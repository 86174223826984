import * as actionTypes from './actionTypes'
import {
    fetchNewUserAPI,
} from 'apiConstants'
import getApi from 'shared/api'

export const fetchNewUserSuccess = userCreated => {
    return {
      type: actionTypes.FETCH_NEW_USER_SUCCESS,
      userCreated: userCreated
    }
  }
  
  export const fetchNewUserFail = error => {
    return {
      type: actionTypes.FETCH_NEW_USER_FAIL,
      error: error
    }
  }
  
  export const fetchNewUserStart = () => {
    return {
      type: actionTypes.FETCH_NEW_USER_START
    }
  }
  
  export const fetchNewUser = body => {
    return async (dispatch, getState) => {
      dispatch(fetchNewUserStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .post(`${fetchNewUserAPI}`,body)
      .then(res => {
        dispatch(fetchNewUserSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchNewUserFail(err.response.data.message))
      })
    }
  }