import React from 'react'
import {TyTitle, PaperStyled, ContainerProgressBar, GridButtonBack,IconBack, ButtonBack} from './styled'
import ProgressBar from 'components/ProgressBar'
import LoaderDialog from 'components/loaders/loaderDialog'

class PaperLayout extends React.Component {
    
    state = {
        isIncomplete: false,
        onHandleClose: this.props.onClose,
    }
    
    
  onHandleClose = () => {
      this.props.onClose()
  }
    
    render() {
        
        if (this.props.created) {
            this.onHandleClose()
        }

      return (
        <div>
            <GridButtonBack item >
                <ButtonBack onClick={this.onHandleClose} variant="fab">
                  <IconBack  />
                </ButtonBack>
                <TyTitle >{this.props.title}</TyTitle>
            </GridButtonBack>
            <PaperStyled >
                <ContainerProgressBar>
                  <ProgressBar Step={this.props.Step} />
                </ContainerProgressBar>

                {this.props.children}
                
            </PaperStyled>
            {this.props.loadingNewCategory && <LoaderDialog />}
        </div>
      )
    }  
  }
  
  
  export default PaperLayout;
  
  