import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { convertFileInBase64 } from 'utils/fileManager'
import * as Yup from 'yup'
import { Formik } from 'formik'
import CrossIcon from 'assets/icons/cross-blue.png'
import CsvIcon from 'assets/icons/csv.jpg'
import FileInput from 'components/FileInput'
import { importProprietary } from 'store/actions/padron.actions'
import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
} from './styled'
import Modal from 'components/Modal'
import {readFile} from "utils/fileManager"

class UploadFile extends React.Component {
  state = {
    url: this.props.value
  }

  getInitialValues = () => ({
    importCsv: ''
  })

  getSignUpSchema = () =>
    Yup.object().shape({
        importCsv: Yup.object().nullable()
  })

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.value })
  }

  hasAttachedContent = () => this.state.url

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> <InputLabel>{'Subir Expensas (.csv)'}</InputLabel> </Row>
      <Row>
        <InputWrapper width={30}>
            <FileInput image={CsvIcon} name="importCsv" value={values.importCsv} onChange={file => setFieldValue('importCsv', file)} />
            {errors.importCsv && <ErrorLabel>{errors.importCsv}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <ButtonWrapper>
        <StyledButton type={'submit'}>
          Crear
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
    )

    
  handleSubmit = async data => {
    var neighbourhood = this.props.neighbourhoods[0].guid;    
    await this.props.importProprietary(data,neighbourhood)
    if (!this.props.isSubmittingFile ) this.props.onClose(true)
  }
  

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}


const mapStateToProps = ({ padron }) => ({
    userInfo: { neighbourhoods, selectedNeighbourhood },
    padron,
    lotes
  }) => ({
    isSubmittingFile: padron.createdImportPropietary,
    submitErrorEvent: padron.errorImportPropietary,
    neighbourhoods
  })
  
const mapDispatchToProps = dispatch =>
bindActionCreators({ importProprietary }, dispatch)

export default connect(
mapStateToProps,
mapDispatchToProps
)(UploadFile)

