import styled from 'styled-components';
import mediaQueries from 'config/media-queries';

export const FileInputWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 150px;
  height: 150px;
  label {
    cursor: pointer;
  }
`
export const DocumentImg = styled.img`
  width: 100%;
  border-radius: 8px;
  ${mediaQueries.mobile`
    display: block;
    max-height: 150px;
    width: auto;
    height: auto;
  `}
`
export const ImageLabel = styled.label`
  font-weight: normal;
  text-decoration: underline;
  text-transform: none;
  color: #1873f3;
`
export const UploadWrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  color: black;
  border: solid;
  border-color: #1873f3;
  border-radius: 10px;
  cursor: pointer;
  border-style: ${(props) => props.dashed ? 'dashed' : 'solid'};
`
