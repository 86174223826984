import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const Container = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQueries.tablet`
    width: 100%;
  `}
`

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 30px;
  margin-top: 10%;
  margin-bottom: 5%;
`

export const Subtitle = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 18px;
`
export const ButtonStyled = styled.button`
  margin: 3%;
  width: 40%;
  max-height: 60px;
  min-height: 40px;
  background-color: ${({ theme, invert }) => (invert ? theme.colors.white : theme.colors.blueRibbon) };
  color: ${({ theme,invert }) => (invert ? theme.colors.blueRibbon : theme.colors.white ) };
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  font-size: 14px;
  border-radius: 10px;
  border: 0px;

`
export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`