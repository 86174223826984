
import React from "react";
import {
  CheckboxInput
} from './styled';

// Checkbox input
const Checkbox = ({
  field: { name, value, onChange },
  id
}) => {
  return (
    <CheckboxInput
      name={name}
      id={id}
      type="checkbox"
      value={value}
      checked={value}
      onChange={onChange}
    />
  );
};

export default Checkbox;
