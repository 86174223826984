import React from 'react';

import {
  DocumentImg,
  FileInputWrapper,
  ImageLabel,
  UploadWrapper,
  Container
} from './styled';

class ImageUploadPlaceholder extends React.Component {
  state = { url: this.props.value };

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      this.setState({ url: this.props.value });
      this.fileSelectedHandler(this.props.value)
    }
  }

  hasAttachedContent = () => {
    return this.state.url;
  }

  fileSelectedHandler = (image) => {
    if (this.props.onChange) {
      this.props.onChange(image);
    }
  }
  
  render() {
    const { icon, label, dashed } = this.props;
    return (
      <FileInputWrapper>
        {
          this.hasAttachedContent() ?
          <Container onClick={() => this.props.clearImage()}>
            <DocumentImg src={this.hasAttachedContent()} onChange={this.fileSelectedHandler} />
            <ImageLabel>Volver a tomar</ImageLabel>
          </Container>
        :
          <UploadWrapper dashed={dashed} onClick={() => this.props.openWebcamModal()}>
            <img src={icon}/> 
            { label ? <ImageLabel>{label}</ImageLabel> : null }
          </UploadWrapper> 
        }
      </FileInputWrapper>
    )
  }
}

export default ImageUploadPlaceholder
