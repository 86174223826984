import React from 'react'
import { Avatar } from '@material-ui/core'
import { Container, AvatarItem, GroupContainer, Label, CheckedElement } from './styled'
import classNames from 'classnames'
import CheckedImg from 'assets/icons/checked.png'
import { capitalizeFirstLetter } from "utils/strings"

class ImagesGroup extends React.Component {
    state = {
        checked: 0
    }

    checked = (element, i) => {
        this.setState({ checked: i })
        this.props.onChange(element)
        if (this.props.onInput) this.props.onInput(element)
    }

    render() {
        return (
            <Container id={'scrollContainer'} className={classNames('portal-hide-scrollbars')} style={this.props.style} >
                {this.props.cuentas.map((cuenta, i) => (
                    <AvatarItem key={i} onClick={() => this.checked(cuenta, i)}>
                        {this.state.checked === i ? ( 
                            <GroupContainer>
                                <CheckedElement src={CheckedImg} />
                                <Avatar
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '20px',
                                    borderColor: '#3771EB',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    backgroundColor: 'transparent',
                                    transition: 'borderColor 1s ease-out 2s',
                                    }}
                                >
                                    <img src={cuenta.src} alt="todo" />
                                </Avatar>
                                <Label>{capitalizeFirstLetter(cuenta.label)}</Label>
                            </GroupContainer>
                            ) : (
                            <GroupContainer>
                                <Avatar
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '20px',
                                    borderColor: '#e5e5e5',
                                    borderStyle: 'solid',
                                    borderWidth: '0.5px',
                                    backgroundColor: 'transparent'
                                    }}
                                >
                                    <img src={cuenta.src} alt="todo" />
                                </Avatar>
                                <Label>{capitalizeFirstLetter(cuenta.label)}</Label>
                            </GroupContainer>
                             ) }
                    </AvatarItem>
                )) }
            </Container>
        )
    }
}

export default ImagesGroup
