import * as actionTypes from './actionTypes'
import { getVehicleByPropietaryIdAPI } from 'apiConstants'
import getApi from 'shared/api'

export const fetchVehiculosSuccess = listVehicles => {
  return {
    type: actionTypes.FETCH_VEHICLES_SUCCESS,
    listVehicles: listVehicles
  }
}

export const fetchVehiculosFail = errorListVehicles => {
  return {
    type: actionTypes.FETCH_VEHICLES_FAIL,
    errorListVehicles: errorListVehicles
  }
}

export const fetchVehiculosStart = () => {
  return {
    type: actionTypes.FETCH_VEHICLES_START
  }
}

export const fetchVehiculos = username => {
  return async (dispatch, getState) => {
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    dispatch(fetchVehiculosStart())
    return api
      .get(getVehicleByPropietaryIdAPI + username)
      .then(res => {
        dispatch(fetchVehiculosSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchVehiculosFail(err))
      })
  }
}