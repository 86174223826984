import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import DatePickerStyled from 'components/DatePickerStyled'
import { registerLocale } from  'react-datepicker';
import { addMonths,subMonths } from 'date-fns'
import {getAlternativeFormattedDate} from 'utils/dateParser'
import {fetchGenerateExpensas} from 'store/actions/expensas.actions'
import PageLoader from 'components/PageLoader';
import es from 'date-fns/locale/es';

import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  LabelFecha,
  ExpensasList,
  ListItem,
} from './styled'

registerLocale('es', es)

class NewExpensa extends React.Component {
  state={
    user: JSON.parse(localStorage.getItem('user')),
    filter: '',
    newExpensa: false,
    isDetailItemOpen: false,
    body: {
      'PaymentMethod': 'APP_MB',
      'DateFrom': '06/01/2019',
    }
  }

  getInitialValues = () => ({
    currentDate: '',
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      currentDate: Yup.string().required('El campo mes actual es requerido'),
    })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> <InputLabel>{'Generar expensas con debito Itau '}</InputLabel> </Row>
      <Row>
        <InputWrapper width={28} margin={'0px 5px'}>
          <LabelFecha>{'Mes a cobrar'}</LabelFecha>
          <DatePickerStyled
            selected={values.currentDate}
            startDate={values.startDate}
            endDate={values.endDate}
            selectsStart 
            onChange={currentDate => setFieldValue('currentDate', currentDate)   }
            minDate={0}
            placeholderText="Fecha"
            dateFormat="MMMM"
            showMonthYearPicker
            locale="es"
          />
          {errors.currentDate && <ErrorLabel>{errors.currentDate}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <LabelFecha>** Se generaran expensas para todos los barrios **</LabelFecha>        
        <a href={window.URL.createObjectURL(new Blob([this.props.expensas.expensas.file_result], {type : 'text/plain'}))} download="expensas.txt" id="download" hidden></a>
      </Row>
      {
        this.props.expensas.errorGenerateExpensasMessage ? (
           <Row><ErrorLabel>{this.props.expensas.errorGenerateExpensasMessage}</ErrorLabel></Row>
        ) : (<></>)
      }{
        this.props.expensas.loadingGenerateExpensas ?
        <ExpensasList>
          <PageLoader customHeight="150px"/>
        </ExpensasList> :
        null
      }
      {
        this.props.expensas.expensas.neighbourhood_list && !this.props.expensas.loadingGenerateExpensas ?
        <ExpensasList>
          {this.props.expensas.expensas.neighbourhood_list.map((e, i) => {
            return (<ListItem>{e.neighbourhood_name}: {e.neighbourhood_result}</ListItem>);
          })}
        </ExpensasList> :
        null
      }
      <ButtonWrapper>
        <StyledButton type={'submit'} onClick={() => this.handleSubmit(values)}>
          Crear
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )

  componentDidUpdate(){
    if (!this.props.expensas.loadingGenerateExpensas && !this.props.expensas.errorGenerateExpensasMessage) {
      
      this.downloadBlob()
    } 
  }

  handleSubmit = data => {
    let newBody = this.state.body;
    newBody.DateFrom = getAlternativeFormattedDate(data.currentDate);
    console.warn(22)
    this.props.fetchGenerateExpensas(newBody)
  }
  
  downloadBlob(){
    document.getElementById('download').click();
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods,username },
  expensas: expensas,
}) => ({
  neighbourhoods,
  username,
  expensas
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGenerateExpensas
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewExpensa)
