import { createSelector } from 'reselect'

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapPropietaries = createSelector(  
  propietary => propietary,
  propietary => 
  (propietary.map(propietary => ({
    value: propietary.dni,
    label: propietary.username,
    id: propietary.id,
    username: propietary.username
  })) )
)
export default mapPropietaries
