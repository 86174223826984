import React, { Component } from 'react'
import {  Container,ContainerError, AvatarItem, TitleCodigo,  Row,Label,DestinatarioDetailContainer,DestinatarioDetailItemContainer,DestinatarioDetailItem,StyledButton,IconBack,CheckContainer,TyTitle, PaperStyled, GridButtonBack, ButtonBack,DestinatarioDetailContainerError} from './styled';
import cancel from 'assets/icons/cancel.png'
import tic from 'assets/icons/tic.svg'
import { Avatar } from '@material-ui/core'
import {getExtendedDate} from 'utils/dateParser'
import Spinner from 'react-spinner-material'

const formatContent = content => content ? (content.length > 20 ? `${content.substring(0, 20)}...` : content) : null

export default class FourStep extends Component {
    state = {
        selectedOption: true,
        hoy: Date.now()
    }

    componentDidMount(){
    }
    
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
  };

  handleOptionChange = changeEvent => {
     this.setState(prevState => ({
      selectedOption:  !prevState.selectedOption
    }))
  };  

    render() {
        if(this.props.billetera.loadingCreateTransactionRequest === true){
            return (
                <GridButtonBack item >
                    <ButtonBack onClick={this.props.onClose} variant="fab">
                        <IconBack  />
                    </ButtonBack>
                    <TyTitle > <Spinner/></TyTitle>
                </GridButtonBack>
               
            )
        }else if(this.props.billetera.errorMessageCreateTransactionRequest){
            return (
                <div>
                    <GridButtonBack item >
                        <ButtonBack onClick={this.props.onClose} variant="fab">
                            <IconBack  />
                        </ButtonBack>
                        <TyTitle >Ups!</TyTitle>
                    </GridButtonBack>
                    <PaperStyled >
                        <ContainerError>
                            <form onSubmit={this.handleFormSubmit}>
                                <CheckContainer>  
                                    <DestinatarioDetailItemContainer>
                                    <AvatarItem>
                                        <Avatar
                                            style={{
                                                width: '25px',
                                                height: '25px',
                                                padding: '20px',
                                                borderStyle: 'solid',
                                                borderWidth: '10px',
                                                backgroundColor: 'white'
                                                }}
                                        >
                                            <img src={cancel} alt="todo" />
                                        </Avatar>
                                        <Label>{'La transferencia no se puedo generar'}</Label>
                                    </AvatarItem>
                                    </DestinatarioDetailItemContainer>
                                </CheckContainer>
                                
                                <DestinatarioDetailContainerError>
                                    <Row>
                                        <TitleCodigo >Mensaje de error: </TitleCodigo>
                                        <TitleCodigo size={'17px'} margin={"9% 0%"}> {this.props.billetera.errorMessageCreateTransactionRequest}
                                        </TitleCodigo>
                                    </Row>
                                </DestinatarioDetailContainerError>
                                            
                                <StyledButton onClick={() => this.props.onClose()} >Volver a la billetera</StyledButton>
                            </form>
                        </ContainerError>
                    </PaperStyled>
                </div>
            )
        }else if(this.props.billetera.errorMessageCreateTransactionRequest === false || this.props.billetera.loadingCreateTransactionRequest === false){
             return (
            <div>
                <GridButtonBack item >
                    <ButtonBack onClick={this.props.onClose} variant="fab">
                        <IconBack  />
                    </ButtonBack>
                    <TyTitle >Nueva Transferencia</TyTitle>
                </GridButtonBack>
                <PaperStyled >
                    <Container>
                    <form onSubmit={this.handleFormSubmit}>
                        <CheckContainer>  
                            <DestinatarioDetailItemContainer>
                            <AvatarItem>
                                <Avatar
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                        padding: '20px',
                                        borderColor: '#e5e5e5',
                                        borderStyle: 'solid',
                                        borderWidth: '10px',
                                        backgroundColor: '#1873f3'
                                        }}
                                >
                                    <img src={tic} alt="todo" />
                                </Avatar>
                                <Label>{'La transferencia se preparo con éxito'}</Label>
                            </AvatarItem>
                            </DestinatarioDetailItemContainer>
                        </CheckContainer>
                        
                        <DestinatarioDetailContainer>
                            {/*
                                <Row>
                                    <TitleCodigo > Codigo de operacion </TitleCodigo>
                                    <TitleCodigo spacing={'5px'} size={'18px'} weight={'600'}>"156123147"</TitleCodigo>
                                </Row>
                            */}
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Destinatario</DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} >{formatContent(this.props.transactionProgress.DestinationBankAccount.HolderName)}</DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer> 
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Monto</DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.Currency+" "}${this.props.transactionProgress.Amount}</DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer>
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Fecha y hora </DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} >{getExtendedDate(this.state.hoy)} </DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer>
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Cuenta de origen</DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} >{formatContent(this.props.transactionProgress.SourceBankAccount.HolderName)}</DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer>
                            <Row margin={"0% 0% 2% 0%"}></Row>
                            <Row >
                                <DestinatarioDetailItemContainer >Pendiente de autorización: </DestinatarioDetailItemContainer>

                                <DestinatarioDetailItemContainer justify={"space-around"}>
                                    <DestinatarioDetailItem > Administrador</DestinatarioDetailItem>
                                    <DestinatarioDetailItem color={"#F7B343"} > Falta firmar </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>

                                <DestinatarioDetailItemContainer justify={"space-around"}>
                                    <DestinatarioDetailItem > Operador</DestinatarioDetailItem>
                                    <DestinatarioDetailItem color={"#F7B343"}  > Falta firmar </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>

                                <DestinatarioDetailItemContainer justify={"space-around"}>
                                    <DestinatarioDetailItem > Aprobador</DestinatarioDetailItem>
                                    <DestinatarioDetailItem color={"#F7B343"}  > Falta firmar </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>

                            </Row>
                        </DestinatarioDetailContainer>
                                    
                        <StyledButton onClick={() => this.props.onClose()} >Volver a la billetera</StyledButton>
                    </form>
                </Container>
                </PaperStyled>
            </div>
        )
        }
    }

}
  