//API Amazon

//prod

// Auth
export const authAPI =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/administration/login'
export const refreshTokenUrl =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/administration/refresh-token'
export const forgotPasword =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/app/forgot-password/'
export const forgotPaswordConfirmation =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/app/forgot-password/'

export const authTokenAPI =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/administration/login'

//USED IN "mensajes"
export const getRecibedMsjAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/Feed/'
export const getSentMsjAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/MessagePrivate/sent'
export const getUserNamesAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/xperson_neighbourhood/neighbourhood'
export const postPrivateMsjAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev//api/MessagePrivate'
export const postPublicMsjAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/MessagePrivate/public'
export const getUnreadMsjAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/MessagePrivate/received/unread'

//USED IN "familiares"
export const getFamiliesByPropietaryIdAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/masterdata/families/propietario/'

//USED IN "vehiculos"
export const getVehicleByPropietaryIdAPI = 'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/vehicle/user/'

export const fetchGetVehicleBrandsAPI ='https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/vehicle_brand'

export const fetchGetVehicleModelsAPI = 'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/vehicle_model/'

//USED IN "feed"
export const getEventListAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/Event/'
export const getNewsListAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/NewsArticle/'
export const postNewEventAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/Event/'
export const postNewArticleAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/NewsArticle/'
export const deleteArticleAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/NewsArticle/'
export const deleteEventAPI =
  'https://79qknkvj7j.execute-api.us-east-2.amazonaws.com/Dev/api/Event/'

//USED IN "lotes"
export const getFunctionalUnitAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/masterdata/functional-units/neighbourhood'

//USED IN "padron"
export const postNewProprietaryAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/person/propietary'
export const postImportMasiveProprietariesAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/person/import-propietary'
export const getSearchedPadronAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/Propietario/search/'
export const getPadronAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/person/searchPropietary'
export const editPadronAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/person'

//USED IN "proveedores"
export const getCategoriesAPI =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/categories'
export const getSuppliersAPI =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/suppliers'
export const getServicesAPI =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/services/categoryId'
export const getNeighbourhoodAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/masterdata/neighbourhoods'
export const postNewSupplier =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/suppliers'
export const postNewCategory =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/categories'
export const postNewService =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/services/categoryId'
export const getSuppliersRequestAPI =
  'https://vwm8jglm1i.execute-api.us-east-2.amazonaws.com/Dev/api/request'

//USED IN "reservas"
export const getActivitiesAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/activities'
export const activitiesAPI =
'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/activities'
export const postNewActivitieAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/activities'
export const getSpacesAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/spaces/actividadBarrioId'
export const postNewSpaceAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/active_time'
export const postNewExceptionsAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/exception-spaces'
export const getBookingRequestAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/booking/future'
export const delSpaceAPI = `https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/Space`

export const deleteActivityAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Dev/api/activities'

//USED IN "securityStaff"
export const postNewSecurityStaffAPI =
  'https://wk8nno6vig.execute-api.us-east-2.amazonaws.com/Dev/api/user/'
export const getSearchedSecurityStaffListAPI =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/security/user/search?name='
export const getSecurityStaffListAPI =
  'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/security/user/search'

//USED IN "Accesos"
export const fetchLotsAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/masterdata/functional-units/neighbourhood'



//USED IN "invitados"
export const getInvitationsAPI =
  'https://w78035x0sk.execute-api.us-east-2.amazonaws.com/Dev/api/event/neighbourhood/'
export const getGuestsAPI =
  'https://w78035x0sk.execute-api.us-east-2.amazonaws.com/Dev/api/guest_frequency/username/'
export const getFilteredGuestsAPI =
  'https://w78035x0sk.execute-api.us-east-2.amazonaws.com/Dev/api/guests/today-invites/search'

//USED IN "neighbourhood"
export const getDefaultActivities =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/activity'
export const getDefaultCategories = ''

// USED IN "ActiveTimes"
export const activeTimesAPI =
  'https://e5jd2tkyl2.execute-api.us-east-2.amazonaws.com/Dev/api/active_time/space'

// USED IN "Billetera"
export const getAccountWithAliasAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/bank-account/get-account?alias='
export const getAccountWithUniqueKeyAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/bank-account/get-account?uniqueKey='
export const getAccountWithLoteAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/bank-account/get-account?propertyCode='
export const postRequestedTransactionAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/requested-transaction?'
export const getCodeQRImageAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/requested-transaction/generate-qr/'

export const getRegenerateOpenQR = 'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/requested-transaction/regenerate-openqr/'

export const getTransactionUserAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transaction/neighbourhood/'
export const getBalanceUserAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transaction/neighbourhood/balance'
export const postCreateTransactionRequestAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/create-transfer-request'
export const getDebitAccountInfo =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/bank-account/get-neighbourhood-account'
export const getTransferPendingAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/pendings-auth'

export const getNumberOfTransferPendingAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/total-pendings-auth'
export const TransactionChargeWithCardAPI  =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/total-pendings-auth'

export const getApproveTransferAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/approve-transfer-workflow'
export const getApproveTransferWithTokenAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/approve-transfer-workflow?Token='
export const getDeniedTransferAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transfer/reject-transfer-workflow'
export const postReSendToken =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/token/sms/send'

export const getAccountQR =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/requested-transaction/search-person'

export const getTransactionDataAPI=
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/transaction/'

// USED IN "Expensas"
export const postGenerateExpensasAPI =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/expense/generate'
export const getHomeExpensas =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/expense/search?'
export const getExpensasPorCliente =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/expense/client?ClientId='
export const getFileDetailClient =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/expense/export/detail/'
export const getFileDetailNeighbourhood =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/expense/export/overview?NeighborhoodGuid='
export const postUploadFile =
  'https://xe864hpr98.execute-api.us-east-2.amazonaws.com/Dev/api/expense/uploadresults'

// USED IN "Profile"
export const getRoles = 'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/role'
export const getMenus = 'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/menu'

export const getPersonByNeighbourhood =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/person/neighbourhood'

export const fetchRefreshMenusForRoleAPI =
  'https://9bf4zprbm6.execute-api.us-east-2.amazonaws.com/Dev/api/person/menus'


  // USED IN "Accesos"
  export const fetchGetGuestAPI = 'https://w78035x0sk.execute-api.us-east-2.amazonaws.com/Dev/api/invites/' //(Get Guest)
  
  // USED IN "Tickets"
  export const getCategoriesByRoleAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/category/getcategoriesbyReview/'
  export const getTicketsByCategoryAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/tickets/getticketsbycategory/'
  export const getTicketMessagesAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/ticketmessage/getmsg/'
  export const getSendMessageAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/ticketmessage/sendmsg/'
  export const getAssignTicketsAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/ticketmessage/assignticket/'
  export const getCloseTicketsAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/tickets/closeTicket/'
  export const getPendingTicketsAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/tickets/changestate/'
  export const TransferTicketsAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/tickets/changecategory/'
  export const getSearchedTicketsAPI = 'https://a37n04y440.execute-api.us-east-2.amazonaws.com/Dev/api/tickets/searchtickets/'
  
  export const fetchNewUserAPI = 'https://ndtl2docz9.execute-api.us-east-2.amazonaws.com/Dev/api/authentication/administration/register'
