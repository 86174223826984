import styled from 'styled-components'

export const Container = styled.div`
    top: 20%;
    left: 25%;
    height: 50px;
    padding: 1%;
    border-radius: 5px;
    color: white;
    background-color: ${({ theme ,success }) => success ? theme.colors.oceanGreen  : theme.colors.pomegranate};
    text-align: center; 
`
export const Items = styled.div`
    height: 50px;   
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Wrapper = styled.div`
  position: fixed;
  top: 40%;
  left: 35%; 
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
`