import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { capitalizeFirstLetter } from 'utils/strings'

dayjs.locale('es')

export const getFormattedDate = date => {
  const guestDate = dayjs(date)
  return `${guestDate.format('DD/MM/YY')}`
}

export const getAlternativeFormattedDate = date => {
  const guestDate = dayjs(date)
  return `${guestDate.format('MM/DD/YYYY')}`
}

export const getGuestFormattedDate = date => {
  const guestDate = dayjs(date)
  return `${capitalizeFirstLetter(guestDate.format('dddd DD'))} de ${guestDate.format('MMMM')}`
}

export const getFormattedDateYear = (date) => {
  const datejs = dayjs(date)
  return datejs.format('YYYY-MM-DD')
}

export const getMonthLabel = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('DD')} ${capitalizeFirstLetter(actualDate.format('MMM'))}`
}

export const getExtendedDate = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('DD')} de ${capitalizeFirstLetter(
    actualDate.format('MMMM')
  )}, ${actualDate.format('YYYY')}, ${actualDate.format('HH:mm')}`
}

export const getDayHourDate = date => {
  const actualDate = dayjs(date)
  return `${actualDate.format('MM/DD')} 
  , ${actualDate.format('HH:mm')}`
}

export const plus30Minutes = timeSpan => {
  const date = dayjs(`2019-01-01 ${timeSpan}`)
  return date.add(30, 'm').format('HH:mm')
}

export const substract2HourFullDate = date => {
  const actualDate = dayjs(date)
  return actualDate.subtract(2, 'H').format('DD/MM/YYYY HH:mm')
}

export const getReservationsActualDate = () => {
  const date = dayjs()
  return date.format('YYYY-MM-DD')
}

export const plusBusinessDays = (plusDays) => {
  let hoy = new Date();
  let i=0;
  while (i<plusDays) {
    hoy.setTime(hoy.getTime()+24*60*60*1000); // añadimos 1 día
    if (hoy.getDay() != 6 && hoy.getDay() != 0)
      i++;  
  }
  let mes = hoy.getMonth()+1;
  if (mes<10) mes = '0'+mes;
  let fecha = mes + '/' + hoy.getDate() + '/' + hoy.getFullYear();
  return fecha
}
