import * as actionTypes from './actionTypes'
import {
    postGenerateExpensasAPI,
    getHomeExpensas,
    getExpensasPorCliente,
    getFileDetailClient,
    getFileDetailNeighbourhood,
    postUploadFile
} from 'apiConstants'
import getApi from 'shared/api'
import fetchData from 'shared/fetchData'

//--------------- Generate file expensas for the bank ------------------------
export const fetchGenerateExpensasSuccess = expensas => {
    return {
        type: actionTypes.FETCH_GENERATE_EXPENSAS_SUCCESS,
        expensas: expensas
    }
}

export const fetchGenerateExpensasFail = error => {
  return {
      type: actionTypes.FETCH_GENERATE_EXPENSAS_FAIL,
      error: error
  }
}

export const fetchGenerateExpensasStart = () => {
  return {
      type: actionTypes.FETCH_GENERATE_EXPENSAS_START
  }
}

export const fetchGenerateExpensas = bodyContent => {
  return async (dispatch, getState) => {
    dispatch(fetchGenerateExpensasStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.post(postGenerateExpensasAPI,bodyContent)
        dispatch(
          fetchGenerateExpensasSuccess(response.data)
        )
      } catch (error) {
        dispatch(fetchGenerateExpensasFail(error.response.data.message))
      }
  }
}

//--------------- Get the data for the Home of Expensas ------------------------
export const fetchgetHomeExpensasSuccess = expensas => {
    return {
        type: actionTypes.FETCH_GET_HOME_EXPENSAS_SUCCESS,
        homeExpensas: expensas
    }
}

export const fetchgetHomeExpensasFail = error => {
  return {
      type: actionTypes.FETCH_GET_HOME_EXPENSAS_FAIL,
      error: error
  }
}

export const fetchgetHomeExpensasStart = () => {
  return {
      type: actionTypes.FETCH_GET_HOME_EXPENSAS_START
  }
}

export const fetchgetHomeExpensas = (guid,year,lote) => {
  return async (dispatch, getState) => {
    dispatch(fetchgetHomeExpensasStart())
    const api = await getApi(guid)
    
    var url = getHomeExpensas+'NeighborhoodGuid='+guid+'&PropertyCode='+lote+'&PeriodYear='+year+'&PageSize=12&PageNumber=0'
    
    try {
      const response = await api.get(url,)

      dispatch(
        fetchgetHomeExpensasSuccess(response.data)
      )
    } catch (error) {
      dispatch(fetchgetHomeExpensasFail(error.response.data.message))
    }
  }
}

//--------------- Get the expensas for a Client ------------------------
export const fetchgetExpensasPorClienteSuccess = expensas => {
    return {
        type: actionTypes.FETCH_GET_EXPENSAS_CLIENTE_SUCCESS,
        expensasCliente: expensas
    }
}

export const fetchgetExpensasPorClienteFail = error => {
  return {
      type: actionTypes.FETCH_GET_EXPENSAS_CLIENTE_FAIL,
      error: error
  }
}

export const fetchgetExpensasPorClienteStart = () => {
  return {
      type: actionTypes.FETCH_GET_EXPENSAS_CLIENTE_START
  }
}

export const fetchgetExpensasPorCliente = (clientId,year) => {
  return async (dispatch, getState) => {
    dispatch(fetchgetExpensasPorClienteStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    var url = getExpensasPorCliente+clientId+'&PeriodYear='+year+'&PageSize=10&PageNumber=0'
    try {
      const response = await api.get(url)
        dispatch(fetchgetExpensasPorClienteSuccess(response.data))
      } catch (error) {
        dispatch(fetchgetExpensasPorClienteFail(error))
      }
  }
}


//--------------- Get the File detail client ------------------------
export const fetchgetFileDetailClientSuccess = fileDetailClient => {
    return {
        type: actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS,
        fileDetailClient: fileDetailClient
    }
}

export const fetchgetFileDetailClientFail = error => {
  return {
      type: actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_FAIL,
      error: error
  }
}

export const fetchgetFileDetailClientStart = () => {
  return {
      type: actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_START
  }
}

export const fetchgetFileDetailClient = (idDetail) => {
  return async (dispatch, getState) => {
    dispatch(fetchgetFileDetailClientStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    var url = getFileDetailClient+idDetail
    try {
      const response = await api.get(url)
        dispatch(fetchgetFileDetailClientSuccess(response.data))
      } catch (error) {
        dispatch(fetchgetFileDetailClientFail(error))
      }
  }
}


//--------------- Get the File detail Neighbourhood ------------------------
export const fetchgetFileDetailNeighbourhoodSuccess = fileDetailClient => {
    return {
        type: actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS,
        fileDetailNeighbourhood: fileDetailClient
    }
}

export const fetchgetFileDetailNeighbourhoodFail = error => {
  return {
      type: actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL,
      error: error
  }
}

export const fetchgetFileDetailNeighbourhoodStart = () => {
  return {
      type: actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START
  }
}

export const fetchgetFileDetailNeighbourhood = (guid,lote,year) => {
  return async (dispatch, getState) => {
    dispatch(fetchgetFileDetailNeighbourhoodStart())

    const api = await getApi(guid)
    
    var url = getFileDetailNeighbourhood+guid+'&PropertyCode='+lote+'&PeriodYear='+year

    try {
      const response = await api.get(url,)

      dispatch(
        fetchgetFileDetailNeighbourhoodSuccess(response.data)
      )
    } catch (error) {
      dispatch(fetchgetFileDetailNeighbourhoodFail(error.response.data.message))
    }
  }
}

//--------------- Post File Upload ------------------------
export const fetchpostUploadFileSuccess = fileDetailClient => {
    return {
        type: actionTypes.FETCH_POST_UPLOAD_FILE_SUCCESS,
        uploadFileRequest: fileDetailClient
    }
}

export const fetchpostUploadFileFail = error => {
  return {
      type: actionTypes.FETCH_POST_UPLOAD_FILE_FAIL,
      error: error
  }
}

export const fetchpostUploadFileStart = () => {
  return {
      type: actionTypes.FETCH_POST_UPLOAD_FILE_START
  }
}

export const fetchpostUploadFile = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchpostUploadFileStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    
    var BodyFormatted= {'ResultFile': ''}
    BodyFormatted.ResultFile = body

    try {
      const response = await api.post(postUploadFile,BodyFormatted)
        dispatch(
          fetchpostUploadFileSuccess(response.data)
        )
      } catch (error) {
        dispatch(fetchpostUploadFileFail(error.response.data.message))
      }
  }
}