import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBackIos'

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin: ${({ theme }) => theme.spacing.unit };
`
export const LabelContainer = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.colors.black };
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing.unit };   
    margin-top: ${({ theme }) => theme.spacing.unit };    
    display: flex;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const RadioBtnContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit };
    margin-bottom: ${({ theme }) => theme.spacing.unit };
    display:flex;
    flex-direction: row;
    justify-content: flex-start;  

`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.dark };
    background-color: ${({ theme }) => theme.colors.white };

    ${LabelContainer}:hover & {
        color: ${({theme})=> theme.colors.blueRibbon};
        transition:0.3s ease;  
        cursor: pointer;
    }
`

export const LabelChecked = styled.div`
    font-size: 15px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};    
    margin-top: ${({ theme }) => theme.spacing.unit };   
`


export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const DestinatarioDetailContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.wildSand };
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.ghost };
    border-radius: 5px;
    border-width: 0.5px;
    padding: ${({ theme }) => theme.spacing.unit };
`

export const DestinatarioDetailItemContainer = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px;
`
export const DestinatarioDetailItem = styled.div`
    color: ${props => (props.bold ? 'dark' : '#616161')} ;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 65px;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 1% 0px 0px 10%;
`

export const AvatarItem = styled.div`
    flex-grow: 1;
    width: 100%;
    min-height: 60px;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const Label = styled.label`
    padding: 5px;
    font-style: bold;
`

