import { createSelector } from 'reselect'
import {getFormattedDate} from "utils/dateParser"

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapAllMovements= createSelector(  
    Movements => Movements,
    Movements => 
    Movements ? ( Movements.map(movement => ({
    description: movement.description,
    datePay: getFormattedDate(movement.date),
    id: movement.id,
    amount: movement.amount,
    status: movement.state_code
  })) ) : (Movements)
)
export default mapAllMovements
