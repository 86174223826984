import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchTransactionQR } from 'store/actions/billetera.actions'
import { InputStyled , ErrorLabel, Row, SelectStyled,FormContainer, LabelContainer, SelectWrapper ,StyledButton, TitleContainer,IconBack} from './styled';
import { Formik } from 'formik'
import * as Yup from 'yup'

const conceptos = [
  { value: 'Cafeteria',  label: 'Cafeteria' },
  { value: 'Alimentos',  label: 'Alimentos' },
  { value: 'Bebidas',  label: 'Bebidas'  },
  { value: 'Otros',  label: 'Otros'  },
];
  
class FirstStep extends React.Component {
  getSignUpSchema = () => Yup.object().shape({
    concepto: Yup.string().required('El campo es requerido'),
    amount: Yup.string().required('El campo es requerido'),
  })

  handleFormSubmit = async data => {
    this.props.createTransaction(data)
    this.props.NextStep()
  };

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <LabelContainer>Monto a cobrar</LabelContainer>
        <InputStyled 
          prefix="$" 
          value={values.amount} 
          onChangeEvent={e => setFieldValue('amount', e.target.value.slice(1,15))} 
          thousandSeparator={','} 
          decimalSeparator={'.'}/>
          {errors.amount && <ErrorLabel>{errors.amount}</ErrorLabel>}
      </Row>
      <Row> 
        <LabelContainer>Categoría</LabelContainer>
        <SelectWrapper> 
          <SelectStyled
            placeholder={'Seleccionar un concepto...'}
            value={values.concepto}
            options={conceptos}
            onChange={(e) => setFieldValue('concepto', e) }
          /> 
          {errors.concepto && <ErrorLabel>{errors.concepto}</ErrorLabel>}
        </SelectWrapper>
      </Row>
      <StyledButton type="submit" value="submit">Continuar</StyledButton>
    </FormContainer>
  )

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }
}
  
const mapStateToProps = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionQR
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstStep)
