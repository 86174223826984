import styled from 'styled-components'
import mediaQueries from 'config/media-queries'
import TextField from '@material-ui/core/TextField'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ImageWrapper = styled.div`
  max-height: 100vh;
  width: 50%;  
  ${mediaQueries.tablet`
    display: none;
  `}
`

export const LogoImg = styled.img`
    margin-top: 5%;
    width: 40%;
`
export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`
export const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 35px;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  width: 50%;


  ${mediaQueries.tablet`
    width: 100%;
  `}
`

export const FormWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  box-sizing: border-box;
  max-width: 600px;
  align-items: center;
  margin: 0 auto;
`

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 50px;
  margin-top: 90px;
  margin-bottom: 24px;
`

export const SubTitle = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 26px;
`

export const ContentLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
`

export const StyledLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 14px;
  align-self: end;
  cursor:pointer;
`


export const InputWrapper = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.dustyGray};
  border-radius: 5px;
  margin: 2% 0%;
  padding: 1% 1%;


`

export const ButtonWrapper = styled.div`
  margin: 30px;
  width: 100%;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
`
export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  padding-bottom: 10px;
`
export const LabelReSend = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;
  position: absolute;
  bottom: 30%;
  right: 12%;
  &:hover{
    cursor:pointer;
    color: ${({ theme }) => theme.colors.persimmon};
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-bottom: ${({ error }) => (error ? 0 : 10)}px;
`
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.royalBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  position: absolute;
  bottom: 10%;
  right: 10%;
`