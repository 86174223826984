import styled, { css } from 'styled-components'
import Select from 'react-select'

export const PageWrapper = styled.div`
  width: 90%;
  margin-left: 5%;
  min-height: 100%;
  align-items: normal;

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const SearchWrapper = styled.div`
    max-height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.alto};
    margin-right: 1px;
    border-radius: 10px;
`

export const ContentWrapper = styled.div`
    max-height: 550px;
    min-height: 350px;
    display: flex;
    margin-right: 1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`

export const ListWrapper = styled.div`
    width: 35%;
    padding-right: 13px;
    height: auto;
    overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-right: 2px solid ${({ theme }) => theme.colors.ghost};
`

export const ContainerSelects = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const ContainerSearchBar = styled.div`
    width: 100%;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`


export const SelectStyled = styled(Select)`
    width: 70%;
    margin: 0px 10px;
`
export const ImgContainer = styled.div`
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.ghost};
  border-radius: 5px;
  padding: 10px;
`


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: auto;
`

export const MovementContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 15px 0px 15px 30px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.alabaster : theme.colors.transparent};
  border-top: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.ghost)};
  border-bottom: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.ghost)};
  transition: background 0.15s ease;
  cursor: pointer;
  

  &:hover {
    background-color: ${({ theme }) => theme.colors.alabaster};
    border-top: 2px solid ${({ theme }) => theme.colors.mercury};
    border-bottom: 2px solid ${({ theme }) => theme.colors.mercury};
  }
`

export const Charge = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.oceanGreen};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`
export const Pay = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.pomegranate};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 500;
  text-align: initial;
  cursor: pointer;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
`
export const Price = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ amount,theme }) => (amount < 0 ? theme.colors.pomegranate : theme.colors.oceanGreen)};
    font-size: "18px";
    align-self: center;
    font-weight: 600;
`

export const Date = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
  cursor: pointer;
  margin-right: 15px;
`