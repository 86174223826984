import expensas from 'assets/icons/expensas.svg'

const meses = [
    {title: "Enero",subtitle: "Enero",avatar: expensas},
    {title: "Febrero",subtitle: "Febrero",avatar: expensas},
    {title: "Marzo",subtitle: "Marzo",avatar: expensas},
    {title: "Abril",subtitle: "Abril",avatar: expensas},
    {title: "Mayo",subtitle: "Mayo",avatar: expensas},
    {title: "Junio",subtitle: "Junio",avatar: expensas},
    {title: "Julio",subtitle: "Julio",avatar: expensas},
    {title: "Agosto",subtitle: "Agosto",avatar: expensas},
    {title: "Septiembre",subtitle: "Septiembre",avatar: expensas},
    {title: "Noviembre",subtitle: "Noviembre",avatar: expensas},
    {title: "Diciembre",subtitle: "Diciembre",avatar: expensas}
]

export default meses