import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Accordion from 'components/Accordion';
import DatePickerStyled from 'components/DatePickerStyled';
import registro from 'assets/images/registro.png';
import ImageUploadPlaceholder from 'components/ImageUploadPlaceholder';
import WebcamModal from 'components/WebcamModal';

import {
  Text,
  FormSection,
  ErrorLabel,
  InputWrapper,
  Row,
  ButtonWrapper,
  StyledButton
} from '../styled'

class NewGuestThirdStep extends Component {
  handleSubmit = (values) => {
    const { index, getFormData } = this.props;
    getFormData(values, index);
  }

  getInitialValues = () => ({
    licencia_img: this.props.editMode && this.props.editInitialValues['drive-license-picture'].front_url ? this.props.editInitialValues['drive-license-picture'].front_url : (''),
    licencia_vencimiento: this.props.editMode && this.props.editInitialValues['drive-license-picture'].expire_date ? (new Date(this.props.editInitialValues['drive-license-picture'].expire_date)) : (''),
  })

  getSignUpSchema = () =>
  Yup.object().shape({
    licencia_img: Yup.string().nullable().required('La imagen de la licencia es requerida.'),
    licencia_vencimiento: Yup.string().required('El campo vencimiento de la licencia es requerido'),
  })

  render() {
    const args = {...this.props};
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.getSignUpSchema()}
        isInitialValid={this.props.editMode ? true : false}
        onSubmit={this.handleSubmit}
        render={props => <FormikForm {...props} {...args} />}
      />
    )
  }
}

class FormikForm extends Component {
  state = { cameraModalOpen: false };

  componentDidMount() {
    if(this.props.editMode) {
      this.setState({dniImage: this.props.editInitialValues[`drive-license-picture`].front_url});
    }
  }

  openWebcamModal = () => {
    this.setState({
      cameraModalOpen: !this.state.cameraModalOpen,
    });
  }

  retrieveImage = (imagen) => {
    this.setState({ dniImage: imagen, cameraModalOpen: false })
  }

  clearImage = () => {
    this.setState({ dniImage: null, cameraModalOpen: true })
  }

  render() {
    const { handleSubmit, values, setFieldValue, errors, isValid } = this.props;
    return(
      <Accordion 
        title={this.props.title} 
        active={this.props.active} 
        index={this.props.index}
        changeIndex={this.props.changeIndex}
        formDataValidation={this.props.formDataValidation}
      >
        <form onSubmit={(values) => handleSubmit(values)}>
          <Row>
            <FormSection flex={1}>
              <InputWrapper>
                <ImageUploadPlaceholder 
                  icon={registro} 
                  label={'Foto del registro'} 
                  onChange={file => setFieldValue('licencia_img', file)} 
                  dashed={true} 
                  value={this.state.dniImage ? this.state.dniImage : null}     
                  openWebcamModal={this.openWebcamModal}
                  clearImage={this.clearImage}
                />
                { this.state.cameraModalOpen ? <WebcamModal onClose={this.openWebcamModal} retrieveImage={this.retrieveImage}></WebcamModal> : null }
                {errors.licencia_img && <ErrorLabel>{errors.licencia_img}</ErrorLabel>}
              </InputWrapper>
            </FormSection>
            <FormSection flex={2}>
              <InputWrapper display='flex' flexdirection='column'>
                <Text margin={'0px'}>{'Fecha vencimiento'}</Text>
                <DatePickerStyled
                  onChange={values => setFieldValue('licencia_vencimiento', values)}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  selected={values.licencia_vencimiento}
                  error={errors.licencia_vencimiento}
                  placeholderText="dd/MM/yyyy 📅"
                  bold={true}
                  padding={'8px 10px'}
                />
              {errors.licencia_vencimiento && <ErrorLabel>{errors.licencia_vencimiento}</ErrorLabel>}
              </InputWrapper>
            </FormSection>
          </Row>
          <ButtonWrapper>
            {isValid ? <StyledButton type={'submit'}>{'Aceptar'}</StyledButton> : null}
          </ButtonWrapper>
        </form>
      </Accordion>
    )
  }
}

export default NewGuestThirdStep