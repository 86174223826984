import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withWidth } from '@material-ui/core'
import closeIcon from '../../../../assets/icons/cross-blue.png'
import axios from 'axios'
import PageLoader from 'components/PageLoader';
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { fetchTransactionQR, fetchOpenQR } from 'store/actions/billetera.actions'
import { bindActionCreators } from 'redux'
import {
  Container,
  Header,
  Title,
  CloseIcon,
  Button,
  Footer,
  Body,
  ErrorMsg
} from './styled'

class PrintQR extends Component {

  componentDidMount() {
    this.props.fetchOpenQR();
  }

  componentDidUpdate(prevProps) {
    if(this.props.loadingQR === false && prevProps.loadingQR === true) {
      if(this.props.errorQR === 'RegenerateFail') {
        this.props.fetchTransactionQR({
          amount: '0',
          categoria: 'varios',
          concept: 'varios',
          destination_cuit: this.props.billetera.AccountDebitInfo.HolderCuit,
          description: 'Pago QR',
          title: 'Pago QR',
        });
      }
    }
  }


  downloadQrImage = () => {
    document.getElementById('qrAbierto').click();
  }

  render() {
    const { billetera } = this.props;
    return (
      <Container>
        <Header>
          <CloseIcon src={closeIcon} onClick={this.props.onClose}/>
        </Header>
        <Title>Imprimir QR</Title>
        <Body>
        {
          !billetera.loadingGetCodeQRImage && billetera.GetCodeQRImage ? 
          <img height={'400px'} width={'400px'} src={'data:image/png;base64,'+billetera.GetCodeQRImage} /> 
          : 
          <PageLoader customHeight={400}/>
        }
        {
          !billetera.loadingGetCodeQRImage && billetera.errorTransactionQR ?
          <ErrorMsg>{billetera.errorTransactionQR}</ErrorMsg> : null
        }
        </Body>
        <a href={'data:application/octet-stream;base64,' + billetera.GetCodeQRImage} download="QR_Abierto.jpeg" id="qrAbierto" hidden></a>
        <Footer>
          <Button disabled={!billetera.GetCodeQRImage} type="button" onClick={this.downloadQrImage}>Imprimir</Button> 
        </Footer>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
    loadingGetCodeQRImage: state.billetera.loadingGetCodeQRImage || state.billetera.loadingTransactionQR,
    billetera: state.billetera,
    loadingQR: state.billetera.loadingTransactionQR,
    errorQR: state.billetera.errorTransactionQR
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionQR,
      fetchOpenQR
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth()
  )(withErrorHandler(PrintQR, axios))
)