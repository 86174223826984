import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  user: null,
  error: false,
  errorMessage: false,
  loading: false,
  session: "",
  authRedirectPath: '/'
}

const authStart = state => {
  return updateObject(state, { error: false, loading: true })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    user: action.user,
    message: action.message,
    tokenrequired: action.tokenrequired,
    session: action.session,
    error: false,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, { errorMessage: action.errorMessage, error:true, loading: false })
}

const authLogout = state => {
  return updateObject(state, { token: null, user: null })
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}




  //------------------Request forgot password-----------------------------------

  const fetchForgotPasswordSuccess = (state,action ) => {  
    return updateObject(state, {
      forgotPassword: action.forgotPassword,
    })
  }
  
  const fetchForgotPasswordFail = state => {
    return updateObject(state, { errorAccountInfo: true })
  }
  
  const fetchForgotPasswordStart = state => {
    return updateObject(state, { loadingAccountInfo: true })
  } 

  //------------------Request Token Auth Login-----------------------------------

  const fetchTokenAuthLoginStart = (state,action ) => {  
    return updateObject(state, {
      tokenAuthLogin: action.forgotPassword,
    })
  }
  
  const fetchTokenAuthLoginFail = state => {
    return updateObject(state, { errorTokenAuthLogin: true })
  }
  
  const fetchTokenAuthLoginSuccess = state => {
    return updateObject(state, { loadingTokenAuthLogin: true })
  } 

  //------------------authRequireToken----------------------------------

  const authRequireToken = (state,action ) => {  
    return updateObject(state, {
      session: action.session,
      loading: false
    })
  }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action)
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action)
      
      case actionTypes.FETCH_FORGOT_PASSWORD_START:
      return fetchForgotPasswordStart(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_FAIL:
      return fetchForgotPasswordFail(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_SUCCESS:
      return fetchForgotPasswordSuccess(state, action)

      
      case actionTypes.FETCH_TOKEN_AUTH_LOGIN_START:
      return fetchTokenAuthLoginStart(state, action)
    case actionTypes.FETCH_TOKEN_AUTH_LOGIN_FAIL:
      return fetchTokenAuthLoginFail(state, action)
    case actionTypes.FETCH_TOKEN_AUTH_LOGIN_SUCCESS:
      return fetchTokenAuthLoginSuccess(state, action)

    case actionTypes.AUTH_REQUIRE_TOKEN:
      return authRequireToken(state, action)
      


    default:
      return state
  }
}

export default reducer
