import React, { Component } from 'react'
import {  
  Container,  
  LabelContainer,
  DetailContainer,
  DetailItemContainer,
  DetailItem,
  StyledButton,
} from './styled';
import LoaderDialog from 'components/loaders/loaderDialog'

class SecondStep extends Component {
  render() {
    return (
      <Container>
        <LabelContainer>Botón de pago</LabelContainer>
          <DetailContainer>  
          {
            this.props.QR ? 
            <DetailItemContainer centered={true}>
              <img height={'60%'} width={'60%'} src={'data:image/png;base64,'+this.props.QR} />
            </DetailItemContainer>
          :
            <DetailItemContainer centered={true}> 
              <LoaderDialog/>
            </DetailItemContainer>
          }
            <DetailItemContainer>
                <DetailItem> Concepto: </DetailItem>
                <DetailItem bold={true} > {this.props.transaction.concept} </DetailItem>
            </DetailItemContainer>
            <DetailItemContainer>
                <DetailItem> Importe a cobrar: </DetailItem>
                <DetailItem bold={true} > ${this.props.transaction.amount} </DetailItem>
            </DetailItemContainer>
          </DetailContainer>
          {/*
            <DetailContainer centered={true}>
              <DetailItemContainer>
                <DetailItem bold={true} margin={'5% 9%;'}  > Tambien podes compartir el botón de pago al e-mail y a la App del destinatario </DetailItem>
                <Button invert={true}>Enviar botón de pago </Button> 
              </DetailItemContainer>  
            </DetailContainer>*/
          }
        <StyledButton onClick={() => this.props.onClose()} >{'Finalizar'}</StyledButton>
      </Container>
    )
  }
}
  
export default SecondStep
