import React, { Component } from 'react'
import {  
  Container,  
  LabelContainer,
  Row,
  TitleContainer,
  Button,
  StyledButton,
  InputStyled
} from './styled';
import LoaderDialog from 'components/loaders/loaderDialog'
import swipe from "../../../../../assets/images/swipe.svg"
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

class SecondStep extends Component {
  state = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    transaction: {
      cardNumber: null,
      name: null,
      cardExpirationDateMonth: null,
      cardExpirationDateYear: null,
      cardSecurityCode: null
    }
}

handleInputFocus = (e) => {
  this.setState({ focus: e.target.name });
}

handleInputChange = (e) => {
  const { name, value } = e.target;
  
  this.setState({ [name]: value });
}


  parseCardReaded=(card_data)=>{
    if(card_data.target.value.length > 62){

      var details1 = card_data.target.value.split("&");


      var card_number = details1[0];
      card_number = card_number.substring(2);
      
      
      var names = details1[1].split("-");
      var first_name = names[1].trim();
      var last_name = names[0].trim();
      
      
      var exp_year = details1[2].substring(0,2);
      var exp_month = details1[2].substring(2,4);


      this.setState(prevState => ({
        transaction: {
          cardNumber: 376411234531007,
          name: "JOHN DOE",
          cardExpirationDateMonth: 12,
          cardExpirationDateYear: 23,
          cardSecurityCode: 1234
        },
        cvc: 122,
        expiry: exp_month + exp_year,
        focus: '',
        name: first_name+" "+last_name,
        number: card_number,
        searchInput: "" 
      })) 
    }
    
  }

  pay=()=>{
    this.props.updateTransaction(this.state.transaction)
    this.props.NextStep()
  }
  render() {
    return (
      <Container>
          <img src={swipe} />
          <TitleContainer>¡Adelante!</TitleContainer>
          <LabelContainer>
            Tu cliente ya puede ingresar su tarjeta en tu dispositivo de cobro para realizar el pago.
            Aguardá mientras se realiza esta acción.
          </LabelContainer>
          <InputStyled
            name={'searchInput'}
            label={'searchInput'}
            id="searchInput"
            onChange={this.parseCardReaded}
            margin="normal"
            type="text"
            autoFocus="autoFocus"
            opacity={"0"}
          />
          <div id="PaymentForm">
            <Cards
              cvc={this.state.cvc}
              expiry={this.state.expiry}
              focused={this.state.focus}
              name={this.state.name}
              number={this.state.number}
            />
          </div>
        <Button onClick={()=> this.pay()}>Pagar</Button>

          {/*
            <DetailContainer centered={true}>
              <DetailItemContainer>
                <DetailItem bold={true} margin={'5% 9%;'}  > Tambien podes compartir el botón de pago al e-mail y a la App del destinatario </DetailItem>
                <Button invert={true}>Enviar botón de pago </Button> 
              </DetailItemContainer>  
            </DetailContainer>*/
          }
      </Container>
    )
  }
}
  
export default SecondStep
