import React from 'react'
import LeftArrow from 'assets/icons/arrow-left.png'
import {
  Container,
  Wrapper,
  SectionTitle,
  Row,
  StyledButton,
  BackButton,
  LinkText,
  StyledImg
} from './styled'

const Section = ({ title, children,link,linkContent, onLinkClick ,secondlink,secondlinkContent,onSecondLinkClick, btnContent, onBtnClick, onBack, styledHeader }) => (
  <Container>
    <Row>
      <Row>
        {onBack && (
          <BackButton onClick={onBack}>
            <StyledImg src={LeftArrow} />
          </BackButton>
        )}
        <SectionTitle>{title}</SectionTitle>
      </Row>
      {secondlink && (<LinkText margin={true} download="download.txt" href={secondlinkContent} onClick={onSecondLinkClick} target="_blank" >{secondlink}</LinkText> )}
      {link && ( <LinkText download="download.txt" href={linkContent} onClick={onLinkClick} target="_blank" >{link}</LinkText> )}
      
      {!styledHeader && btnContent && (
        <StyledButton onClick={onBtnClick}>{btnContent}</StyledButton>
      )}
      {styledHeader && styledHeader()}
    </Row>
    <Wrapper>{children}</Wrapper>
  </Container>
)

export default Section
