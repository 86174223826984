import styled from 'styled-components'

export const ContainerWebcam = styled.div`
  width: 600px;
  padding:20px;
  -webkit-box-shadow: -1px 0px 9px 0px rgba(0,0,0,0.34);
  -moz-box-shadow: -1px 0px 9px 0px rgba(0,0,0,0.34);
  box-shadow: -1px 0px 9px 0px rgba(0,0,0,0.20);
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Header = styled.div`
  display: flex;
  justify-content:flex-end;
  width: 100%;
`
export const CloseIcon = styled.img`
  cursor: pointer;
  width: 20px;
`
export const Title = styled.h1`
  font-size: 20px;
`
export const WebCapture = styled.div`
  height: 335px;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    max-height: 324px;
  }
`
export const ShowCaptured = styled.div`
  max-height: 324px;
`
export const Footer = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`
export const RedoButton = styled.button`
  cursor: pointer;
  width: 200px;
  background: transparent;
  font-size: 16px;
  border-radius: 20px;
  color: #5178FF;
  border: 1px solid #5178FF;
  margin: 0 1em;
  padding: 12px 30px; 
  transition: 0.5s all ease-out;
  &:focus{
    direction: none;
    outline:none;
  }
`
export const CaptureButton = styled.button`
  cursor: pointer;
  height: 70px;
  width:70px;
  padding: 17px 10px;
  background: transparent;
  font-size: 16px;
  border-radius: 100%;
  color: #5178FF;
  border: 1px solid #5178FF;
  margin: 0 1em; 
  transition: 0.5s all ease-out;
  background-image: url("assets/images/dni.png");
  &:focus{
    outline:none;
  }
`
export const ContinueButton = styled.button`
  cursor: pointer;
  width:200px;
  background: #5178FF;
  font-size: 16px;
  border-radius: 20px;
  color: white;
  border: 1px solid #5178FF;
  margin: 0 1em;
  padding: 12px 20px; 
  transition: 0.5s all ease-out;
  &:focus{
    outline:none;
  }
`