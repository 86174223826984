import styled from 'styled-components'
import fondo from 'assets/images/Cards/blue-puertos.png'

export const CardContainer = styled.div`
  width: 90%;
  min-height: 190px;
  max-height: 600px;
  background-image: url(${fondo});
  margin: 3% 0px 0px 3%;
  border-radius: 5px;
  padding: 0% 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`
export const CardTitle = styled.div`
  width: 100%;
  min-height: 40px;
  max-height: 80px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`
export const CardMoney = styled.div`
  width: 100%;
  min-height: 50px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3%;
`
export const TitleCard = styled.div`
  min-height: 50px;
  max-height: 150px;
  color: white;
  font-size: 35px;  
  align-self: start;
`

export const SubtitleCard = styled.div`
  color: white;
  font-size: 15px;  
  align-self: start;
`
export const LabelCVU = styled.div`
  color: white;
  font-size: 15px;  
  align-self: start;
  margin-left: 2%;
`
export const StyledLabel = styled.div`
  font-weight: ${({ bold }) => bold ? 600 : 400 };
  color: white;
  font-size: 15px;  
  align-self: start;
`
export const ContainerLabel = styled.div`
  margin-top: 6%;
  display: flex;
`
export const ContainerErrorLabel = styled.div`
  margin-top: 17%;
  display: flex;
`