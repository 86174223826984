import React from 'react'
import { connect } from 'react-redux'
import { DataWrapper, DataContainer,Name } from './styled'
import classNames from 'classnames'
import DetailTitle from 'components/DetailTitle'
import UserInfoElementList from 'components/UserInfoElementList'
import UserInfo from 'components/UserInfo'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import StyledTabs from 'components/StyledTabs'
import mapRelativeListPadron from 'selectors/mapRelativeListPadron'
import { fetchRelatives } from 'store/actions/familiares.actions'
import { fetchVehiculos } from 'store/actions/vehicles.actions'
import EmptyLoader from 'components/PageLoader'
import { withWidth, withStyles } from '@material-ui/core'

const ContentWrapper = styled.div`
  display: flex;
`

export const Wrapper = styled.div`
  width: 100%;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid ${({ theme }) => theme.colors.mercury};
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(37, 38, 94, 0.1);
`

class ProprietaryDetail extends React.Component {
  state = {
    tabSelected: 0,
    relativesList: [],
    listVehicles: [],
    isLoading: false
  }

  componentDidUpdate = async prevProps => {
    if (!prevProps.item && this.props.item) {
      this.fetchContent(this.props.item)
    } else if (this.isAnotherItem(prevProps)) {
      await this.setState({ relativesList: [], isLoading: true })
      this.fetchContent(this.props.item)
    }
  }

  isAnotherItem = prevProps => prevProps.item && prevProps.item.id !== this.props.item.id

  fetchContent = selected => {
     this.fetchRelatives(selected)
     this.fetchVehiculos(selected)
  }

  
  fetchRelatives = async selected => {
    await this.props.fetchRelatives(selected.id)
    this.setState({
      relativesList: this.props.relativesList,
      isLoading: this.props.loadingRelatives
    })
  }

  fetchVehiculos = async selected => {
    await this.props.fetchVehiculos(selected.username)
    this.setState({
      listVehicles: this.props.listVehicles,
      isLoading: this.props.loadingListVehicles
    })
  }

  renderDetail = () => {
    return (
      <ContentWrapper>
        <Wrapper>
          <DetailTitle edit={true} onEdit={this.props.onEdit}>
            {'Información personal:'}
          </DetailTitle>
          <UserInfo user={this.props.item} />
          <StyledTabs
            value={this.state.tabSelected}
            tabs={[
              {
                label: 'Familiares',
                value: this.state.relativesList.length
              },
              {
                label: 'Autos',
                value: this.state.listVehicles.length
              }
            ]}
            handleTabChange={this.handleTabChange}
          />
          {this.state.isLoading || this.isLoadingContent(this.props.item) ? (
            <EmptyLoader customHeight={200} />
          ) : (
            <div>
              {(() => {
                switch(this.state.tabSelected) {
                  case 0:
                    return <UserInfoElementList
                              emptyMessage={'familiares'}
                              itemList={this.state.relativesList}
                            />
                  case 1:
                    return <UserInfoElementList
                              emptyMessage={'Vehiculos'}
                              itemList={this.state.listVehicles}
                            />
                  default:
                    return null;
                }
              })()}
            </div>
          )}
        </Wrapper>
      </ContentWrapper>
    )
  }

  isLoadingContent = () =>
   this.props.loadingRelatives && this.props.relativesList.length === 0 || this.props.loadingListVehicles && this.props.listVehicles.length === 0

  handleTabChange = async (event, value) => {
    await this.setState({ tabSelected: value, isLoading: true })
    this.fetchContent(this.props.item)
  }

  renderEmpyDetail = () => (
    <DataWrapper>
      <Name>{'Seleccione un Propietario'}</Name>
    </DataWrapper>
  )

  render() {
    return (
      <DataContainer className={classNames('portal-hide-scrollbars')}>
        {this.props.item ? this.renderDetail() : this.renderEmpyDetail()}
      </DataContainer>
    )
  }
}

const mapStateToProps = ({ familiares, invitados,vehicles }) => {
  return {
    relativesList: mapRelativeListPadron(familiares.relativesList),
    loadingRelatives: familiares.loadingRelatives,
    listVehicles: vehicles.listVehicles,
    loadingVehicles: vehicles.loadingListVehicles,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRelatives,
      fetchVehiculos
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth(),
    withStyles({ withTheme: true })
  )(ProprietaryDetail)
)
