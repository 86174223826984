import styled from 'styled-components';
import mediaQueries from 'config/media-queries';

export const AccordionItem = styled.div`
  width: 100%;
  padding: 3%;
  border-radius: 4px;
  border: solid 0.5px #e9e9e9;
  border-color: ${props => props.valid ? '#cae9a6' : '#e9e9e9'};
  box-sizing: border-box;
  margin-bottom: 5px;
  flex-direction: row;
  background-color: ${props => props.valid ? 'rgba(126, 211, 33, 0.07)' : 'inherit'};
`
export const AccordionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3%;
  margin-bottom: ${props => props.active ? '3%' : '0%'};
  cursor: pointer;
`
export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: flex;
`
export const AccordionToggleIcon = styled.svg`
  cursor: pointer;
  height: 22px;
  width: 22px;
`
export const AccordionBody = styled.div`
  display: flex;
  flex-direction: column;
  display: ${props => props.active ? 'flex' : 'none'};
`
export const ButtonWrapper = styled.div`
  display: ${props => props.active ? 'flex' : 'flex'};
  justify-content: flex-end;
`
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  border: 1px solid ${({ theme }) => theme.colors.blueRibbon};
  font-size: 18px;
  font-weight: 500;
  padding: 13px 30px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`
