import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchTransactionQR } from 'store/actions/billetera.actions'
import { InputStyled , ErrorLabel, Row, SelectStyled,FormContainer, LabelContainer, SelectWrapper ,StyledButton, TitleContainer,IconBack} from './styled';
import ImagesGroup from '../../ImagesGroup/index'
import expensas from 'assets/icons/group-3.svg'
import DatePickerStyled from 'components/DatePickerStyled'
import { Formik } from 'formik'
import * as Yup from 'yup'


const cuentas = [
    { value: 'Expensas', label: 'Expensas',  src: expensas },
    { value: 'Reservas', label: 'Expensas',  src: expensas },
    { value: 'Multas', label: 'Expensas',  src: expensas  },
    { value: 'Otros', label: 'Expensas',  src: expensas  },
  ];
  

const options = [
    { value: 'Expensas', label: 'Expensas' },
    { value: 'Alquiler', label: 'Alquiler' },
    { value: 'Compra', label: 'Compra' },
    { value: 'Gimnasio', label: 'Gimnasio' },
    { value: 'Otro', label: 'Otro' }
  ];

class SecondStep extends React.Component {
  
  getInitialValues = () => ({
    concepto: "Expensas"
  })

  getSignUpSchema = () => Yup.object().shape({
      concepto: Yup.string()
      .required('El campo es requerido'),
      categoria: Yup.string()
      .required('El campo es requerido'),
      amount: Yup.string()
      .required('El campo es requerido'),
      date: Yup.string()
      .required('El campo es requerido'),
  })

  handleFormSubmit = async data => {
    this.props.updateTransaction(data)
    this.props.NextStep()
  };

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <TitleContainer onClick={()=> this.props.PreviousStep()}><IconBack/>{'Paso anterior'}</TitleContainer>
        <LabelContainer>Concepto</LabelContainer>
        <ImagesGroup style={{padding: "3% 0%", margin: "0px" }} cuentas={cuentas} onChange={(e)  => setFieldValue('concepto', e.value)} />
        {errors.concepto && <ErrorLabel>{errors.concepto}</ErrorLabel>}
        <Row> 
          <LabelContainer>Categoría</LabelContainer>
          <SelectWrapper> 
              <SelectStyled
                placeholder={'Seleccionar una categoria...'}
                value={values.categoria}
                options={options}
                onChange={(e) => setFieldValue('categoria', e) }
              /> 
               {errors.categoria && <ErrorLabel>{errors.categoria}</ErrorLabel>}
          </SelectWrapper>
        </Row>
        
        <Row>
        <LabelContainer>Importe a cobrar</LabelContainer>
        <InputStyled 
          prefix="$" 
          value={values.amount} 
          onChangeEvent={e => setFieldValue('amount', e.target.value.slice(1,15))} 
          thousandSeparator={','} 
          decimalSeparator={'.'}/>
          {errors.amount && <ErrorLabel>{errors.amount}</ErrorLabel>}
        </Row>

        <Row>
          <LabelContainer>Asignar fecha de vencimiento: </LabelContainer>
          <DatePickerStyled
            selected={values.date}
            onChange={e => setFieldValue('date', e)}
            minDate={new Date()}
            dateFormat="dd/MM/yyyy"
            placeholderText="Vencimiento"
            bold={true}
          />
          {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
        </Row>
        <StyledButton type="submit" value="submit">Generar botón de pago</StyledButton>
    </FormContainer>
  )

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }
}
  
const mapStateToProps = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionQR
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondStep)
