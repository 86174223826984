import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withWidth from '@material-ui/core/withWidth'
import compose from 'recompose/compose'
import Modal from 'components/Modal'
import spinner from 'assets/animated/spinning-arrows.gif'
import {fetchGetGuest, fetchCheckOut} from 'store/actions/acceso.actions'
import { InputStyled,  Container  } from '../acceso-qr/styled'

class ScanQRModal extends React.Component {
  constructor(props){
    super(props);
    this.timeout =  0;
    this.state = {
      isModalInfoOpen: false,
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    window.addEventListener('blur', this.softcheckToClose, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
    window.removeEventListener('blur', this.softcheckToClose, false);
  }

  softcheckToClose = () => {
    if(!this.state.isModalInfoOpen && !this.props.loading) {
      this.props.onClose(true)
    }
  }

  escFunction = (event) => {
    if(event.keyCode === 27) {
     this.props.onClose()
    }
  }

  doSearch(evt){
    var searchText = evt.target.value; // this is the search text
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.fetchGetGuest(searchText)
    }, 800);
  }

  componentDidUpdate(prevProps) {
    if(!this.props.loading) {
      if (prevProps.accesos.getGuestInvitation !== this.props.accesos.getGuestInvitation) {
        this.props.fetchCheckOut(this.props.accesos.getGuestInvitation.guest_invite_id)
      }
    }
  }
  
  render() {
    return (
      <Modal>
        <Container>
          <InputStyled
            name={'searchInput'}
            label={'searchInput'}
            id="searchInput"
            onChange={evt => this.doSearch(evt)}
            margin="normal"
            type="text"
            autoFocus="autoFocus"
          />
          <img src={spinner}/>          
          {this.props.loading ? "buscando...": ""}     
        </Container>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  accesos,
}) => ({
  loading: accesos.loadingGuestInvitation,
  accesos: accesos,
  error: accesos.errorGuestInvitation,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetGuest, fetchCheckOut
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(withWidth())(ScanQRModal)
  )