import styled from 'styled-components'

export const Container = styled.div`
    min-width: 500px;
    height: 550px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.2s ease;
    box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
    border-radius: 0px 0px 10px 10px;
`

export const Header = styled.div`
    width: 100%;
    height: 150px;
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
    border-radius: 10px 10px 0px 0px;
`

export const Body = styled.div`
    width: 90%;
    height: 350px;
    padding: 3% 5%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
`

export const Title = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: black;
    align-self: baseline;
    margin-top: 30px;
`
export const LabelStyled = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: black;
    align-self: center;
`
export const RowItem = styled.div`
    width: 100%;
    padding: 2% 0%;
    color: black;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid
    ${({ theme }) => (theme.colors.ghost)};
  border-bottom: 1px solid
    ${({ theme }) => (theme.colors.ghost)};
    align-items: center;
`

export const StyledButton = styled.button`
    background-color: ${({ theme,red }) => red ? theme.colors.pomegranate  : theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0px;
    width: 45%;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 5% 0px 5% 0%;

    &:hover{
        background-color: ${({ theme ,red }) => red ? theme.colors.persimmon  : theme.colors.royalBlue};
        transition: all 0.3s ease;
    }
`
export const UserImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 5px;
`
export const CloseIcon = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 15px;
  right: 15px;
`
export const ErrorLabel = styled.label`
    width: 60%;
    align-self: center;
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.red};
    font-size: 16px;
    font-weight: 500;
`
export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
`

