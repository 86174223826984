import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  loadingNewUser: false,
  fetchNewUserFail: false,
  fetchNewUserSuccess: false,
}

const fetchNewUserStart = state => {
  return updateObject(state, { loadingNewUser: true,fetchNewUserSuccess: false,fetchNewUserFail: false })
}

const fetchNewUserSuccess = (state, action) =>
  updateObject(state, {
    loadingNewUser: false, fetchNewUserSuccess: true,fetchNewUserFail: false, userCreated: action.userCreated.values 
  })

const fetchNewUserFail = (state, action) => {
  return updateObject(state, { loadingNewUser: false,fetchNewUserSuccess: false,fetchNewUserFail: action.error })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NEW_USER_SUCCESS:
      return fetchNewUserSuccess(state, action)
    case actionTypes.FETCH_NEW_USER_FAIL:
      return fetchNewUserFail(state, action)
    case actionTypes.FETCH_NEW_USER_START:
      return fetchNewUserStart(state, action)
    default:
      return state
 }
}
export default reducer
