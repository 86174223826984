import React, { Component } from 'react'
import {  Container, AvatarItem, TitleQr,  Row,FormContainer,DestinatarioDetailContainer,DestinatarioDetailItemContainer,DestinatarioDetailItem,StyledButton,IconBack,CheckContainer,TyTitle, GridButtonBack, ButtonBack} from './styled';
import PageLoader from 'components/PageLoader';
import tic from 'assets/icons/tic.svg'
import cancel from 'assets/icons/cancel.png'
import { Avatar } from '@material-ui/core'
import {substract2HourFullDate} from 'utils/dateParser'

export default class ThirdStep extends Component {
    state = {
        selectedOption: true
    }
    
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
  };

  typeOfCard = card => {
    return card === "TC" ? "Tarjeta de crédito " : card
  };

  handleOptionChange = changeEvent => {
     this.setState(prevState => ({
      selectedOption:  !prevState.selectedOption
    }))  
  };  

    render() {
        const billetera = this.props.billetera
        

        return (
            <Container>
                <GridButtonBack item >
                    <ButtonBack onClick={this.props.onClose} variant="fab">
                        <IconBack  />
                    </ButtonBack>
                    <TyTitle >Nuevo cobro con tarjeta</TyTitle>
                </GridButtonBack>
                {this.props.billetera.errorTransactionChargeCard == false && this.props.billetera.loadingTransactionChargeCard == false  ? (
                    <FormContainer onSubmit={this.handleFormSubmit}>
                        <CheckContainer>  
                            <DestinatarioDetailItemContainer>
                                <AvatarItem>
                                    <Avatar
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            padding: '20px',
                                            borderColor: '#e5e5e5',
                                            borderStyle: 'solid',
                                            borderWidth: '10px',
                                            backgroundColor: '#1873f3'
                                        }}
                                    >
                                        <img src={tic} alt="todo" />
                                    </Avatar>
                                    <Row> 
                                        <TitleQr>Listo! El pago fue recibido.</TitleQr>
                                    </Row>
                                </AvatarItem>
                            </DestinatarioDetailItemContainer>
                        </CheckContainer>
                        <DestinatarioDetailContainer>
                            <Row border={true} margin={"2% 0% 10% 0%"}>
                                <TitleQr>Codigo de operacion</TitleQr>
                                <TitleQr spacing={'5px'} size={'18px'} weight={'600'}>{billetera.transactionChargeCard.id}</TitleQr>
                            </Row>
                            <Row margin={"2% 0% 10% 0%"}>
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Fecha y hora : </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} >  {substract2HourFullDate(billetera.transactionChargeCard.date)} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer> 
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Titular: </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > {billetera.transactionChargeCard.source_payment.cardHolderName} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Tipo de tarjeta : </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > {this.typeOfCard(billetera.transactionChargeCard.source_payment.paymentType)} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Monto: </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > ${billetera.transactionChargeCard.amount_net} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>
                                {/* <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Concepto: </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > {billetera.transactionChargeCard.source_payment.cardHolderName} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer> */}
                            </Row>
                        </DestinatarioDetailContainer>
                        <StyledButton onClick={() => this.props.onClose()} >Volver a la Billetera</StyledButton>
                </FormContainer>
            )
            : 
            (<>
                {
                    this.props.billetera.errorTransactionChargeCard == true ? 
                    (
                        <FormContainer fail={true} onSubmit={this.handleFormSubmit}>
                        <CheckContainer >  
                            <DestinatarioDetailItemContainer>
                                <AvatarItem>
                                    <Avatar
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            padding: '20px',
                                            borderColor: '#e5e5e5',
                                            borderStyle: 'solid',
                                            borderWidth: '10px',
                                            backgroundColor: '#e5e5e5'
                                        }}
                                    >
                                        <img src={cancel} alt="todo" />
                                    </Avatar>
                                    <Row> 
                                        <TitleQr  spacing={'2px'} size={'18px'} weight={'600'}>Ups! Algo salio mal.</TitleQr> 
                                        <TitleQr  size={'17px'} weight={'500'}>{billetera.errorTransactionChargeCardMessage}</TitleQr>
                                    </Row>
                                </AvatarItem>
                            </DestinatarioDetailItemContainer>
                        </CheckContainer>
                        {/* <DestinatarioDetailContainer>
                            <Row border={true} margin={"2% 0% 10% 0%"}>
                                <TitleQr>Motivo del error </TitleQr>
                                <TitleQr spacing={'5px'} size={'18px'} weight={'600'}>{billetera.errorTransactionChargeCardMessage}</TitleQr>
                            </Row>
                            <Row margin={"2% 0% 10% 0%"}>
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Fecha y hora : </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} >  {substract2HourFullDate(billetera.transactionChargeCard.date)} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer> 
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Titular: </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > {billetera.transactionChargeCard.source_payment.cardHolderName} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Tipo de tarjeta : </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > {this.typeOfCard(billetera.transactionChargeCard.source_payment.paymentType)} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>
                                <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Estado: </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > No Facturado </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer>
                                {/* <DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItem> Concepto: </DestinatarioDetailItem>
                                    <DestinatarioDetailItem bold={true} > {billetera.transactionChargeCard.source_payment.cardHolderName} </DestinatarioDetailItem>
                                </DestinatarioDetailItemContainer> 
                            </Row>
                        </DestinatarioDetailContainer> */}
                        <StyledButton onClick={() => this.props.onClose()}  fail={true}>Volver a la Billetera</StyledButton>
                    </FormContainer>
                    )
                    :
                    (
                        <PageLoader></PageLoader>
                    )
                }
            </>)}
        </Container>
        )
    }
} 