import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchForgotPassword } from 'store/actions/auth.actions'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import {Container, Wrapper,Title,Subtitle,ButtonStyled,ErrorLabel} from './styled'
import { Formik } from 'formik'
import * as Yup from 'yup'


class Forgot extends Component {
  
  getInitialValues = () => ({
    inputUserName:'',
 })
 
  getSignUpSchema = () =>
    Yup.object().shape({
      inputUserName: Yup.string(),
    })

    
  handleFormSubmit = async data => {
    this.props.fetchForgotPassword(data.inputUserName)
  };
  
  getFormContent = ({ handleSubmit, values, setFieldValue, errors,setSubmitting,OnClickBack }) => (
      <Container onSubmit={handleSubmit}>
        <Wrapper>
          <Title variant="h5" component="h2" gutterBottom>
            Recuperación de Contraseña
          </Title>
          <Subtitle >
            Por favor ingrese su mail. Recibirá un link para crear un nuevo password.
          </Subtitle>
        </Wrapper>
        <Wrapper>
          <TextField 
            value={values.inputUserName}
            error={errors.inputUserName}
            onChange={({ target: value }) => setFieldValue('inputUserName', value.value)}
            component="p" 
            gutterBottom
            label="Dirección de Correo Electrónico" 
            fullWidth 
           />
          <ButtonStyled type="submit" value="submit">
            Reestablecer Contraseña
          </ButtonStyled>
          {errors.inputUserName && <ErrorLabel>{errors.inputUserName}</ErrorLabel>}
          {this.props.forgotPassword ? <ErrorLabel>{this.props.forgotPassword.message}</ErrorLabel> : null}
          <ButtonStyled invert={true} onClick={() => OnClickBack }>Volver al Ingreso</ButtonStyled>
        </Wrapper>
      </Container>
  )

  render() {
    return(
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }
}

Forgot.propTypes = {
  classes: PropTypes.shape({}).isRequired
}

const mapStateToProps = state => {
  return {
    forgotPassword: state.auth.forgotPassword,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForgotPassword,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot)
