import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {withWidth} from '@material-ui/core'
import axios from 'axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { updateObject, checkValidity } from 'shared/utility'
import { bindActionCreators } from 'redux'
import {
  fetchTransactionQR,
} from 'store/actions/billetera.actions'
import PaperLayout from 'components/PaperLayout'
import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep/index'
import ThirdStep from './ThirdStep/index'
import FourStep from './FourStep/index'

class GeneratePay extends Component {
  state = {
    completed: false,
    step: {
      name: 'Creation',
      number: 1,
      percentage: 30,
      },
    GetCodeQRImage: 'data:image/png;base64,'+this.props.GetCodeQRImage,
    transaction: {
      'description': 'description'
    }
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        valid: checkValidity(event.target.value, this.state.controls[controlName].validation),
        touched: true
      })
    })
    this.setState({ controls: updatedControls })
  }

  updateTransaction = (transaction) => {
    this.setState(prevState => ({
      transaction: {
          ...prevState.transaction,
          amount: transaction.amount,
          categoria: transaction.categoria.value,
          concept: transaction.concepto,
          date: transaction.date        //SE USA FECHA DE VENCIMIENTO?
      }
    }), () =>this.props.fetchTransactionQR(this.state.transaction))
  }


  setDestinatario = (person) => {
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        destination_cuit: person.cuit,
        title: person.title,
      }
    }))
  }


  NextStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number:  prevState.step.number + 1,
        percentage: prevState.step.percentage + 30
      }
    }))
  }

  PreviousStep= () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number:  prevState.step.number - 1,
        percentage: prevState.step.percentage - 30
      }
    }))
  }


  render() {
    return ( 
      <div>
        {this.state.completed === false ? ( 
          <PaperLayout title={'Nuevo QR de pago'} onClose={() => {this.props.onClose()}} Step={this.state.step} >
            {(() => {
              switch(this.state.step.number) {
                case 1:
                  return <FirstStep NextStep={() => {this.NextStep()}} setDestinatario={this.setDestinatario} neighbourhood={this.props.neighbourhood}   />;
                case 2:
                  return <SecondStep NextStep={() => {this.NextStep()}} PreviousStep={() => {this.PreviousStep()}} updateTransaction={this.updateTransaction} />;
                case 3:
                  return <ThirdStep NextStep={() => {this.NextStep()}} PreviousStep={() => {this.PreviousStep()}} transaction={this.state.transaction} QR={this.props.GetCodeQRImage} />;
                case 4:
                  return this.setState({completed: true})
                
                default:
                  return null;
              }
            })()}
          </PaperLayout >
        ): (
          <FourStep onClose={() => {this.props.onClose()}} transaction={this.state.transaction} QR={this.props.GetCodeQRImage}/> 
        )}     
      </div>
    )
  }
}

const mapStateToProps  = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
    loadingGetCodeQRImage: state.billetera.loadingGetCodeQRImage,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionQR,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth()
  )(withErrorHandler(GeneratePay, axios))
)
