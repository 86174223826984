import React from 'react'
import styled from 'styled-components'

const EditIcon = props => (
  <svg
    className="prefix__MuiSvgIcon-root-1015 prefix__sc-eIHaNI prefix__bWRgyL"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
)


export const EditIconStyled = styled(EditIcon)`
  fill-opacity: ${({ translucent }) =>  translucent || '1'};
  border-style: solid;
  border-width: ${({border }) => border ? "0.5px" : "0"};
  border-radius: 8px;
  padding: 1%;
  margin: ${({margin }) => margin ? margin : "none"};
  &:hover{
    fill-opacity: 1;
    transition: all 0.3s ease-in-out, background-position 1ms;
    transform: scale(1.1,1.1);
    fill: ${({ theme }) => theme.colors.blueRibbon};
    cursor: pointer;
    border-color: ${({border, theme }) => border ? theme.colors.blueRibbon : "none"};
  }

`
export default EditIconStyled
