import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 15vh;
    left: 25%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1%;
    border-radius: 5px;
    border: 3px solid ${({ theme, success }) => success ? theme.colors.oceanGreen  : theme.colors.pomegranate};
    color: white;
    background-color: ${({ theme, success }) => success ? theme.colors.oceanGreen  : theme.colors.pomegranate}c7;
    text-align: center; 
    opacity: 0.9;
`
export const Items = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 15px 0px;
`
export const Header = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
    cursor: pointer;
`
export const HeaderItem = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`
