import React, { Component } from 'react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';

import {
  AccordionItem,
  Title,
  AccordionToggleIcon,
  AccordionHeader,
  AccordionBody,
} from './styled'

class Accordion extends Component {
  render() {
    const { active, index, formDataValidation} = this.props;
    return(
      <AccordionItem valid={formDataValidation}>
        <AccordionHeader active={active} onClick={() => this.props.changeIndex(index)}>
          <Title>{this.props.title}{formDataValidation ? <Check style={{'marginLeft': '20px'}}/> : null}</Title>
          <AccordionToggleIcon>
            {active ? <Minus /> : <Plus/>}
          </AccordionToggleIcon>
        </AccordionHeader>
        <AccordionBody active={active}>
          {this.props.children}
        </AccordionBody>
      </AccordionItem>
    );
  }
}

export default Accordion;