import * as actionTypes from '../actions/actionTypes'
import { updateObject } from 'shared/utility'

const initialState = {
    getGuestInvitation: "",
    errorGuestInvitation: false,
    loadingGuestInvitation: false,

    checkInSuccess: false,
    checkInFail: false,

    checkOutSuccess: false,
    checkOutFail: false,
    errorCheckOut: false,

    errorPostInvite: false,
    loadingPostInvite: false,
    postInviteSuccess: false,
    createdInvitationId: null,

    dniPutError: null,
    dniPutSuccess: false,
    dniPutLoading: false,
    carPutError: null,
    carPutSuccess: false,
    carPutLoading: false,
    insurancePutError: null,
    insurancePutSuccess: false,
    insurancePutLoading: false,

    searchedInvites: "",
    errorSearchedInvites: false,
    loadingSearchedInvites: false,

    lots: [],
    errorLots: false,
    loadingLots: false,

    loadingVehicleBrands: false,
    loadingVehicleModels: false,
    vehicleBrands: [],
    vehicleModels: [],
}

const clearAcessStore = (state, action) => {
  return updateObject(state, initialState)
}

const fetchGetGuestSuccess = (state, action) => {
  return updateObject(state, { 
    getGuestInvitation: action.getGuestInvitation, 
    loadingGuestInvitation: false,
    errorGuestInvitation: false,
    checkInSuccess: false,
    checkInFail: false  
  })
}

const fetchGetGuestFail = (state, action) => {
  return updateObject(state, { loadingGuestInvitation: false,errorGuestInvitation: action.error })
}

const fetchGetGuestStart = state => {
  return updateObject(state, { 
    loadingGuestInvitation: true,
    checkInSuccess: false,
    checkInFail: false,
    getGuestInvitation: "",
    checkOutSuccess: false,
    checkOutFail: false,
    errorCheckOut: false,
  })
}


// Vehicle Brands

const fetchGetVehicleStart = state => {
  return updateObject(state, { 
    loadingVehicleBrands: true,
  })
}

const fetchGetVehicleSuccess = (state, action) => {
  return updateObject(state, { 
    loadingVehicleBrands: false,
    vehicleBrands: action.vehicles.values
  })
}

const fetchGetVehicleFail = state => {
  return updateObject(state, { 
    loadingVehicleBrands: true,
  })
}

// Vehicle models

const fetchGetVehicleModelStart = state => {
  return updateObject(state, { 
    loadingVehicleModels: true,
  })
}

const fetchGetVehicleModelSuccess = (state, action) => {
  return updateObject(state, { 
    loadingVehicleModels: false,
    vehicleModels: action.vehicles_model.values
  })
}

const fetchGetVehicleModelFail = state => {
  return updateObject(state, { 
    loadingVehicleModels: false,
  })
}

//////

const fetchCheckInSuccess = (state, action) => {
  return updateObject(state, { 
    checkInSuccess: true, 
    loadingGuestInvitation: false,
    errorGuestInvitation: false 
  })
}

const fetchCheckInFail = (state, action)  => {
  return updateObject(state, { 
    loadingGuestInvitation: false,
    checkInFail: true,
    errorGuestInvitation: action.error 
  })
}

const fetchCheckInStart = state => {
  return updateObject(state, { 
    loadingGuestInvitation: true,
    checkInSuccess: false,
    checkInFail: false })
}


//////

const fetchCheckOutSuccess = (state, action) => {
  return updateObject(state, { 
    checkOutSuccess: true, 
    loadingCheckOut: false,
    errorCheckOut: false,
  })
}

const fetchCheckOutFail = (state, action)  => {
  console.log(action)
  return updateObject(state, { 
    loadingCheckOut: false,
    checkOutFail: true,
    errorCheckOut: action.error 
  })
}

const fetchCheckOutStart = state => {
  return updateObject(state, { 
    loadingCheckOut: true,
    checkOutSuccess: false,
    checkOutFail: false })
}
//////

const fetchSearchInviteDniSuccess = (state, action) => {
  return updateObject(state, { 
    searchedInvites: action.searchedInvites, 
    loadingSearchedInvites: false,
    errorSearchedInvites: false })
}

const fetchSearchInviteDniFail = (state, action)  => {
  return updateObject(state, { loadingSearchedInvites: false,
    errorSearchedInvites: action.error })
}

const fetchSearchInviteDniStart = state => {
  return updateObject(state, { 
    loadingSearchedInvites: true,
    postInviteSuccess: false,
    searchedInvites: []
  })
}

const clearSearchedInvites = state => {
  return updateObject(state, { 
    searchedInvites: []
  })
}


//////   POSTS

const fetchPostInviteSuccess = (state, action) => {
  return updateObject(state, { 
    postInviteSuccess: true, 
    loadingPostInvite: false,
    createdInvitationId: action.postInviteSuccess.invitation_id,
    errorPostInvite: false 
  })
}

const fetchPostInviteFail = (state, action)  => {
  return updateObject(state, { 
    loadingPostInvite: false,
    errorPostInvite: action.error,
    createdInvitationId: null, 
  })
}

const fetchPostInviteStart = state => {
  return updateObject(state, { 
    loadingPostInvite: true,
    postInviteSuccess: false,
    createdInvitationId: null,
  })
}


//////   Lots

const fetchLotsSuccess = (state, action) => {
  return updateObject(state, { 
    LotsSuccess: true, 
    loadingLots: false,
    lots: action.lots,
    errorLots: false 
  })
}

const fetchLotsFail = (state, action)  => {
  return updateObject(state, { 
    loadingLots: false,
    errorLots: action.error,
  })
}

const fetchLotsStart = state => {
  return updateObject(state, { 
    loadingLots: true,
    LotsSuccess: false,
  })
}



////// PUTS 

const fetchPutInviteSuccess = (state, action) => {
  let body = `{
    "${action.segment}PutSuccess": ${true}, 
    "${action.segment}PutLoading": ${false},
    "${action.segment}PutError": ${null} 
  }`;
  return updateObject(state, JSON.parse(body))
}

const fetchPutInviteFail = (state, action)  => {
  let body = `{
    "${action.segment}PutLoading": ${false},
    "${action.segment}PutError": "${action.error}"
  }`;
  return updateObject(state, JSON.parse(body))
}

const fetchPutInviteStart = (state, action) => {
  let body = `{
    "${action.segment}PutError": ${null},
    "${action.segment}PutLoading": ${true},
    "${action.segment}PutSuccess": ${false}
  }`;
  return updateObject(state, JSON.parse(body))
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GET_GUEST_SUCCESS:
      return fetchGetGuestSuccess(state, action)
    case actionTypes.FETCH_GET_GUEST_FAIL:
      return fetchGetGuestFail(state, action)
    case actionTypes.FETCH_GET_GUEST_START:
      return fetchGetGuestStart(state, action)
    case actionTypes.FETCH_CHECK_IN_SUCCESS:
      return fetchCheckInSuccess(state, action)
    case actionTypes.FETCH_CHECK_IN_FAIL:
      return fetchCheckInFail(state, action)
    case actionTypes.FETCH_CHECK_IN_START:
      return fetchCheckInStart(state, action)
    case actionTypes.CLEAR_ACCESS_STORE:
      return clearAcessStore(state, action)
    case actionTypes.FETCH_CHECK_OUT_SUCCESS:
      return fetchCheckOutSuccess(state, action)
    case actionTypes.FETCH_CHECK_OUT_FAIL:
      return fetchCheckOutFail(state, action)
    case actionTypes.FETCH_CHECK_OUT_START:
      return fetchCheckOutStart(state, action)
    case actionTypes.FETCH_POST_INVITE_SUCCESS:
      return fetchPostInviteSuccess(state, action)
    case actionTypes.FETCH_POST_INVITE_FAIL:
      return fetchPostInviteFail(state, action)
    case actionTypes.FETCH_POST_INVITE_START:
      return fetchPostInviteStart(state, action)
    case actionTypes.FETCH_SEARCH_INVITES_SUCCESS:
      return fetchSearchInviteDniSuccess(state, action)
    case actionTypes.FETCH_SEARCH_INVITES_FAIL:
      return fetchSearchInviteDniFail(state, action)
    case actionTypes.FETCH_SEARCH_INVITES_START:
      return fetchSearchInviteDniStart(state, action)
    case actionTypes.CLEAR_SEARCHED_INVITES:
      return clearSearchedInvites(state, action)

    case actionTypes.FETCH_PUT_INVITE_SUCCESS:
      return fetchPutInviteSuccess(state, action)
    case actionTypes.FETCH_PUT_INVITE_FAIL:
      return fetchPutInviteFail(state, action)
    case actionTypes.FETCH_PUT_INVITE_START:
      return fetchPutInviteStart(state, action)
    case actionTypes.FETCH_VEHICLES_BRAND_START:
      return fetchGetVehicleStart(state, action)
    case actionTypes.FETCH_VEHICLES_BRAND_SUCCESS:
      return fetchGetVehicleSuccess(state, action)
    case actionTypes.FETCH_VEHICLES_BRAND_FAIL:
      return fetchGetVehicleFail(state, action)
    case actionTypes.FETCH_VEHICLES_MODEL_START:
      return fetchGetVehicleModelStart(state, action)
    case actionTypes.FETCH_VEHICLES_MODEL_SUCCESS:
      return fetchGetVehicleModelSuccess(state, action)
    case actionTypes.FETCH_VEHICLES_MODEL_FAIL:
      return fetchGetVehicleModelFail(state, action)

      case actionTypes.FETCH_LOTS_START:
        return fetchLotsStart(state, action)
      case actionTypes.FETCH_LOTS_SUCCESS:
        return fetchLotsSuccess(state, action)
      case actionTypes.FETCH_LOTS_FAIL:
        return fetchLotsFail(state, action)
  
    default:
      return state
  }
}

export default reducer
