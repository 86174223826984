import { createSelector } from 'reselect'

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapRoles = createSelector(  
  roles => roles,
  roles => 
  (roles.map(rol => ({
    value: rol.code,
    label: rol.name,
    username: rol.username,
    id: rol.id,
    menus: rol.menus
  })) )
)
export default mapRoles
