import React, { Component } from 'react'
import {  Container,  LabelContainer,DestinatarioDetailContainer,DestinatarioDetailItemContainer,DestinatarioDetailItem,StyledButton,Button,TitleContainer,IconBack} from './styled';
import {getFormattedDate} from 'utils/dateParser'
import LoaderDialog from 'components/loaders/loaderDialog'

class ThirdStep extends Component {
    state = {
        selectedOption: true
    }

  handleOptionChange = changeEvent => {
     this.setState(prevState => ({
      selectedOption:  !prevState.selectedOption
    }))
  };  


    render() {
        return (
            <Container>
              <LabelContainer>QR creado exitosamente</LabelContainer>
                <DestinatarioDetailContainer>  
                {this.props.QR ? 
                  <DestinatarioDetailItemContainer centered={true}>
                    <img height={'40%'} width={'35%'} src={'data:image/png;base64,'+this.props.QR} />
                  </DestinatarioDetailItemContainer>
                :
                  <DestinatarioDetailItemContainer centered={true}> 
                    <LoaderDialog/>
                  </DestinatarioDetailItemContainer>
                 }
                  <DestinatarioDetailItemContainer>
                      <DestinatarioDetailItem> Destinatario: </DestinatarioDetailItem>
                      <DestinatarioDetailItem bold={true} > {this.props.transaction.title} </DestinatarioDetailItem>
                  </DestinatarioDetailItemContainer> 
                  <DestinatarioDetailItemContainer>
                      <DestinatarioDetailItem> Concepto: </DestinatarioDetailItem>
                      <DestinatarioDetailItem bold={true} > {this.props.transaction.concept} </DestinatarioDetailItem>
                  </DestinatarioDetailItemContainer>
                  <DestinatarioDetailItemContainer>
                      <DestinatarioDetailItem> Categoria: </DestinatarioDetailItem>
                      <DestinatarioDetailItem bold={true} > {this.props.transaction.categoria} </DestinatarioDetailItem>
                  </DestinatarioDetailItemContainer> 
                  <DestinatarioDetailItemContainer>
                      <DestinatarioDetailItem> Fecha de vencimiento: </DestinatarioDetailItem>
                      <DestinatarioDetailItem bold={true} > {getFormattedDate(this.props.transaction.date)} </DestinatarioDetailItem>
                  </DestinatarioDetailItemContainer>
                  <DestinatarioDetailItemContainer>
                      <DestinatarioDetailItem> Importe a cobrar: </DestinatarioDetailItem>
                      <DestinatarioDetailItem bold={true} > ${this.props.transaction.amount} </DestinatarioDetailItem>
                  </DestinatarioDetailItemContainer>
                </DestinatarioDetailContainer>
              {/* 
                  <DestinatarioDetailContainer centered={true}>
                    <DestinatarioDetailItemContainer>
                      <DestinatarioDetailItem bold={true} margin={'5% 9%;'}  > Tambien podes compartir el botón de pago al e-mail y a la App del destinatario </DestinatarioDetailItem>
                      <Button invert={true}>Enviar botón de pago </Button> 
                    </DestinatarioDetailItemContainer>  
                  </DestinatarioDetailContainer>
              */}  
                
              <StyledButton onClick={() => this.props.NextStep()} >{'Finalizar'}</StyledButton>
            </Container>
        )
    }

}
  
export default ThirdStep
