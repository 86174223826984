import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
    listVehicles: "",
    loadingListVehicles: false,
    errorListVehicles: false,
}

const fetchVehiculosSuccess = (state, action) => {
  return updateObject(state, {
    listVehicles: action.listVehicles,
    loadingListVehicles: false
  })
}

const fetchVehiculosFail = state => {
  return updateObject(state, { loadingListVehicles: false,errorListVehicles: true })
}

const fetchVehiculosStart = state => {
  return updateObject(state, { loadingListVehicles: true })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_VEHICLES_SUCCESS:
        return fetchVehiculosSuccess(state, action)
      case actionTypes.FETCH_VEHICLES_FAIL:
        return fetchVehiculosFail(state, action)
      case actionTypes.FETCH_VEHICLES_START:
        return fetchVehiculosStart(state, action)
  
      default:
        return state
    }
  }
  
  export default reducer
  