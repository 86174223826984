import Input from 'components/Input'
import styled from 'styled-components'

export const InputStyled = styled(Input)`
    opacity: 0;
`
export const Container = styled.form`
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`