import { createSelector } from 'reselect'
import expensasImage from 'assets/icons/expensas.svg'

const whatMonth = (date) => {
  var dateformat = new Date(date);
  return dateformat.getMonth() + 1
}

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapExpensas = createSelector(  
  expensas => expensas,
  expensas => 
  expensas.homeExpensas ? (expensas.homeExpensas.map(expensa => ({
    month: whatMonth(expensa.expenses_header.period_start_date),
    expenses_header: expensa.expenses_header,
    total_cobrado: expensa.total_cobrado,
    total_pendiente_cobrar: expensa.total_pendiente_cobrar,
    total_usuario_debito_directo: expensa.total_usuario_debito_directo,
    avatar: expensasImage
  })) ) : (expensas)
)
export default mapExpensas
