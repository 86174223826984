import React, { Component } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import EmptyLoader from 'components/PageLoader'
import Modalmessage from 'components/ModalMessage'
import ModalDetails from 'components/ModalDetails'
import DoorIn from 'assets/images/door-in.png'
import Report from 'assets/images/registro.png'
import DoorOut from 'assets/images/door-out.png'
import AccessModal from './acceso-qr'
import NuevoInvitado from './nuevo-invitado'
import Exit from './exit'
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';

import {
  Wrapper,
  PageWrapper,
  AccessEntry,
  BigButton,
  Text,
  PngImage
} from './styled'


class Accesos extends Component {
  state = {
    newGuestForm: false,
    scanQRModal: false,
    editMode: false,
    newExit: false,
    succesModal: false,
    failModal: false,
    modalContent: null,
    putsDetailsModal: false,
    showSteps : false
  }

  renderPageContent = () => (
    <>
      <AccessEntry>
        <BigButton onClick={this.toggleNewGuestForm}>
          <PngImage src={DoorIn}/>
          <Text>
            Ingreso de invitado
          </Text>
        </BigButton>
        <BigButton onClick={this.toggleExit}>
          <PngImage src={DoorOut}/>
          <Text>
            Salida de invitado
          </Text>
        </BigButton>
      </AccessEntry>
      <AccessEntry width={"50%"}>
        <BigButton onClick={() =>this.toggleScanQRModal()}>
          <PngImage src={Report}/>
          <Text>
            Reportes e informes
          </Text>
        </BigButton>
      </AccessEntry> 
    </>
  )

  
  toggleSteps = () => {
    this.setState({ 
      showSteps: !this.state.showSteps 
    })
  }

  editMode = () => {
    this.setState({
      scanQRModal: false,
      newGuestForm: true,
      editMode: true,
      showSteps: !this.state.showSteps
    })
  }
  
  toggleNewGuestForm = () => {
    this.setState({ 
      editMode: false,
      newGuestForm: !this.state.newGuestForm,
      showSteps: false
     })
  } 

  toggleScanQRModal = (close) => {
    if(close) {
      this.setState({ 
        scanQRModal: false 
      })
    } else {
      this.setState({ 
        scanQRModal: !this.state.scanQRModal 
      })
    }
  }

  toggleSuccesModal = (modalContent) => {
    this.setState({ 
      succesModal: !this.state.succesModal,
      modalContent,
      failModal: false,
    })
  }

  closeAllModals = () => {
    this.setState({ 
      failModal: false,
      succesModal: false,
      showSteps: false
    })
  }

  onSuccess = () => {
    this.setState({ 
      scanQRModal: false,
      newGuestForm: false,
      newExit: false,
      editMode: false,
      showSteps: false
    })
  }

  toggleFailModal = (modalContent) => {
    this.setState({ 
      failModal: !this.state.failModal,
      modalContent,
      succesModal: false,
     })
  }

  toggleExit = () => {
    this.setState({ 
      newExit: 
      !this.state.newExit 
    })
  }

  togglePutsModalDetails = () => {
    this.setState({ 
      putsDetailsModal: !this.state.putsDetailsModal 
    })
  }

  isLoadingContent = () => this.props.loading;

  componentDidUpdate(prevProps) {
    if(this.props.loadingPuts && !prevProps.loadingPuts) {
      this.togglePutsModalDetails();
    }
  }

  render() {
    const {newGuestForm, scanQRModal, succesModal, failModal, editMode, newExit, putsDetailsModal} = this.state;
    if (newExit) {
      return(
        <Exit 
          onClose={this.toggleExit}      
          toggleSuccesModal={this.toggleSuccesModal}
          onSuccess={this.onSuccess}
          toggleFailModal={this.toggleFailModal} 
        />
      )
    } else {
    return (
      <Page>
        { !newGuestForm ? 
          <PageWrapper>
            <Section title={'Control de acceso'}>
              { succesModal || failModal ? 
                <Modalmessage success={succesModal ? true : false} onClose={this.closeAllModals}>
                  {this.state.modalContent}
                </Modalmessage> : <></>
              }
              {
                putsDetailsModal ? 
                <ModalDetails 
                  success={this.props.successPuts} 
                  errors={this.props.allPutsError} 
                  onClose={this.togglePutsModalDetails}
                /> : null
              }
              <Wrapper>
                {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
              </Wrapper>
            </Section>
          </PageWrapper>
          :
          <PageWrapper>
            <Section title={'Nuevo ingreso'} onBack={this.toggleNewGuestForm}>
              <NuevoInvitado 
                editMode={editMode}  
                onSuccesCheckIn={this.toggleSuccesModal}
                onFailCheckIn={this.toggleFailModal}
                toggleSuccesModal={this.toggleSuccesModal}
                toggleSteps={this.toggleSteps}
                showSteps={this.state.showSteps}
                toggleScanQRModal={this.toggleScanQRModal}
                onSuccess={this.onSuccess}
              />
            </Section>
          </PageWrapper>
        }
        { scanQRModal && (
          <AccessModal 
            onClose={this.toggleScanQRModal}
            toggleSuccesModal={this.toggleSuccesModal}
            toggleFailModal={this.toggleFailModal}
            onSuccess={this.onSuccess}
            editMode={this.editMode}  
            onFailCheckIn={this.toggleFailModal}
            onSuccesCheckIn={this.toggleSuccesModal}        
          />
        )}
      </Page>
    )}
  }
}


const mapStateToProps = ({
  accesos,
}) => ({
  loadingPuts: accesos.carPutLoading && accesos.dniPutLoading && accesos.insurancePutLoading,
  successPuts: accesos.carPutSuccess && accesos.dniPutSuccess && accesos.insurancePutSuccess,
  carPutError: accesos.carPutError,
  dniPutError: accesos.dniPutError,
  insurancePutError: accesos.insurancePutError,
  allPutsError: [{label: 'Vehiculo', error: accesos.carPutError},{label: 'DNI', error: accesos.dniPutError},{label: 'Seguro del vehiculo', error: accesos.insurancePutError}],
  carPutSuccess: accesos.carPutSuccess,
  dniPutSuccess: accesos.dniPutSuccess,
  insurancePutSuccess: accesos.insurancePutSuccess,
  accesos: accesos,
  createdInvitationId: accesos.createdInvitationId,
  postInviteSuccess: accesos.postInviteSuccess,
  error: accesos.errorPostInvite,
  checkInSucces: accesos.checkInSuccess,
  checkInFail: accesos.checkInFail
})

const mapDispatchToProps = dispatch => bindActionCreators({},dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(Accesos))
