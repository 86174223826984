import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import withWidth from '@material-ui/core/withWidth'
import classNames from 'classnames'
import EmptyLoader from 'components/PageLoader'
import mapGuests from 'selectors/mapGuests'
import mapFilteredGuests from 'selectors/mapFilteredGuests'
import { Avatar, Grid} from '@material-ui/core'
import {fetchTransactionData} from 'store/actions/billetera.actions'
import SearchBar from 'components/SearchBar'
import { SelectStyled, SearchWrapper, ContentWrapper,Charge, Container, MovementContainer, Row, Date,Price,Label , PageWrapper,Wrapper,ContainerSelects,ContainerSearchBar, ImgContainer } from './styled'
import expensas from 'assets/icons/group-3.svg';
import mapAllMovements from 'selectors/mapAllMovements'
import MaterialTable from 'material-table'
import arrowDown from 'assets/icons/arrow-bottom.png'
import ModalDetailTransfer from "../ModalDetailTransfer"


const options = [
    { value: 'recent', label: 'Recientes' },
    { value: 'maxvalue', label: 'Mayor valor' },
    { value: 'minvalue', label: 'Menor valor' }
  ];

  const isSelected = (movement, selected) => selected && movement.id === selected.id

  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ARS',
    });

class AllMovements extends Component {
    state = {
        filter: '',
        isDetailItemOpen: false,
        detailTransferOpen: false
      }
    
    renderPageContent = () => (
        <SearchWrapper>
            <ContainerSearchBar>
                <SearchBar
                    placeholder={'Busca por: alias,categoría, o subcategoría'}
                    style={'width: \'70%\' '}
                    value={this.state.filter}
                    onChange={this.handleInputOnChange}
                    onClear={this.handleInputClear}
                    onKeyDown={this.handleKeyPress}
                    noBorder={true}
                />
                {/* <ContainerSelects>
                    <ImgContainer>
                        <img src={expensas}/>
                    </ImgContainer>
                    <SelectStyled
                        value={this.state.filter}
                        onChange={this.handleSelectOnChange}
                        options={options}
                        placeholder={'2019'}
                    />
                </ContainerSelects> */}
            </ContainerSearchBar>
            <ContentWrapper>
                <Container className={classNames('portal-hide-scrollbars')}>
                <MaterialTable
                    columns={[
                        { title: 'Descripcion', field: 'description', },
                        { title: 'Fecha de pago', field: 'datePay', },
                        { title: 'Destinatario', field: 'destinationName', },
                        { title: 'Importe', field: 'amount',render: rowData =>  
                            <Price amount={rowData.amount}>
                                {formatter.format(rowData.amount)} 
                            </Price> 
                        }
                    ]}
                    data={this.props.AllMovements}        
                    onRowClick={(event, rowData, togglePanel) => this.onMovementClick(rowData)}
                    onSelectionChange={(rows) => this.onMovementClick( rows )}
                    options={{
                        toolbar: false,
                        paging: false,
                        height: '300px',
                        selection: false,
                        headerStyle: {
                            zIndex: 0
                        }
                    }} //Esconde titulo y search y pagination 
                />
                </Container>
           </ContentWrapper>
        </SearchWrapper>
    )
    
    isFilterEnabled = () => this.state.filterEnabled

    handleInputOnChange = value => this.setState({ filter: value })

    handleFilterOnChange = value => this.setState({ select: value })

    handleInputClear = value => {
        this.setState({ filter: value, filterEnabled: false })
    }

    handleKeyPress = e => {
        if(e.keyCode === 13){
            //hacer algo con el enter
        }
    }  

    onMovementClick = (rowData) => {
        this.props.fetchTransactionData(rowData.id)
        this.setState({detailTransferOpen: true})
    }

    isContentAvaiable = () => this.props.guests.length > 0 || this.props.filteredGuests.length > 0

    isLoadingContent = () => this.props.loading && this.props.guests.length === 0


    render() {
        return (
            <Page>
                <PageWrapper>
                    <Section 
                        title={'Todos los movimientos'} 
                        onBack={this.props.onClose} 
                    >
                    <Wrapper>
                        {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
                        {this.state.detailTransferOpen ? 
                            <ModalDetailTransfer 
                                onClose={()=> this.setState({detailTransferOpen: false})} 
                            /> : 
                            <></>
                        }
                    </Wrapper>
                </Section>
            </PageWrapper>
        </Page>
        )
    }
}
    
    const mapStateToProps = state => ({
        AllMovements: mapAllMovements(state.billetera.TransactionsList),
        billetera: state.billetera
    })
    
    const mapDispatchToProps = dispatch =>
        bindActionCreators({
            fetchTransactionData,
        }, dispatch)
    
    export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      compose(
        withWidth()
      )(AllMovements)
    )
    