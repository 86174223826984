import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import MENU_HEADER_LINKS from 'config/headerMenuLinks'
import { HeaderMenuWrapper, MenuItem, MenuLabel } from './styled'

const HeaderMenu = (props) => {
  const onLinkClick = link => props.history.push(`/${link}`)
  const isActive = link => link === props.active
  return (
    <HeaderMenuWrapper>
      {props.userInfo.menusAuth.map(menu=>{
          if(menu.code === 'WALLET_BO'){
            return(
              <MenuItem active={isActive('billetera')} key={menu.name} onClick={() => onLinkClick('billetera')}>
                <MenuLabel active={isActive('billetera')}>Billetera</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'USERROLE_BO'){
            return(
              <MenuItem 
              active={isActive('users')} 
              key={'users'} 
              onClick={() =>onLinkClick('users')}
            >
              <MenuLabel active={isActive('users')}>Usuarios</MenuLabel>
            </MenuItem>
            )
          }
          if(menu.code === 'FEED_BO'){
            return(
              <MenuItem active={isActive('feed')} key={menu.name} onClick={() => onLinkClick('feed')}>
                <MenuLabel active={isActive('feed')}>Feed</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'PEOPLE_BO'){
            return(
              <MenuItem active={isActive('padron')} key={menu.name} onClick={() => onLinkClick('padron')}>
                <MenuLabel active={isActive('padron')}>Padron</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'RESERV_BO'){
            return(
              <MenuItem active={isActive('reservas')} key={menu.name} onClick={() => onLinkClick('reservas')}>
                <MenuLabel active={isActive('reservas')}>Reservas</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'GUESTS_BO'){
            return(
              <MenuItem active={isActive('invitados')} key={menu.name} onClick={() => onLinkClick('invitados')}>
                <MenuLabel active={isActive('invitados')}>Invitados</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'SUPPLIERS_BO'){
            return(
              <MenuItem active={isActive('proveedores')} key={menu.name} onClick={() => onLinkClick('proveedores')}>
                <MenuLabel active={isActive('proveedores')}>Proveedores</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'SECURITY_BO'){
            return(
              <MenuItem active={isActive('seguridad')} key={menu.name} onClick={() => onLinkClick('seguridad')}>
                <MenuLabel active={isActive('seguridad')}>Seguridad</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'EXPEN_BO'){
            return(
              <MenuItem active={isActive('expensas')} key={menu.name} onClick={() => onLinkClick('expensas')}>
                <MenuLabel active={isActive('expensas')}>Expensas</MenuLabel>
              </MenuItem>
            )
          }
          if(menu.code === 'ACCESS_BO'){
            return(
              <MenuItem active={isActive('accesos')} key={'Accesos'} onClick={() => onLinkClick('accesos')}>
                <MenuLabel active={isActive('accesos')}>Accesos</MenuLabel>
              </MenuItem>
            )
          }
          // if(menu.code === 'TICKETS_BO' || menu.code === 'TICKETS_ADMIN_BO'){
          if(menu.code === 'TICKETS_BO' || menu.code === 'TICKETS_ADMIN_BO'){
            return(
                <MenuItem 
                  active={isActive('tickets')} 
                  key={'Tickets'} 
                  onClick={() => onLinkClick('tickets')}
                >
                  <MenuLabel active={isActive('tickets')}>Tickets</MenuLabel>
                </MenuItem>
            )
          }
        })
      }
    </HeaderMenuWrapper>
  )
}

const mapStateToProps = ({ userInfo }) => ({
  userInfo
})

export default connect(
  mapStateToProps,
  null
)(withRouter(HeaderMenu))
