import React from 'react'
import NoFile from 'assets/icons/empty-document.png'
import CheckedImg from 'assets/icons/checked.png'
import inputTypes from 'constants/inputTypes'
import {
  AttachedContainer,
  CheckedElement,
  DocumentImg,
  FileInputWrapper,
  StyledFileInput
} from './styled'

class FileInput extends React.Component {
  state = {
    url: "",
    loading: false,
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.image })
  }

  hasAttachedContent = () => this.state.url

  fileSelectedHandler = async e => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      this.props.onChange(text)
      this.setState({text: text})
    };
    reader.readAsText(e.target.files[0])
    return reader
  }

  render() {
    return (
      <FileInputWrapper>
        {this.props.onChange && (
          <StyledFileInput type={inputTypes.FILE} onChange={this.fileSelectedHandler} />
        )}
        <AttachedContainer>
          {this.state.text ? <CheckedElement src={CheckedImg} /> : ""}
          <DocumentImg src={this.state.url || NoFile} />
        </AttachedContainer>
      </FileInputWrapper>
    )
  }
}

export default FileInput
