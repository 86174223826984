//AUTH
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS'
export const FETCH_FORGOT_PASSWORD_FAIL = 'FETCH_FORGOT_PASSWORD_FAIL'
export const FETCH_FORGOT_PASSWORD_START = 'FETCH_FORGOT_PASSWORD_START'

export const FETCH_TOKEN_AUTH_LOGIN_SUCCESS = 'FETCH_TOKEN_AUTH_LOGIN_SUCCESS'
export const FETCH_TOKEN_AUTH_LOGIN_FAIL = 'FETCH_TOKEN_AUTH_LOGIN_FAIL'
export const FETCH_TOKEN_AUTH_LOGIN_START = 'FETCH_TOKEN_AUTH_LOGIN_START'

export const AUTH_REQUIRE_TOKEN = 'AUTH_REQUIRE_TOKEN'


//LAYOUT
export const UPDATE_LAYOUT = '(LAYOUT) UPDATE_THEME_LAYOUT'
export const TOGGLE_SIDENAV = '(LAYOUT) TOGGLE_SIDENAV'
export const SET_SIDENAV_OPEN = '(LAYOUT) SET_SIDENAV_OPEN'
export const TOGGLE_SIDENAV_VARIANT = '(LAYOUT) TOGGLE_SIDENAV_VARIANT'
export const TOGGLE_NOTIFICATIONS = '(LAYOUT) TOGGLE_NOTIFICATIONS'

//THEMES
export const CHANGE_THEME = '(THEME) CHANGE_THEME'
export const CHANGE_SIDENAV_TOOLBAR_BACKGROUND = '(THEME) CHANGE_SIDENAV_TOOLBAR_BACKGROUND'
export const CHANGE_SIDENAV_TOOLBAR_TEXT = '(THEME) CHANGE_SIDENAV_TOOLBAR_TEXT'
export const CHANGE_SIDENAV_PALETTE_TYPE = '(THEME) CHANGE_SIDENAV_PALETTE_TYPE'
export const CHANGE_CONTENT_TOOLBAR_BACKGROUND = '(THEME) CHANGE_CONTENT_TOOLBAR_BACKGROUND'
export const CHANGE_CONTENT_TOOLBAR_TEXT = '(THEME) CHANGE_CONTENT_TOOLBAR_TEXT'
export const CHANGE_CONTENT_PALETTE_TYPE = '(THEME) CHANGE_CONTENT_PALETTE_TYPE'
export const CHANGE_PRIMARY_PALETTE_BACKGROUND = '(THEME) CHANGE_PRIMARY_PALETTE_BACKGROUND'
export const CHANGE_PRIMARY_PALETTE_TEXT = '(THEME) CHANGE_PRIMARY_PALETTE_TEXT'
export const CHANGE_SECONDARY_PALETTE_BACKGROUND = '(THEME) CHANGE_SECONDARY_PALETTE_BACKGROUND'
export const CHANGE_SECONDARY_PALETTE_TEXT = '(THEME) CHANGE_SECONDARY_PALETTE_TEXT'
export const CHANGE_THEME_DIRECTION = '(THEME) CHANGE_TEXT_DIRECTION'

//SECURITYSTAFF
export const FETCH_SECURITYSTAFF_START = 'FETCH_SECURITYSTAFF_START'
export const FETCH_SECURITYSTAFF_SUCCESS = 'FETCH_SECURITYSTAFF_SUCCESS'
export const FETCH_SECURITYSTAFF_FAIL = 'FETCH_SECURITYSTAFF_FAIL'
export const CREATE_SECURITYSTAFF_START = 'CREATE_SECURITYSTAFF_START'
export const CREATE_SECURITYSTAFF_SUCCESS = 'CREATE_SECURITYSTAFF_SUCCESS'
export const CREATE_SECURITYSTAFF_FAIL = 'CREATE_SECURITYSTAFF_FAIL'
export const FILTER_SEARCH_CHANGED = 'FILTER_SEARCH_CHANGED'

//PADRON
export const FETCH_PADRON_START = 'FETCH_PADRON_START'
export const FETCH_PADRON_SUCCESS = 'FETCH_PADRON_SUCCESS'
export const FETCH_PADRON_FAIL = 'FETCH_PADRON_FAIL'
export const CREATE_PROPRIETARY_START = 'CREATE_PROPRIETARY_START'
export const CREATE_PROPRIETARY_SUCCESS = 'CREATE_PROPRIETARY_SUCCESS'
export const CREATE_PROPRIETARY_FAIL = 'CREATE_PROPRIETARY_FAIL'
export const IMPORT_PROPRIETARY_START = 'IMPORT_PROPRIETARY_START'
export const IMPORT_PROPRIETARY_SUCCESS = 'IMPORT_PROPRIETARY_SUCCESS'
export const IMPORT_PROPRIETARY_FAIL = 'IMPORT_PROPRIETARY_FAIL'

//INVITADOS
export const FETCH_GUESTS_START = 'FETCH_GUESTS_START'
export const FETCH_GUESTS_SUCCESS = 'FETCH_GUESTS_SUCCESS'
export const FETCH_GUESTS_FAIL = 'FETCH_GUESTS_FAIL'
export const FETCH_RELATIVE_GUESTS_START = 'FETCH_RELATIVE_GUESTS_START'
export const FETCH_RELATIVE_GUESTS_SUCCESS = 'FETCH_RELATIVE_GUESTS_SUCCESS'
export const FETCH_RELATIVE_GUESTS_FAIL = 'FETCH_RELATIVE_GUESTS_FAIL'
export const FETCH_INVITACIONES_START = 'FETCH_INVITACIONES_START'
export const FETCH_INVITACIONES_SUCCESS = 'FETCH_INVITACIONES_SUCCESS'
export const FETCH_INVITACIONES_FAIL = 'FETCH_INVITACIONES_FAIL'
export const FETCH_FILTERED_GUESTS_START = 'FETCH_FILTERED_GUESTS_START'
export const FETCH_FILTERED_GUESTS_SUCCESS = 'FETCH_FILTERED_GUESTS_SUCCESS'
export const FETCH_FILTERED_GUESTS_FAIL = 'FETCH_FILTERED_GUESTS_FAIL'

//FAMILIARES
export const FETCH_RELATIVES_START = 'FETCH_RELATIVES_START'
export const FETCH_RELATIVES_SUCCESS = 'FETCH_RELATIVES_SUCCESS'
export const FETCH_RELATIVES_FAIL = 'FETCH_RELATIVES_FAIL'

//AUTOS

export const FETCH_VEHICLES_START = 'FETCH_VEHICLES_START'
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS'
export const FETCH_VEHICLES_FAIL = 'FETCH_VEHICLES_FAIL'

//LOTES
export const FETCH_LOTS_BY_NEIGHBOURHOOD_START = 'FETCH_LOTS_BY_NEIGHBOURHOOD_START'
export const FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS = 'FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS'
export const FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL = 'FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL'

export const FETCH_EDIT_PERSON_START = 'FETCH_EDIT_PERSON_START'
export const FETCH_EDIT_PERSON_SUCCESS = 'FETCH_EDIT_PERSON_SUCCESS'
export const FETCH_EDIT_PERSON_FAIL = 'FETCH_EDIT_PERSON_FAIL'

//FEED
export const FETCH_EVENTS_START = 'FETCH_EVENTS_START'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL'
export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAIL = 'FETCH_ARTICLES_FAIL'
export const CREATE_NEW_EVENT_START = 'CREATE_NEW_EVENT_START'
export const CREATE_NEW_EVENT_SUCCESS = 'CREATE_NEW_EVENT_SUCCESS'
export const CREATE_NEW_EVENT_FAIL = 'CREATE_NEW_EVENT_FAIL'
export const CREATE_NEW_ARTICLE_START = 'CREATE_NEW_ARTICLE_START'
export const CREATE_NEW_ARTICLE_SUCCESS = 'CREATE_NEW_ARTICLE_SUCCESS'
export const CREATE_NEW_ARTICLE_FAIL = 'CREATE_NEW_ARTICLE_FAIL'
export const DELETE_ARTICLE_START = 'DELETE_ARTICLE_START'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'
export const DELETE_EVENT_START = 'DELETE_EVENT_START'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL'

//MENSAJES
export const FETCH_MSJ_RECIBIDO_START = 'FETCH_MSJ_RECIBIDO_START'
export const FETCH_MSJ_RECIBIDO_SUCCESS = 'FETCH_MSJ_RECIBIDO_SUCCESS'
export const FETCH_MSJ_RECIBIDO_FAIL = 'FETCH_MSJ_RECIBIDO_FAIL'
export const CREATE_NEW_PRIVATE_MSJ_START = 'CREATE_NEW_PRIVATE_MSJ_START'
export const CREATE_NEW_PRIVATE_MSJ_SUCCESS = 'CREATE_NEW_PRIVATE_MSJ_SUCCESS'
export const CREATE_NEW_PRIVATE_MSJ_FAIL = 'CREATE_NEW_PRIVATE_MSJ_FAIL'
export const FETCH_MSJ_ENVIADOS_START = 'FETCH_MSJ_ENVIADOS_START'
export const FETCH_MSJ_ENVIADOS_SUCCESS = 'FETCH_MSJ_ENVIADOS_SUCCESS'
export const FETCH_MSJ_ENVIADOS_FAIL = 'FETCH_MSJ_ENVIADOS_FAIL'
export const FETCH_MSJ_UNREAD_START = 'FETCH_MSJ_UNREAD_START'
export const FETCH_MSJ_UNREAD_SUCCESS = 'FETCH_MSJ_UNREAD_SUCCESS'
export const FETCH_MSJ_UNREAD_FAIL = 'FETCH_MSJ_UNREAD_FAIL'
export const CREATE_NEW_PUBLIC_MSJ_START = 'CREATE_NEW_PUBLIC_MSJ_START'
export const CREATE_NEW_PUBLIC_MSJ_SUCCESS = 'CREATE_NEW_PUBLIC_MSJ_SUCCESS'
export const CREATE_NEW_PUBLIC_MSJ_FAIL = 'CREATE_NEW_PUBLIC_MSJ_FAIL'
export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'

//PROVEEDORES
export const FETCH_CATEGORIAS_START = 'FETCH_CATEGORIAS_START'
export const FETCH_CATEGORIAS_SUCCESS = 'FETCH_CATEGORIAS_SUCCESS'
export const FETCH_CATEGORIAS_FAIL = 'FETCH_CATEGORIAS_FAIL'
export const FETCH_PROVEEDORES_START = 'FETCH_PROVEEDORES_START'
export const FETCH_PROVEEDORES_SUCCESS = 'FETCH_PROVEEDORES_SUCCESS'
export const FETCH_PROVEEDORES_FAIL = 'FETCH_PROVEEDORES_FAIL'
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START'
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL'
export const FETCH_NEIGHBOURHOOD_START = 'FETCH_NEIGHBOURHOOD_START'
export const FETCH_NEIGHBOURHOOD_SUCCESS = 'FETCH_NEIGHBOURHOOD_SUCCESS'
export const FETCH_NEIGHBOURHOOD_FAIL = 'FETCH_NEIGHBOURHOOD_FAIL'
export const CREATE_SUPPLIERS_START = 'CREATE_SUPPLIERS_START'
export const CREATE_SUPPLIERS_SUCCESS = 'CREATE_SUPPLIERS_SUCCESS'
export const CREATE_SUPPLIERS_FAIL = 'CREATE_SUPPLIERS_FAIL'
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL'
export const CREATE_SERVICE_START = 'CREATE_SERVICE_START'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL'
export const FETCH_SUPPLIERS_REQUESTS_START = 'FETCH_SUPPLIERS_REQUESTS_START'
export const FETCH_SUPPLIERS_REQUESTS_SUCCESS = 'FETCH_SUPPLIERS_REQUESTS_SUCCESS'
export const FETCH_SUPPLIERS_REQUESTS_FAIL = 'FETCH_SUPPLIERS_REQUESTS_FAIL'

//RESERVAS
export const FETCH_ACTIVIDADES_START = 'FETCH_ACTIVIDADES_START'
export const FETCH_ACTIVIDADES_SUCCESS = 'FETCH_ACTIVIDADES_SUCCESS'
export const FETCH_ACTIVIDADES_FAIL = 'FETCH_ACTIVIDADES_FAIL'
export const CREATE_ACTIVIDAD_START = 'CREATE_ACTIVIDAD_START'
export const CREATE_ACTIVIDAD_SUCCESS = 'CREATE_ACTIVIDAD_SUCCESS'
export const CREATE_ACTIVIDAD_FAIL = 'CREATE_ACTIVIDAD_FAIL'
export const FETCH_SPACES_START = 'FETCH_SPACES_START'
export const FETCH_SPACES_SUCCESS = 'FETCH_SPACES_SUCCESS'
export const FETCH_SPACES_FAIL = 'FETCH_SPACES_FAIL'
export const CREATE_SPACE_START = 'CREATE_SPACE_START'
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS'
export const CREATE_SPACE_FAIL = 'CREATE_SPACE_FAIL'
export const CREATE_EXCEPTIONS_START = 'CREATE_EXCEPTIONS_START'
export const CREATE_EXCEPTIONS_SUCCESS = 'CREATE_EXCEPTIONS_SUCCESS'
export const CREATE_EXCEPTIONS_FAIL = 'CREATE_EXCEPTIONS_FAIL'
export const FETCH_BOOKING_REQUESTS_START = 'FETCH_BOOKING_REQUESTS_START'
export const FETCH_BOOKING_REQUESTS_SUCCESS = 'FETCH_BOOKING_REQUESTS_SUCCESS'
export const FETCH_BOOKING_REQUESTS_FAIL = 'FETCH_BOOKING_REQUESTS_FAIL'

//NEIGHBOURHOOD
export const FETCH_DEFAULT_ACTIVITIES_START = 'FETCH_DEFAULT_ACTIVITIES_START'
export const FETCH_DEFAULT_ACTIVITIES_SUCCESS = 'FETCH_DEFAULT_ACTIVITIES_SUCCESS'
export const FETCH_DEFAULT_ACTIVITIES_FAIL = 'FETCH_DEFAULT_ACTIVITIES_FAIL'
export const FETCH_DEFAULT_CATEGORIES_START = 'FETCH_DEFAULT_CATEGORIES_START'
export const FETCH_DEFAULT_CATEGORIES_SUCCESS = 'FETCH_DEFAULT_CATEGORIES_SUCCESS'
export const FETCH_DEFAULT_CATEGORIES_FAIL = 'FETCH_DEFAULT_CATEGORIES_FAIL'

//BILLETERA
export const  FETCH_TRANSACTION_LIST_FAIL = ' FETCH_TRANSACTION_LIST_FAIL'
export const  FETCH_TRANSACTION_LIST_START = ' FETCH_TRANSACTION_LIST_START'
export const  FETCH_TRANSACTION_LIST_SUCCESS = ' FETCH_TRANSACTION_LIST_SUCCESS'
export const FETCH_TRANSACTION_QR_FAIL = 'FETCH_TRANSACTION_QR_FAIL'
export const FETCH_TRANSACTION_QR_START = 'FETCH_TRANSACTION_QR_START'
export const FETCH_TRANSACTION_QR_SUCCESS = 'FETCH_TRANSACTION_QR_SUCCESS'
export const FETCH_REQUESTED_TRANSACTION_SUCCESS = 'FETCH_REQUESTED_TRANSACTION_SUCCESS'
export const FETCH_REQUESTED_TRANSACTION_FAIL = 'FETCH_REQUESTED_TRANSACTION_FAIL'
export const FETCH_REQUESTED_TRANSACTION_START = 'FETCH_REQUESTED_TRANSACTION_START'
export const FETCH_GET_CODE_QR_IMAGE_START = 'FETCH_GET_CODE_QR_IMAGE_START'
export const FETCH_GET_CODE_QR_IMAGE_FAIL = 'FETCH_GET_CODE_QR_IMAGE_FAIL'
export const FETCH_GET_CODE_QR_IMAGE_SUCCESS = 'FETCH_GET_CODE_QR_IMAGE_SUCCESS'
export const FETCH_GET_ACCOUNT_SUCCESS = 'FETCH_GET_ACCOUNT_SUCCESS'
export const FETCH_GET_ACCOUNT_FAIL = 'FETCH_GET_ACCOUNT_FAIL'
export const FETCH_GET_ACCOUNT_START = 'FETCH_GET_ACCOUNT_START'
export const FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS = 'FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS'
export const FETCH_GET_ACCOUNT_BY_LOTE_FAIL = 'FETCH_GET_ACCOUNT_BY_LOTE_FAIL'
export const FETCH_GET_ACCOUNT_BY_LOTE_START = 'FETCH_GET_ACCOUNT_BY_LOTE_START'
export const FETCH_GET_ACCOUNT_DEBIT_SUCCESS = 'FETCH_GET_ACCOUNT_DEBIT_SUCCESS'
export const FETCH_GET_ACCOUNT_DEBIT_FAIL = 'FETCH_GET_ACCOUNT_DEBIT_FAIL'
export const FETCH_GET_ACCOUNT_DEBIT_START = 'FETCH_GET_ACCOUNT_DEBIT_START'
export const FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS = 'FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS'
export const FETCH_CREATE_TRANSACTION_REQUEST_FAIL = 'FETCH_CREATE_TRANSACTION_REQUEST_FAIL'
export const FETCH_CREATE_TRANSACTION_REQUEST_START = 'FETCH_CREATE_TRANSACTION_REQUEST_START'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START'
export const FETCH_GET_TRANSFER_PENDING_SUCCESS = 'FETCH_GET_TRANSFER_PENDING_SUCCESS'
export const FETCH_GET_TRANSFER_PENDING_FAIL = 'FETCH_GET_TRANSFER_PENDING_FAIL'
export const FETCH_GET_TRANSFER_PENDING_START = 'FETCH_GET_TRANSFER_PENDING_START'
export const FETCH_APPROVE_TRANSACTION_SUCCESS = 'FETCH_APPROVE_TRANSACTION_SUCCESS'
export const FETCH_APPROVE_TRANSACTION_FAIL = 'FETCH_APPROVE_TRANSACTION_FAIL'
export const FETCH_APPROVE_TRANSACTION_START = 'FETCH_APPROVE_TRANSACTION_START'
export const FETCH_DENIED_TRANSACTION_SUCCESS = 'FETCH_DENIED_TRANSACTION_SUCCESS'
export const FETCH_DENIED_TRANSACTION_FAIL = 'FETCH_DENIED_TRANSACTION_FAIL'
export const FETCH_DENIED_TRANSACTION_START = 'FETCH_DENIED_TRANSACTION_START'
export const FETCH_RESEND_TOKEN_SUCCESS = 'FETCH_RESEND_TOKEN_SUCCESS'
export const FETCH_RESEND_TOKEN_FAIL = 'FETCH_RESEND_TOKEN_FAIL'
export const FETCH_RESEND_TOKEN_START = 'FETCH_RESEND_TOKEN_START'
export const FETCH_GET_ACCOUNT_QR_SUCCESS = 'FETCH_GET_ACCOUNT_QR_SUCCESS'
export const FETCH_GET_ACCOUNT_QR_FAIL = 'FETCH_GET_ACCOUNT_QR_FAIL'
export const FETCH_GET_ACCOUNT_QR_START = 'FETCH_GET_ACCOUNT_QR_START'
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS'
export const FETCH_BALANCE_FAIL = 'FETCH_BALANCE_FAIL'
export const FETCH_BALANCE_START = 'FETCH_BALANCE_START'
export const FETCH_TRANSACTION_CHARGE_CARD_SUCCESS = 'FETCH_TRANSACTION_CHARGE_CARD_SUCCESS'
export const FETCH_TRANSACTION_CHARGE_CARD_FAIL = 'FETCH_TRANSACTION_CHARGE_CARD_FAIL'
export const FETCH_TRANSACTION_CHARGE_CARD_START = 'FETCH_TRANSACTION_CHARGE_CARD_START'


export const FETCH_TRANSACTION_DATA_SUCCESS = 'FETCH_TRANSACTION_DATA_SUCCESS'
export const FETCH_TRANSACTION_DATA_FAIL = 'FETCH_TRANSACTION_DATA_FAIL'
export const FETCH_TRANSACTION_DATA_START = 'FETCH_TRANSACTION_DATA_START'

//EXPENSAS
export const FETCH_GENERATE_EXPENSAS_SUCCESS = 'FETCH_GENERATE_EXPENSAS_SUCCESS'
export const FETCH_GENERATE_EXPENSAS_START = 'FETCH_GENERATE_EXPENSAS_START'
export const FETCH_GENERATE_EXPENSAS_FAIL = 'FETCH_GENERATE_EXPENSAS_FAIL'
export const FETCH_GET_HOME_EXPENSAS_SUCCESS = 'FETCH_GET_HOME_EXPENSAS_SUCCESS'
export const FETCH_GET_HOME_EXPENSAS_START = 'FETCH_GET_HOME_EXPENSAS_START'
export const FETCH_GET_HOME_EXPENSAS_FAIL = 'FETCH_GET_HOME_EXPENSAS_FAIL'
export const FETCH_GET_EXPENSAS_CLIENTE_SUCCESS = 'FETCH_GET_EXPENSAS_CLIENTE_SUCCESS'
export const FETCH_GET_EXPENSAS_CLIENTE_START = 'FETCH_GET_EXPENSAS_CLIENTE_START'
export const FETCH_GET_EXPENSAS_CLIENTE_FAIL = 'FETCH_GET_EXPENSAS_CLIENTE_FAIL'
export const FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS = 'FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS'
export const FETCH_GET_FILE_DETAIL_CLIENT_START = 'FETCH_GET_FILE_DETAIL_CLIENT_START'
export const FETCH_GET_FILE_DETAIL_CLIENT_FAIL = 'FETCH_GET_FILE_DETAIL_CLIENT_FAIL'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL'

export const FETCH_POST_UPLOAD_FILE_SUCCESS = 'FETCH_POST_UPLOAD_FILE_SUCCESS'
export const FETCH_POST_UPLOAD_FILE_START = 'FETCH_POST_UPLOAD_FILE_START'
export const FETCH_POST_UPLOAD_FILE_FAIL = 'FETCH_POST_UPLOAD_FILE_FAIL'

//PROFILE
export const FETCH_GET_ROLES_SUCCESS = 'FETCH_GET_ROLES_SUCCESS'
export const FETCH_GET_ROLES_FAIL = 'FETCH_GET_ROLES_FAIL'
export const FETCH_GET_ROLES_START = 'FETCH_GET_ROLES_START'

export const FETCH_GET_MENUS_SUCCESS = 'FETCH_GET_MENUS_SUCCESS'
export const FETCH_GET_MENUS_FAIL = 'FETCH_GET_MENUS_FAIL'
export const FETCH_GET_MENUS_START = 'FETCH_GET_MENUS_START'

export const FETCH_GET_PERSON_SUCCESS = 'FETCH_GET_PERSON_SUCCESS'
export const FETCH_GET_PERSON_FAIL = 'FETCH_GET_PERSON_FAIL'
export const FETCH_GET_PERSON_START = 'FETCH_GET_PERSON_START'

export const FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS = 'FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS'
export const FETCH_REFRESH_MENUS_FOR_ROLE_FAIL = 'FETCH_REFRESH_MENUS_FOR_ROLE_FAIL'
export const FETCH_REFRESH_MENUS_FOR_ROLE_START = 'FETCH_REFRESH_MENUS_FOR_ROLE_START'


//ACCESOS

export const FETCH_GET_GUEST_SUCCESS = 'FETCH_GET_GUEST_SUCCESS'
export const FETCH_GET_GUEST_FAIL = 'FETCH_GET_GUEST_FAIL'
export const FETCH_GET_GUEST_START = 'FETCH_GET_GUEST_START'
export const CLEAR_ACCESS_STORE = 'CLEAR_ACCESS_STORE'
export const FETCH_CHECK_IN_SUCCESS = 'FETCH_CHECK_IN_SUCCESS'
export const FETCH_CHECK_IN_FAIL = 'FETCH_CHECK_IN_FAIL'
export const FETCH_CHECK_IN_START = 'FETCH_CHECK_IN_START'
export const FETCH_VEHICLES_BRAND_START = 'FETCH_VEHICLES_BRAND_START'
export const FETCH_VEHICLES_BRAND_SUCCESS = 'FETCH_VEHICLES_BRAND_SUCCESS'
export const FETCH_VEHICLES_BRAND_FAIL = 'FETCH_VEHICLES_BRAND_FAIL'
export const FETCH_VEHICLES_MODEL_START = 'FETCH_VEHICLES_MODEL_START'
export const FETCH_VEHICLES_MODEL_SUCCESS = 'FETCH_VEHICLES_MODEL_SUCCESS'
export const FETCH_VEHICLES_MODEL_FAIL = 'FETCH_VEHICLES_MODEL_FAIL'


//TICKETS

export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS'
export const FETCH_TICKETS_FAIL = 'FETCH_TICKETS_FAIL'
export const FETCH_TICKETS_START = 'FETCH_TICKETS_START'

export const FETCH_CATEGORIES_BY_ROLE_SUCCESS = 'FETCH_CATEGORIES_BY_ROLE_SUCCESS'
export const FETCH_CATEGORIES_BY_ROLE_FAIL = 'FETCH_CATEGORIES_BY_ROLE_FAIL'
export const FETCH_CATEGORIES_BY_ROLE_START = 'FETCH_CATEGORIES_BY_ROLE_START'

export const FETCH_TICKET_MESSAGES_SUCCESS = 'FETCH_TICKET_MESSAGES_SUCCESS'
export const FETCH_TICKET_MESSAGES_FAIL = 'FETCH_TICKET_MESSAGES_FAIL'
export const FETCH_TICKET_MESSAGES_START = 'FETCH_TICKET_MESSAGES_START'

export const SEND_TICKET_MESSAGE_SUCCESS = 'SEND_TICKET_MESSAGE_SUCCESS'
export const SEND_TICKET_MESSAGE_FAIL = 'SEND_TICKET_MESSAGE_FAIL'
export const SEND_TICKET_MESSAGE_START = 'SEND_TICKET_MESSAGE_START'

export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS'
export const CLOSE_TICKET_FAIL = 'CLOSE_TICKET_FAIL'
export const CLOSE_TICKET_START = 'CLOSE_TICKET_START'

export const PENDING_TICKET_SUCCESS = 'PENDING_TICKET_SUCCESS'
export const PENDING_TICKET_FAIL = 'PENDING_TICKET_FAIL'
export const PENDING_TICKET_START = 'PENDING_TICKET_START'

export const CHANGE_CATEGORY_TICKET_SUCCESS = 'CHANGE_CATEGORY_TICKET_SUCCESS'
export const CHANGE_CATEGORY_TICKET_FAIL = 'CHANGE_CATEGORY_TICKET_FAIL'
export const CHANGE_CATEGORY_TICKET_START = 'CHANGE_CATEGORY_TICKET_START'

export const SEARCH_TICKETS_SUCCESS = 'SEARCH_TICKETS_SUCCESS'
export const SEARCH_TICKETS_FAIL = 'SEARCH_TICKETS_FAIL'
export const SEARCH_TICKETS_START = 'SEARCH_TICKETS_START'

export const FETCH_PUT_INVITE_SUCCESS = "FETCH_PUT_INVITE_SUCCESS"
export const FETCH_PUT_INVITE_FAIL = "FETCH_PUT_INVITE_FAIL"
export const FETCH_PUT_INVITE_START = "FETCH_PUT_INVITE_START"

export const FETCH_POST_INVITE_SUCCESS = "FETCH_POST_INVITE_SUCCESS"
export const FETCH_POST_INVITE_FAIL = "FETCH_POST_INVITE_FAIL"
export const FETCH_POST_INVITE_START = "FETCH_POST_INVITE_START"

export const FETCH_CHECK_OUT_SUCCESS = "FETCH_CHECK_OUT_SUCCESS"
export const FETCH_CHECK_OUT_FAIL = "FETCH_CHECK_OUT_FAIL"
export const FETCH_CHECK_OUT_START = "FETCH_CHECK_OUT_START"

export const FETCH_SEARCH_INVITES_SUCCESS = "FETCH_SEARCH_INVITES_SUCCESS"
export const FETCH_SEARCH_INVITES_FAIL = "FETCH_SEARCH_INVITES_FAIL"
export const FETCH_SEARCH_INVITES_START = "FETCH_SEARCH_INVITES_START"
export const CLEAR_SEARCHED_INVITES = "CLEAR_SEARCHED_INVITES"

export const FETCH_NEW_USER_SUCCESS = "FETCH_NEW_USER_SUCCESS"
export const FETCH_NEW_USER_FAIL = " FETCH_NEW_USER_FAIL"
export const FETCH_NEW_USER_START = " FETCH_NEW_USER_START"


//accesos
export const FETCH_LOTS_SUCCESS = "FETCH_LOTS_SUCCESS"
export const FETCH_LOTS_FAIL = " FETCH_LOTS_FAIL"
export const FETCH_LOTS_START = "FETCH_LOTS_START"
