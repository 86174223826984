import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Page from 'components/Page'
import Section from 'components/Section'
import themeStyles from './personas.theme.style'
import compose from 'recompose/compose'
import withWidth from '@material-ui/core/withWidth'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CreateUser from './components/CreateUser'
import ProprietaryDetail from './components/ProprietaryDetail/index'
import { bindActionCreators } from 'redux'
import { fetchPadron } from 'store/actions/padron.actions'
import PageLoader from 'components/PageLoader'
import mapProprietary from 'selectors/mapProprietary'
import ResourceList from 'components/ResourceList'
import { setSelectedResource, resetSelectedResource } from 'store/actions/selectedResource'
import EmptyWrapper from 'components/PageEmpty'
import EditUser from "./components/EditUser"
import SearchBar from 'components/SearchBar'
import mapRoles from "selectors/mapRoles"
import {SearchWrapper, ListWrapper, PageWrapper, Wrapper, ContentWrapper } from "./styled.js"

var timer;
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewUserOpen: false,
      isNewUserEditOpen: false,
      isUserDetailOpen: false,
      itemInDetail: '',
      uploadFile: false,
      filter: "",
    }
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.fetchPadron()
    this.props.resetSelectedResource()
  }

  openDeleteConfirmation = item => {
    this.setState({ openDeleteConfirmation: true, propietarioToDelete: item })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  toggleUserDetail = () => {
    this.setState({ isUserDetailOpen: !this.state.isUserDetailOpen })
  }
  
  toggleNewUser = (reload) => {
    if (reload) {
      this.props.fetchPadron(true)
      this.props.resetSelectedResource()
    }
    this.setState({ isNewUserOpen: !this.state.isNewUserOpen })
  }

  onOpenNewUserEdit = (reload) => {
    if (reload) {
      this.props.fetchPadron(true)
    }
    this.setState({ isNewUserEditOpen: !this.state.isNewUserEditOpen })
  }

  isLoadingContent = () => this.props.loading && this.props.users.length === 0

  createMainView = () => (
    <Section
      title={'Usuarios y roles'}
      btnContent={'Añadir Usuario'}
      onBtnClick={this.toggleNewUser}
    >
      <Wrapper>
        {this.isLoadingContent() ? <PageLoader /> : this.renderPageContent()}  
      </Wrapper>
    </Section>
  )

  isContentAvaiable = () => !this.props.loading 

  renderPageContent = () =>
    this.isContentAvaiable() ? (
    <>
      <SearchWrapper>
        <SearchBar
          placeholder={'Buscá usuario'}
          value={this.state.filter}
          onChange={this.handleInputOnChange}
          onClear={this.handleInputClear}
          onKeyUp={(e) => this.handleKeyPress(e)}
          borderBottom
        />
      </SearchWrapper>
        <ContentWrapper>
          <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
            <ResourceList
              elements={this.props.mappedPropietaries }
              onClick={this.handleResourceClick}
              selectedPos={this.props.selectedResource ? this.props.selectedResource.pos : null}
              noMoreContentMsg={'No hay usuarios'}
              hasMore={this.props.hasMore}
              resourcePeople={true}
            />
          </ListWrapper>
          <ProprietaryDetail
            onHandleClose={this.onCloseNewUserEdit}
            item={this.props.selectedResource}
            onEdit={this.onOpenNewUserEdit}
          />
        </ContentWrapper>
      </>
    ) : (
      <EmptyWrapper message={'No hay usuarios disponibles'} />
    )

  handleInputOnChange = value => this.setState({ filter: value })

  handleInputClear = value => {
    this.setState({ filter: value, filterEnabled: false },() => this.props.fetchPadron())
  }  
  
  handleKeyPress(e){
    if(e.keyCode === 13){
      this.props.fetchPadron(e.target.value)
    }
  }  

  handleResourceClick = resource => this.props.setSelectedResource(resource)

  isLoadingContent = () => this.props.loading

  render() {
    return (
      <Page className={classNames('portal-hide-scrollbars')}>
        <PageWrapper>
          {this.createMainView()}
          { this.state.isNewUserOpen == true &&
           ( <CreateUser onClose={this.toggleNewUser} /> )
           || this.state.isNewUserEditOpen == true &&
           ( <EditUser onClose={this.onOpenNewUserEdit} roles={this.props.roles} userToEdit={this.props.selectedResource} />)
          }
        </PageWrapper>
      </Page>
    )
  }
}

const mapStateToProps = ({ padron,profile, selectedResource }) => {
  return {
    mappedPropietaries: mapProprietary(padron.proprietaryList),
    itemSearched: padron.proprietaryList,
    loading: padron.loading,
    roles: mapRoles(profile.roles),
    hasMore: padron.hasMore,
    selectedResource
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPadron,
      setSelectedResource,
      resetSelectedResource
    },
    dispatch
  )


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth(),
    withStyles(themeStyles, { withTheme: true })
  )(Users)
)
