import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { auth, setAuthRedirectPath } from 'store/actions/auth.actions'
// New implementation
import { Formik } from 'formik'
import * as Yup from 'yup'
import theme from 'config/theme'
import TextFieldMaterial from 'components/TextFieldMaterial'
import ButtonMaterial from 'components/ButtonMaterial'
import loginImage from 'assets/images/IlustracionLogin.png'
import logo from 'assets/images/Miiii.png'
import {
  Container,
  ContentLabel,
  FormContainer,
  FormWrapper,
  ImageWrapper,
  StyledImg,
  StyledLabel,
  SubTitle,
  LogoImg,
  ButtonWrapper,
  ErrorLabel
} from './styled'
import ForgotPass from '../forgot-password/forgot-password.component'
import NewTokenRequest from 'components/AuthPopUp'

class Login extends Component {
  state={
    isRecoveryPass: false
  }
  renderForm = () => {
    const SignUpSchema = Yup.object().shape({
      user: Yup.string().required('Usuario requerido'),
      password: Yup.string().required('Contraseña requerida')
    })

    return (
      <Formik
        initialValues={{
          user: '',
          password: ''
        }}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }

  handleSubmit = async formData => {
    this.setState({formData: formData})
    await this.props.auth(formData.user, formData.password)
    if(await this.props.error === false){
      this.toggleAuth(formData.user, formData.password,this.props.session) 
    } 
  }

  toggleRecovery = () => this.setState({isRecoveryPass: !this.state.isRecovery})
  
  toggleAuth = (user,pass) => this.setState({ isAuthOpen: !this.state.isAuthOpen,user: user,pass: pass })

  getRecoveryPassContent = () => ( 
    <ForgotPass/>
  )

  confirmLogin(token){
    this.props.auth(this.state.formData.user, this.state.formData.password,token,this.props.session) 
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <FormWrapper>
          <LogoImg src={logo} />
        <SubTitle>{'Bienvenido'}</SubTitle>
        <ContentLabel>{'Ingresá a tu cuenta'}</ContentLabel>
          <TextFieldMaterial
            name={'user'}
            label={'Nombre de usuario'}
            helperText={errors.user}
            value={values.user}
            error={errors.user}
            onChange={({ target: value }) => setFieldValue('user', value.value.trim().replace(/\s+/g, ''))}
            margin="normal"
            variant="outlined"
          />
          <TextFieldMaterial
            name={'password'}
            label={'Contraseña'}
            type="password"
            helperText={errors.password}
            value={values.password}
            onChange={({ target: value }) => setFieldValue('password', value.value.trim().replace(/\s+/g, ''))}
            error={errors.password}
            margin="normal"
            variant="outlined"
            autocomplete="off"
          />
      <StyledLabel onClick={this.toggleRecovery} OnClickBack={this.toggleRecovery}>Recuperar contraseña</StyledLabel>
        <ButtonWrapper>
          <ButtonMaterial
            type={'submit'}
            background={theme.colors.blueRibbon}
            color={theme.colors.white}
            fontSize={'20'}
            loading={this.props.loading}
          >
            {'Ingresar'}
          </ButtonMaterial>
        </ButtonWrapper>
         {this.props.errorMessage.length > 0 ? <ErrorLabel>{this.props.errorMessage}</ErrorLabel> : <></>}
        {this.props.error && this.props.errorMessage === false ? <ErrorLabel>Error al comunicarse al servidor</ErrorLabel> : <></>}
      </FormWrapper>
    </FormContainer>
  )

  render() {
    return(
      <div>
        {this.state.isAuthOpen ? ( <NewTokenRequest onClose={this.toggleAuth} pass={this.state.pass} user={this.state.user} 
          chargeToken={(t)=> this.confirmLogin(t)} fetchReSendToken={false} />) :(<></>)}
        {this.state.isRecoveryPass ? ( 
          <ForgotPass OnClickBack={() => this.toggleRecovery}/>     
        ) : (
          <Container>
            <ImageWrapper>
              <StyledImg src={loginImage} />
            </ImageWrapper>
            { this.renderForm()}
          </Container>
        )  
      }
    </div>
    )
    
  }
}

const mapStateToProps = state => {
  return {
    authReducer: state.auth,
    loading: state.auth.loading,
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    session: state.auth.session
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      auth,
      setAuthRedirectPath
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
