import React, { Component } from 'react';
import Modal from 'components/Modal'
import {
  Container,
  Header,
  RowItem,
  Body,
  Title,
  StyledButton,
  LabelStyled,
  UserImg,
  CloseIcon,
  ErrorLabel,
  ButtonWrapper
} from './styled'
import check from "assets/icons/authorizedCheck.png"
import cross from "assets/icons/cross.png"

class ModalInfo extends Component {
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.props.onClose()
        }
    }
  
  render() {
    const invitation = this.props.accesos.getGuestInvitation;
    return (
        <Modal> 
            <Container>
                <Header>
                <CloseIcon src={cross} onClick={this.props.onClose}/>
                 <img src={check} />
                 Acceso autorizado
                </Header>
                <Body>
                    <Title>Datos del Invitado</Title>
                    <RowItem>
                        <label style={{flexGrow: 2}}>Invitado</label> 
                        <LabelStyled>{invitation.name}</LabelStyled> 
                        <UserImg src={invitation.avatar} />
                    </RowItem>
                    {invitation.car ? (
                        <>
                        <RowItem>
                            <label>Patente</label> 
                            <LabelStyled>{invitation.car.vehicle_license_plate}</LabelStyled> 
                        </RowItem>
                        <RowItem>
                            <label>Marca / Modelo </label> 
                            <LabelStyled>{invitation.car.vehicle_brand_name + " " + invitation.car.vehicle_model_name}</LabelStyled> 
                        </RowItem>
                        <RowItem>
                            <label>Seguro</label> 
                            <LabelStyled>
                                {invitation.insurance.insurance_expire_date}
                            </LabelStyled> 
                        </RowItem>
                        </>
                    ) : (
                        <></>
                    ) }                    
                    <RowItem>
                        <label>Lote</label> 
                        <LabelStyled>
                            {invitation.funcional_unit.property_code}
                        </LabelStyled> 
                    </RowItem>
                    {/* <RowItem>
                        <label>Turno de entrada</label> 
                        <LabelStyled>Mediodia</LabelStyled> 
                    </RowItem> */}
                    <ButtonWrapper>
                        <StyledButton red={true} onClick={()=> this.props.editMode()}>
                            Actualizar datos
                        </StyledButton>
                        <StyledButton  onClick={()=> this.props.checkin(true)}>
                            Permitir ingreso
                        </StyledButton>
                    </ButtonWrapper>
                    {this.props.error ? (<ErrorLabel>{this.props.error}</ErrorLabel>) : (<></>)}
                </Body>
            </Container>
        </Modal> 
    )
  }
}

export default ModalInfo