import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Accordion from 'components/Accordion';
import DatePickerStyled from 'components/DatePickerStyled';
import Input from 'components/Input';
import dni from 'assets/images/dni.png';
import ImageUploadPlaceholder from 'components/ImageUploadPlaceholder';
import WebcamModal from 'components/WebcamModal';
import {
  FormSection,
  ErrorLabel,
  Text,
  InputWrapper, 
  Row,
  ButtonWrapper,
  StyledButton
} from '../styled'

class NewGuestSecondStep extends Component {
  handleSubmit = (values) => {
    const { index, getFormData} = this.props;
    getFormData(values, index);
  }

  getInitialValues = () => ({
    invitado_nombre: this.props.editMode && this.props.editInitialValues.name ? (this.props.editInitialValues.name) : (''),
    invitado_apellido: this.props.editMode && this.props.editInitialValues.name ? (this.props.editInitialValues.name) : (''),
    invitado_dni_escaneado: this.props.editMode && this.props.editInitialValues['dni-picture'].front_url ? this.props.editInitialValues['dni-picture'].front_url : (''),
    invitado_dni_vencimiento: this.props.editMode && this.props.editInitialValues['dni-picture'].expire_date ? (new Date(this.props.editInitialValues['dni-picture'].expire_date)) : (''),
    invitado_dni_numero: this.props.editMode && (this.props.editInitialValues.dni) ? (this.props.editInitialValues.dni) : (''),
  })

  getSignUpSchema = () =>
  Yup.object().shape({
    invitado_nombre: Yup.string().required('El campo Nombre es requerido'),
    invitado_apellido: Yup.string().required('El campo Apellido es requerido'),
    invitado_dni_escaneado: Yup.string().nullable().required('Suba una foto del DNI'),
    invitado_dni_vencimiento: Yup.string().required('El campo Vencimiento del DNI es requerido.'),
    invitado_dni_numero: Yup.string().required('El campo DNI es requerido.'),
  })

  render() {
    const args = {...this.props};
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.getSignUpSchema()}
        isInitialValid={this.props.editMode ? true : false}
        onSubmit={this.handleSubmit}
        render={props => <FormikForm {...props} {...args} />}
      />
    )
  }
}

class FormikForm extends Component {
  state = { cameraModalOpen: false };

  componentDidMount() {
    if(this.props.editMode) {
      this.setState({dniImage: this.props.editInitialValues[`dni-picture`].front_url});
    }
  }
  
  openWebcamModal = () => {
    this.setState({
      cameraModalOpen: !this.state.cameraModalOpen,
    });
  }

  retrieveImage = (imagen) => {
    this.setState({ dniImage: imagen, cameraModalOpen: false })
  }

  clearImage = () => {
    this.setState({ dniImage: null, cameraModalOpen: true })
  }

  render() {
    const { handleSubmit, values, setFieldValue, errors, isValid } = this.props;
    return (
      <Accordion 
        title={this.props.title} 
        active={this.props.active} 
        index={this.props.index}
        changeIndex={this.props.changeIndex}
        formDataValidation={this.props.formDataValidation}
      >
        <form onSubmit={(values) => handleSubmit(values)}>
          <Row>
            <FormSection flex={1}>
              <InputWrapper>
                <ImageUploadPlaceholder 
                  onChange={file => setFieldValue('invitado_dni_escaneado', file)}
                  icon={dni}
                  label={'Foto del DNI'} 
                  dashed={true} 
                  value={this.state.dniImage ? this.state.dniImage : null}
                  openWebcamModal={this.openWebcamModal}
                  clearImage={this.clearImage}
                />
                { this.state.cameraModalOpen ? <WebcamModal onClose={this.openWebcamModal} retrieveImage={this.retrieveImage}></WebcamModal> : null }
                {errors.invitado_dni_escaneado && <ErrorLabel>{errors.invitado_dni_escaneado}</ErrorLabel>}
              </InputWrapper>
            </FormSection>
            <FormSection flex={2}>
              <Row>
                <InputWrapper style={{'marginRight':'3%'}}>
                  <Text>{'Nombre'}</Text>
                  <Input
                    name={'invitado_nombre'}
                    label={'Nombre'}
                    placeholder={'Nombre'}
                    value={values.invitado_nombre}
                    error={errors.invitado_nombre}
                    onChange={({target: value}) => setFieldValue('invitado_nombre', value.value)}
                    />
                  {errors.invitado_nombre && <ErrorLabel>{errors.invitado_nombre}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper>
                  <Text>{'Apellido'}</Text>
                  <Input
                    name={'invitado_apellido'}
                    label={'Apellido'}
                    placeholder={'Apellido'}
                    value={values.invitado_apellido}
                    error={errors.invitado_apellido}
                    onChange={({target: value}) => setFieldValue('invitado_apellido', value.value)}
                    />
                  {errors.invitado_apellido && <ErrorLabel>{errors.invitado_apellido}</ErrorLabel>}
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{'marginRight':'3%'}}>
                  <Text>{'N de DNI'}</Text>
                  <Input
                    name={'invitado_dni_numero'}
                    label={'DNI'}
                    placeholder={'N de DNI'}
                    value={values.invitado_dni_numero}
                    error={errors.invitado_dni_numero}
                    onChange={({target: value}) => setFieldValue('invitado_dni_numero', value.value)}
                    />
                  {errors.invitado_dni_numero && <ErrorLabel>{errors.invitado_dni_numero}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper display='flex' flexdirection='column'>
                  <Text margin='0px'>{'Fecha vencimiento DNI'}</Text>
                  <DatePickerStyled
                    onChange={values => setFieldValue('invitado_dni_vencimiento', values)}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    selected={values.invitado_dni_vencimiento}
                    error={errors.invitado_dni_vencimiento}
                    placeholderText="dd/MM/yyyy 📅"
                    bold={true}
                    padding={'8px 10px'}
                  />
                  {errors.invitado_dni_vencimiento && <ErrorLabel>{errors.invitado_dni_vencimiento}</ErrorLabel>}
                </InputWrapper>
              </Row>
            </FormSection>
          </Row>
          <ButtonWrapper>
            {isValid ? <StyledButton type={'submit'}>{'Aceptar'}</StyledButton> : null}
          </ButtonWrapper>
        </form>
      </Accordion>
    );
  }
}

export default NewGuestSecondStep;