import * as actionTypes from './actionTypes'
import {
    getTransactionUserAPI,
    postRequestedTransactionAPI,
    getCodeQRImageAPI,
    postCreateTransactionRequestAPI,
    getAccountWithUniqueKeyAPI,
    getAccountWithAliasAPI,
    getAccountWithLoteAPI,
    getDebitAccountInfo,
    getTransferPendingAPI,
    getNumberOfTransferPendingAPI,
    getApproveTransferAPI,
    getDeniedTransferAPI,
    getRegenerateOpenQR,
    getApproveTransferWithTokenAPI,
    postReSendToken,
    getAccountQR,
    getBalanceUserAPI,
    TransactionChargeWithCardAPI,
    getTransactionDataAPI
} from 'apiConstants'
import getApi from 'shared/api'
import fetchData from 'shared/fetchData'


export const fetchTransactionListSuccess = transactions => {
    return {
      type: actionTypes.FETCH_TRANSACTION_LIST_SUCCESS,
      transactions: transactions
    }
  }
  
  export const fetchTransactionListFail = error => {
    return {
      type: actionTypes.FETCH_TRANSACTION_LIST_FAIL,
      error: error
    }
  }
  
  export const fetchTransactionListStart = () => {
    return {
      type: actionTypes.FETCH_TRANSACTION_LIST_START
    }
  }
  
  export const fetchTransactionList = reset => {
    return async (dispatch, getState) => {
      dispatch(fetchTransactionListStart())
      const {
        feed,
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      let eventTake = reset ? 10 : feed.eventTake
      const page = feed.page
  
      try {
        const response = await api.get(`${getTransactionUserAPI}${page}/100`)

        let hasMoreUpdated = response.data.length === eventTake
        let takeUpdated = hasMoreUpdated ? eventTake + 10 : eventTake
        dispatch(
            fetchTransactionListSuccess({
            values: response.data,
            eventTake: takeUpdated,
            hasMoreTransactions: hasMoreUpdated
          })
        )
      } catch (error) {
        dispatch(fetchTransactionListFail(error))
      }
    }
  }

  //------------------Request Transaction -----------------------------------
  export const fetchTransactionQRSuccess = transactions => {
    let transaction;
    if(transactions === '') {
      transaction = 'RegenerateFail';
    } else {
      transaction = transactions;
    }
    return {
      type: actionTypes.FETCH_TRANSACTION_QR_SUCCESS,
      transactions: transaction
    }
  }
  
  export const fetchTransactionQRFail = error => {
    return {
      type: actionTypes.FETCH_TRANSACTION_QR_FAIL,
      error: error
    }
  }
  
  export const fetchTransactionQRStart = () => {
    return {
      type: actionTypes.FETCH_TRANSACTION_QR_START
    }
  }
  
  export const fetchTransactionQR = transaction => {
    return async (dispatch, getState) => {
      dispatch(fetchTransactionQRStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .post(postRequestedTransactionAPI,transaction)
      .then(res => {
        dispatch(fetchTransactionQRSuccess(res.data))
        dispatch(fetchGetCodeQRImage(res.data.id))
      })
      .catch(err => {
        dispatch(fetchTransactionQRFail(err))
      })
    }
  }

  export const fetchOpenQR = () => {
    return async (dispatch, getState) => {
      dispatch(fetchTransactionQRStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .get(getRegenerateOpenQR)
      .then(res => {
        dispatch(fetchTransactionQRSuccess(res.data))
        dispatch(fetchGetCodeQRImageSuccess(res.data.qr_base64))
      })
      .catch(err => {
        dispatch(fetchTransactionQRFail(err))
      })
    }
  }

  //------------------QR IMAGE-----------------------------------
  export const fetchGetCodeQRImageSuccess = codeQR => {
    return {
      type: actionTypes.FETCH_GET_CODE_QR_IMAGE_SUCCESS,
      GetCodeQRImage: codeQR
    }
  }
  
  export const fetchGetCodeQRImageFail = error => {
    return {
      type: actionTypes.FETCH_GET_CODE_QR_IMAGE_FAIL,
      error: error
    }
  }
  
  export const fetchGetCodeQRImageStart = () => {
    return {
      type: actionTypes.FETCH_GET_CODE_QR_IMAGE_START
    }
  }
  
  export const fetchGetCodeQRImage = id => {
    return async (dispatch, getState) => {
      dispatch(fetchGetCodeQRImageStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .get(`${getCodeQRImageAPI}${id}`)
      .then(res => {
        dispatch(fetchGetCodeQRImageSuccess(res.data.qr_base64))
      })
      .catch(err => {
        dispatch(fetchGetCodeQRImageFail(err))
      })
    }
  }



  
  //------------------Crear Transferencia-----------------------------------

  export const fetchCreateTransactionRequestSuccess = requestTransaction => {
    return {
      type: actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS,
      requestTransaction : requestTransaction
    }
  }
  
  export const fetchCreateTransactionRequestFail = error => {
    return {
      type: actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_FAIL,
      errorMessageCreateTransactionRequest: error
    }
  }
  
  export const fetchCreateTransactionRequestStart = () => {
    return {
      type: actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_START
    }
  }
  
  export const fetchCreateTransactionRequest = body => {
    return async (dispatch, getState) => {
      dispatch(fetchCreateTransactionRequestStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)
      
      return api
      .post(postCreateTransactionRequestAPI,body)
      .then(res => {
        dispatch(fetchCreateTransactionRequestSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchCreateTransactionRequestFail(err.response.data.message))
      })
    }
  }

  //------------------Get Account Info-----------------------------------

  export const fetchGetAccountSuccess = AccountInfo => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_SUCCESS,
      AccountInfo: AccountInfo
    }
  }
  
  export const fetchGetAccountFail = error => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_FAIL,
      errorAccountInfo: error
    }
  }
  
  export const fetchGetAccountStart = () => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_START,
    }
  }
  
  export const fetchGetAccount = uniqueKey => {
    return async (dispatch, getState) => {
      dispatch(fetchGetAccountStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)
      var apiUrl;
      
      uniqueKey.length > 21 ? apiUrl = getAccountWithUniqueKeyAPI : apiUrl= getAccountWithAliasAPI

      return api
      .get(`${apiUrl}${uniqueKey}`)
      .then(res => {
        dispatch(fetchGetAccountSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountFail(err.response.data.message))
      })
    }
  }

  //------------------Get Account Info by LOTE-----------------------------------

  export const fetchGetAccountByLoteSuccess = AccountInfoByLote => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS,
      AccountInfoByLote: AccountInfoByLote
    }
  }
  
  export const fetchGetAccountByLoteFail = error => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_FAIL,
      errorAccountInfoByLote: error
    }
  }
  
  export const fetchGetAccountByLoteStart = () => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_START,
    }
  }
  
  export const fetchGetAccountByLote = lote => {
    return async (dispatch, getState) => {
      dispatch(fetchGetAccountByLoteStart())     
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)
      let url= getAccountWithLoteAPI.concat(lote)
      return api
      .get(url)
      .then(res => {
        dispatch(fetchGetAccountByLoteSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountByLoteFail(err.response.data.message))
      })
    }
  }

  //------------------Get Account DEBIT info-----------------------------------
  
  export const fetchGetAccountDebitSuccess = AccountDebitInfo => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_DEBIT_SUCCESS,
      loadingAccountDebitInfo: false, 
      AccountDebitInfo: AccountDebitInfo
    }
  }
  
  export const fetchGetAccountDebitFail = error => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_DEBIT_FAIL,
      loadingAccountDebitInfo: false,      
      errorAccountDebitInfo: error
    }
  }
  
  export const fetchGetAccountDebitStart = () => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_DEBIT_START,
      loadingAccountDebitInfo: true,
    }
  }
  
  export const fetchGetAccountDebit = uniqueKey => {
    return async (dispatch, getState) => {
      dispatch(fetchGetAccountDebitStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)
      
      return api
      .get(`${getDebitAccountInfo}`)
      .then(res => {
        dispatch(fetchGetAccountDebitSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountDebitFail(err))
      })
    }
  }

  
  //------------------Get Account For QR-----------------------------------
  
  export const fetchGetAccountQRSuccess = AccountQR => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_QR_SUCCESS,
      loadingAccountQR: false, 
      AccountQR: AccountQR
    }
  }
  
  export const fetchGetAccountQRFail = error => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_QR_FAIL,
      loadingAccountQR: false,      
      errorAccountQR: error
    }
  }
  
  export const fetchGetAccountQRStart = () => {
    return {
      type: actionTypes.FETCH_GET_ACCOUNT_QR_START,
      loadingAccountQR: true,
    }
  }
  
  export const fetchGetAccountQR = body => {
    return async (dispatch, getState) => {
      dispatch(fetchGetAccountQRStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .post(getAccountQR,body)
      .then(res => {
        dispatch(fetchGetAccountQRSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountQRFail(err))
      })
    }
  }

  
  //------------------Get Transfer to aprove-----------------------------------
  
  export const fetchGetTransferPendingSuccess = TransferPending => {
    return {
      type: actionTypes.FETCH_GET_TRANSFER_PENDING_SUCCESS,
      loadingTransferRequest: false, 
      TransferPending: TransferPending
    }
  }
  
  export const fetchGetTransferPendingFail = error => {
    return {
      type: actionTypes.FETCH_GET_TRANSFER_PENDING_FAIL,
      loadingTransferRequest: false,      
      errorTransferRequest: error
    }
  }
  
  export const fetchGetTransferPendingStart = () => {
    return {
      type: actionTypes.FETCH_GET_TRANSFER_PENDING_START,
      loadingTransferRequest: true,
    }
  }
  
  export const fetchGetTransferPending = body => {
    return async (dispatch, getState) => {
      dispatch(fetchGetTransferPendingStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      try {
        const response = await api.get(getTransferPendingAPI,body)
          dispatch(fetchGetTransferPendingSuccess(response.data))
        } catch (error) {
          dispatch(fetchGetTransferPendingFail(error))
        }
        
    }
  }

  
  //-------------Get Total number of Transfer to aprove-----------------------------------
  
  export const fetchGetNumberOfTransferPendingSuccess = NumberOfTransferRequest => {
    return {
      type: actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS,
      loadingNumberOfTransferRequest: false, 
      NumberOfTransferRequest: NumberOfTransferRequest
    }
  }
  
  export const fetchGetNumberOfTransferPendingFail = error => {
    return {
      type: actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL,
      loadingNumberOfTransferRequest: false,      
      errorNumberOfTransferRequest: error
    }
  }
  
  export const fetchGetNumberOfTransferPendingStart = () => {
    return {
      type: actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START,
      loadingNumberOfTransferRequest: true,
    }
  }
  
  export const fetchGetNumberOfTransferPending = body => {
    return async (dispatch, getState) => {
      dispatch(fetchGetNumberOfTransferPendingStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      try {
        const response = await api.get(getNumberOfTransferPendingAPI,body)
  
          dispatch(fetchGetNumberOfTransferPendingSuccess(response.data) )
        } catch (error) {
          dispatch(fetchGetNumberOfTransferPendingFail(error))
        }
      }
  }

  
  //-------------Approve Transaction-----------------------------------
  
  export const fetchApproveTransactionSuccess = ApproveTransfer => {
    return {
      type: actionTypes.FETCH_APPROVE_TRANSACTION_SUCCESS,
      loadingApproveTransfer: false,
      ApproveTransfer: ApproveTransfer
    }
  }
  
  export const fetchApproveTransactionFail = error => {
    return {
      type: actionTypes.FETCH_APPROVE_TRANSACTION_FAIL,
      loadingApproveTransaction: false,      
      errorApproveTransaction: true,   
      errorApproveTransactionMessage: error 
      }
  }
  
  export const fetchApproveTransactionStart = () => {
    return {
      type: actionTypes.FETCH_APPROVE_TRANSACTION_START,
      loadingApproveTransaction: true,
    }
  }
  
  export const fetchApproveTransaction = body => {
    return async (dispatch, getState) => {
      dispatch(fetchApproveTransactionStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .post(getApproveTransferAPI,body)
      .then(res => {
        if(res.data.message === "Token inválido"){
          dispatch(
            fetchApproveTransactionFail(res.data.message),
            fetchGetTransferPending()
            )
        }else{
          dispatch(
            fetchApproveTransactionSuccess(res.data),
            fetchGetTransferPending(),
            fetchBalance()
            )
        }
      })
      .catch(err => {
        dispatch(fetchApproveTransactionFail(err))
      })
    }
  }
   
  //-------------Denied Transaction-----------------------------------
  
  export const fetchDeniedTransactionSuccess = DeniedTransfer => {
    return {
      type: actionTypes.FETCH_DENIED_TRANSACTION_SUCCESS,
      loadingDeniedTransfer: false, 
      DeniedTransfer: DeniedTransfer
    }
  }
  
  export const fetchDeniedTransactionFail = error => {
    return {
      type: actionTypes.FETCH_DENIED_TRANSACTION_FAIL,
      errorDeniedTransactionMessage: error,      
      errorDeniedTransfer: true   }
  }
  
  export const fetchDeniedTransactionStart = () => {
    return {
      type: actionTypes.FETCH_DENIED_TRANSACTION_START,
      loadingDeniedTransfer: true,
    }
  }
  
  export const fetchDeniedTransaction = body => {
    return async (dispatch, getState) => {
      dispatch(fetchDeniedTransactionStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .post(getDeniedTransferAPI,body)
      .then(res => {
        if(res.data.message === 'Token inválido'){
          dispatch(fetchDeniedTransactionFail(res.data.message))
        }else{
          dispatch(
            fetchDeniedTransactionSuccess(res.data),
            fetchBalance()
          )
        }
      })
      .catch(err => {
        dispatch(fetchDeniedTransactionFail(err))
      })
    }
  }

    
  //-------------Approve Transaction-----------------------------------
  
  export const fetchApproveTransactionWithTokenSuccess = ApproveTransfer => {
    return {
      type: actionTypes.FETCH_APPROVE_TRANSACTION_SUCCESS,
      loadingApproveTransfer: false, 
      ApproveTransfer: ApproveTransfer
    }
  }
  
  export const fetchApproveTransactionWithTokenFail = error => {
    return {
      type: actionTypes.FETCH_APPROVE_TRANSACTION_FAIL,
      loadingApproveTransfer: false,      
      errorApproveTransfer: true   }
  }
  
  export const fetchApproveTransactionWithTokenStart = () => {
    return {
      type: actionTypes.FETCH_APPROVE_TRANSACTION_START,
      loadingApproveTransfer: true,
    }
  }
  
  export const fetchApproveTransactionWithToken = (body,token) => {
    return async (dispatch, getState) => {
      dispatch(
        fetchApproveTransactionWithTokenStart()
      )
      let url = getApproveTransferWithTokenAPI + token
      var response = await fetchData(url,'POST',body)
      response.error ? ( 
          dispatch(
            fetchApproveTransactionWithTokenFail(response),
            fetchBalance()
            ) 
        ) : (
          dispatch(
            fetchApproveTransactionWithTokenSuccess(response),
            fetchBalance()
          )
        )
    }
  }

  //-------------Re send token--------------------------
  
  export const fetchReSendTokenSuccess = token => {
    fetchGetTransferPending()
    return {
      type: actionTypes.FETCH_RESEND_TOKEN_SUCCESS,
      loadingReSendToken: false, 
      token: token
    }
  }
  
  export const fetchReSendTokenFail = error => {
    return {
      type: actionTypes.FETCH_RESEND_TOKEN_FAIL,
      loadingReSendToken: false,      
      errorReSendToken: true   }
  }
  
  export const fetchReSendTokenStart = () => {
    return {
      type: actionTypes.FETCH_RESEND_TOKEN_START,
      loadingReSendToken: true,
    }
  }
  
  export const fetchReSendToken = () => {
    return async (dispatch, getState) => {
      dispatch(fetchReSendTokenStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)
      
      return api
      .post(postReSendToken)
      .then(res => {
        dispatch(fetchReSendTokenSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchReSendTokenFail(err.response.data.message))
      })
    }
  }

  
    
  //-------------Get Balance-------------------------
  
  export const fetchBalanceSuccess = balance => {
    fetchGetTransferPending()
    return {
      type: actionTypes.FETCH_BALANCE_SUCCESS,
      loadingBalance: false, 
      balance: balance
    }
  }
  
  export const fetchBalanceFail = error => {
    return {
      type: actionTypes.FETCH_BALANCE_FAIL,
      loadingBalance: false,      
      errorBalance: true   }
  }
  
  export const fetchBalanceStart = () => {
    return {
      type: actionTypes.FETCH_BALANCE_START,
      loadingBalance: true,
    }
  }
  
  export const fetchBalance = () => {
    return async (dispatch, getState) => {
      dispatch(fetchBalanceStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      try {
        const response = await api.get(getBalanceUserAPI)
  
          dispatch(
            fetchBalanceSuccess(response)
          )
        } catch (error) {
          dispatch(fetchBalanceFail(error))
        }
    }
  }
    
  //-------------Transaction Charge With CardAPI-------------------------
  
  export const fetchTransactionChargeWithCardSuccess = transactionChargeCard => {
    fetchGetTransferPending()
    return {
      type: actionTypes.FETCH_TRANSACTION_CHARGE_CARD_SUCCESS,
      loadingTransactionChargeCard: false, 
      transactionChargeCard: transactionChargeCard
    }
  }
  
  export const fetchTransactionChargeWithCardFail = error => {
    return {
      type: actionTypes.FETCH_TRANSACTION_CHARGE_CARD_FAIL,
      loadingTransactionChargeCard: false,      
      errorTransactionChargeCard: error   }
  }
  
  export const fetchTransactionChargeWithCardStart = () => {
    return {
      type: actionTypes.FETCH_TRANSACTION_CHARGE_CARD_START,
      loadingTransactionChargeCard: true,
      errorTransactionChargeCard: false
    }
  }
  
  export const fetchTransactionChargeWithCard = (body) => {
    return async (dispatch, getState) => {
      dispatch(fetchTransactionChargeWithCardStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      try {
        const response = await api.post(TransactionChargeWithCardAPI,body)
          dispatch(
            fetchTransactionChargeWithCardSuccess(response.data)
          )
        } catch (err) {
          dispatch(fetchTransactionChargeWithCardFail(err.response.data.message))
        }
    }
  }
    
  //-------------Transaction data-------------------------
  
  export const fetchTransactionDataSuccess = TransactionData => {
    return {
      type: actionTypes.FETCH_TRANSACTION_DATA_SUCCESS,
      loadingTransactionData: false, 
      TransactionData: TransactionData
    }
  }
  
  export const fetchTransactionDataFail = error => {
    return {
      type: actionTypes.FETCH_TRANSACTION_DATA_FAIL,
      loadingTransactionData: false,      
      errorTransactionData: error   }
  }
  
  export const fetchTransactionDataStart = () => {
    return {
      type: actionTypes.FETCH_TRANSACTION_DATA_START,
      loadingTransactionData: true,
      errorTransactionData: false
    }
  }
  
  export const fetchTransactionData = (id) => {
    return async (dispatch, getState) => {
      dispatch(fetchTransactionDataStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      const url = getTransactionDataAPI + id
      try {
        const response = await api.get(url)
          dispatch(
            fetchTransactionDataSuccess(response.data)
          )
        } catch (err) {
          dispatch(fetchTransactionDataFail(err.response.data.message))
        }
    }
  }