import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import CrossIcon from 'assets/icons/cross-blue.png'
import {
  FormContainer,
  TitleLabel,
  SelectWrapper,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  Row
} from './styled'
import { connect } from 'react-redux'
import mapRoles from "selectors/mapRoles"
import mapPropietaries from "selectors/mapPropietaries"
import {fetchRoles,fetchgetMenus,fetchgetPersonByNeighbourhood,fetchRefreshMenusForRole} from "store/actions/profile.actions"

class ProprietaryEdit extends Component {
  state = { 
    customRoles:[],
  }
  
  componentDidMount(){
    this.props.fetchRoles()
    this.props.fetchgetMenus()
    this.props.fetchgetPersonByNeighbourhood()
}

componentWillReceiveProps(){
   this.props.profile.menus.map(menu => {
        if(menu.code.indexOf("_BO") !== -1){
            this.setState(prevState => ({
                allMenus: {
                    ...prevState.allMenus,
                    [menu.code]: 0 
                }
            }))
        }   
    })
}

  handleSelectRoles = value => {
    this.setState({ selectedRoles: value })
    if( value.length !== 0){
        value[0].menus.map(menu => {
            if(menu.code.indexOf("_BO") !== -1){
                this.setState(prevState => ({
                    customRoles: {
                        ...prevState.customRoles,
                        [menu.id]: 1 
                    }
                }))
            }   
        })
    }else{
        this.props.profile.menus.map(menu => {
            if(menu.code.indexOf("_BO") !== -1){
                this.setState(prevState => ({
                    customRoles: {
                        ...prevState.customRoles,
                        [menu.id]: 0 
                    }
                }))
            }   
        })
    }
  }

  handleSelectRoles = value => {
    this.setState({ selectedRoles: value })
      if( value.length !== 0){
          value[0].menus.map(menu => {
              if(menu.code.indexOf("_BO") !== -1){
                  this.setState(prevState => ({
                      customRoles: {
                          ...prevState.customRoles,
                          [menu.id]: 1 
                      }
                  }))
              }   
          })
      }else{
          this.props.profile.menus.map(menu => {
              if(menu.code.indexOf("_BO") !== -1){
                  this.setState(prevState => ({
                      customRoles: {
                          ...prevState.customRoles,
                          [menu.id]: 0 
                      }
                  }))
              }   
          })
      }
  }

  sendRol(){
    
   console.log("body a mandar")
  }

  render() {
   console.log("estado de edit user",this.state)
   console.log("props de edit user",this.props)
      return (
        <Modal>
          <FormContainer>
          <CrossImage onClick={() => this.props.onClose()} src={CrossIcon} />
            <Row>
            <TitleLabel> Rol  
              {
                this.props.userToEdit.title ? " de "+ this.props.userToEdit.title  : ""
              }
            </TitleLabel>
            </Row>
            <Row>
            <SelectWrapper>
              {this.props.roles ? (
              <Select
                  value={this.state.selectedRoles}
                  onChange={this.handleSelectRoles}
                  options={this.props.roles}
                  isLoading={this.props.profile.loadingRoles}
                  isMulti
              />) : (<></>)}
              </SelectWrapper>
            <ButtonWrapper>
                <button >Actualizar Rol</button>
            </ButtonWrapper>
            </Row>
          </FormContainer>
        </Modal>
      )
  }
}

const mapStateToProps = ({
  profile,
  userInfo
}) => ({
  profile,
  roles: mapRoles(profile.roles),
  propietaries: mapPropietaries(profile.persons),
  userInfo
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchRoles,fetchgetMenus,fetchgetPersonByNeighbourhood,fetchRefreshMenusForRole }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProprietaryEdit)
