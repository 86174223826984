import styled from 'styled-components'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

export const PageWrapper = styled.div`
    overflow: hidden;
    width: 100%;
    min-height: 100%;
    align-items: normal;

    &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
    }
    &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
}
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 560px;
`

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.alto};
    margin-right: 1px;
    border-radius: 10px;
`

export const ContentWrapper = styled.div`
    display: flex;
    margin-right: 1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`

export const ListWrapper = styled.div`
    width: 35%;
    background-color: ${({ theme }) => theme.colors.ghost};
    padding-right: 13px;
    max-height: 600px;
    min-height: 550px;
    overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.ghost};
`

export const GridButtonBack = styled(Grid)`
    margin-top: ${({ theme }) => theme.spacing.unit3};
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 1000px;
    border-radius: 10px;
    display: flex;
`

export const ButtonBack = styled(Button)`
    padding: 0px !important;
    height: 35px !important;
    width: 35px !important;
    margin: ${({ theme }) => theme.spacing.unit2  } !important;
    background-color: ${({ theme }) => theme.colors.white } !important;

`
export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.blueRibbon };
    background-color: ${({ theme }) => theme.colors.white };
`

export const TyTitle = styled(Typography)`
    width: 90%;
    max-width: 1000px;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 30px;
    font-weight: bold !important;
    margin-top: 20px !important;
    margin-bottom: ${({ theme }) => theme.spacing.unit3 }!important;
`