import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCheckOut, fetchSearchInviteDni, clearSearchedInvites } from 'store/actions/acceso.actions'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Page from 'components/Page'
import Section from 'components/Section'
import Input from 'components/Input';
import compose from 'recompose/compose'
import ScanQRModal from "./scanQR"

import {
  Wrapper,
  PageWrapper,
  Breakline,
  StyledButton,
  Text,
  Title,
  FormSection,
  Row,
  InputWrapper,
  ErrorLabel,
} from './styled'


class Exit extends Component {
  state = {
    scanQRModal: false,
  }

  handleQRsearch(guest) {
    this.props.fetchCheckOut(guest)
  }

  handleSubmit = () => {
    this.props.fetchCheckOut(this.props.searchedInvites[0].guest_invite_id) 
  }

  toggleScanQRModal = () => {
    this.setState({ scanQRModal: !this.state.scanQRModal})
  }

  getInitialValues = () => ({
    invitado_dni_numero: ""
  })

  getSignUpSchema = () =>
  Yup.object().shape({
    invitado_dni_numero: Yup.string().required('El DNI debe tener 8 caracteres.'),
  })

  componentDidUpdate(prevProps) {
    if(!this.props.loadingCheckOut) {
      if (prevProps.checkOutSuccess === false && this.props.checkOutSuccess === true) {
        this.props.onSuccess();
        this.props.toggleSuccesModal(
          <p>El check-out fue realizado exitosamente!</p>
        );
      }
      if (prevProps.checkOutFail === false && this.props.checkOutFail === true) {
        this.props.onSuccess();
        this.props.toggleFailModal(
          <p>El check-out no fue realizado.</p>
        );
      }
    }
  }

  render() {
    const args = {...this.props};
    return (
      <Page>       
        <PageWrapper>
          <Section title={'Salida de Invitado'} onBack={this.props.onClose}>
            { this.state.scanQRModal ? 
              <ScanQRModal 
                guest={this.handleQRsearch} 
                onClose={this.toggleScanQRModal}
                toggleSucessModal={this.props.toggleSuccesModal}
                toggleFailModal={this.props.toggleFailModal}
              /> 
            : 
              <Wrapper> 
                <Formik
                  initialValues={this.getInitialValues()}
                  validationSchema={this.getSignUpSchema()}
                  onSubmit={this.handleSubmit}
                  render={props => <FormikForm {...props} {...args} toggleScanQRModal={this.toggleScanQRModal}/>}
                />
              </Wrapper>
            }
          </Section>
        </PageWrapper>
      </Page>
    )
  }
}

class FormikForm extends Component {
  handleSearchDNI(dni) {
    if(dni.length > 7) {
      this.props.fetchSearchInviteDni(dni);
    } else {
      this.props.clearSearchedInvites();
    }
  }

  render() {
    const { handleSubmit, values, setFieldValue, errors} = this.props;
    return(
      <form onSubmit={(values) => handleSubmit(values)}>
        <Row >
          <FormSection>
            <Title>Puede ingresar el DNI del invitado que esta saliendo.</Title>  
            <Row>
              <InputWrapper style={{'flex':'1'}}>
                <Text>{'DNI'}</Text>
                <Input
                  name={'invitado_dni_numero'}
                  label={'DNI'}
                  placeholder={'N de DNI'}
                  value={values.invitado_dni_numero}
                  error={errors.invitado_dni_numero}
                  onChange={({target: value}) => {
                    setFieldValue('invitado_dni_numero', value.value)
                    this.handleSearchDNI(value.value)
                  }}
                />
                {errors.invitado_dni_numero && <ErrorLabel>{errors.invitado_dni_numero}</ErrorLabel>}
                {
                  !this.props.loading && this.props.searchedInvites.length === 0 && values.invitado_dni_numero > 7 ?
                  <Text>No se encontro ningun invitado bajo el DNI ingresado</Text> : null 
                }
              </InputWrapper>
              <InputWrapper style={{'flex':'1'}}>
                <Text>{'Apellido y Nombre'}</Text>
                <Input
                  placeholder={'Nombre'}
                  value={ !this.props.loading ? (values.invitado_dni_numero ? (this.props.searchedInvites.length > 0 ? this.props.searchedInvites[0].name : '') : ('')) : 'Cargando...'}
                  disabled
                />
              </InputWrapper>
              <InputWrapper style={{'marginTop': '16px', 'flex':'1'}}>
                <StyledButton style={{'width':'100%'}} type="submit">Registrar salida</StyledButton>
              </InputWrapper>
            </Row>
            <Breakline />
            <Title>O escanea su codigo QR.</Title>  
            <Row style={{'justifyContent':'center'}}>
              <StyledButton onClick={() => this.props.toggleScanQRModal()}>Escanear QR</StyledButton>
            </Row>
          </FormSection>  
        </Row>
      </form>
    );
  }
}

const mapStateToProps = ({
  accesos,
}) => ({
  loadingCheckOut: accesos.loadingCheckOut,
  loading: accesos.loadingSearchedInvites,
  searchedInvites: accesos.searchedInvites,
  error: accesos.errorGuestInvitation,
  checkOutSuccess: accesos.checkOutSuccess,
  checkOutFail: accesos.checkOutFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCheckOut, fetchSearchInviteDni, clearSearchedInvites
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose()(Exit))