import { createSelector } from 'reselect'

let mapAccountQR = createSelector(  
    AccountQR => AccountQR,
    AccountQR => 
    AccountQR ? (AccountQR.map(account => ({
    title: account.PersonName,
    cuit: account.PersonCuit,
    lote: account.PropertyCode,
    withAvatar: false
  })) ) : (AccountQR.errorAccountQR)
)
export default mapAccountQR
