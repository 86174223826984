import styled from 'styled-components'
import mediaQueries from 'config/media-queries'
import Input from 'components/Input'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ImageWrapper = styled.div`
  width: 60%;
  height: 100%;
  min-height: 550px;
  max-height: 1110px;


  ${mediaQueries.tablet`
    display: none;
  `}
`

export const LogoImg = styled.img`
  margin: 20% 10%;
  width: 100%;
  height: 20%;
`
export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`
export const FormContainer = styled.form`
  position: relative;
  height: 40%;
  max-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 600px;
  padding: 5%;
  box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
  ${mediaQueries.tablet`
    width: 100%;
  `}
`

export const FormWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  box-sizing: border-box;
  max-width: 600px;
  align-items: center;
  margin: 0 auto;
`

export const Title = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.black};
    font-size: 20px;
    padding-bottom: 10px;
`

export const SubTitle = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.black};
    font-size: 16px;
`

export const ContentLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
`

export const StyledLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 14px;
  align-self: end;
  cursor:pointer;
`


export const InputWrapper = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction:column;
`

export const ButtonWrapper = styled.div`
  padding: 0 15%;
  margin: 30px;
  width: 100%;
`

export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
`

export const InputStyled = styled(Input)`
    width: 70%;
    letter-spacing: 10px;
    text-align: center;

`

export const LabelReSend = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 15px;
  margin: 4% 1%;
  &:hover{
    cursor:pointer;
    color: ${({ theme }) => theme.colors.persimmon};
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-bottom: ${({ margin }) => (margin ? margin : 0+"px")};
`
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 50px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  position: absolute;
  bottom: 10%;
  right: 36%;
`