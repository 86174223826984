import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import { submitActivity } from 'store/actions/activities.actions'
import Input from 'components/Input'
import CheckBox from 'components/CheckBox'
import Select from 'react-select'
import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  RadioBtnContainer,
  LabelChecked,
  SelectWrapper
} from './styled'

class NewDestinatario extends React.Component {
    state = {
        selectedOptionInFirst: 'nombre',
        selectedOptionInSecond: 'alias'
    }

    handleOptionChange = changeEvent => {
        this.setState({ selectedOptionInFirst: changeEvent.target.value });
    }
    handleOptionChangeSecond = changeEvent => {
        this.setState({ selectedOptionInSecond: changeEvent.target.value })
    }

  getInitialValues = () => ({
    firstInput:'',
    secondInput: '',
    cuit: '',
    tipoDestinatario: '',
    referencia: '',
    email: '',
 })

  getTypeOptions = () => [
    {
      label: 'Proveedor',
      value: 'proveedor'
    },
    {
      label: 'Invitado',
      value: 'invitado'
    },
    {
      label: 'Familiar',
      value: 'familiar'
    }
  ]

  getSignUpSchema = () =>
    Yup.object().shape({
      firstInput: Yup.string().required('El campo nombre/razon es requerido'),
      secondInput: Yup.string().required('El campo Alias/CVU/CBU es requerido'),
      cuit: Yup.string().required('El campo cuit es requerido con numeros').max(13, "muy largo!"),
      tipoDestinatario: Yup.object(),
      referencia: Yup.string().required('El campo referencia es requerido'),
      email: Yup.string().required('El campo email es requerido'),
    })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> 
        <RadioBtnContainer>
            <LabelChecked checked={this.state.selectedOptionInFirst === 'nombre'}>
                <CheckBox
                type="radio"
                name="input1"
                value="nombre"
                checked={this.state.selectedOptionInFirst === 'nombre'}
                onChange={this.handleOptionChange}
                className="form-check-input"
                />
                Nombre y apellido
            </LabelChecked>
            <LabelChecked checked={this.state.selectedOptionInFirst === 'razon'}>
                <CheckBox
                    type="radio"
                    name="input1"
                    value="razon"
                    checked={this.state.selectedOptionInFirst === 'razon'}
                    onChange={this.handleOptionChange}
                    className="form-check-input"
                />
                Razon social
            </LabelChecked>
            </RadioBtnContainer>
        </Row>
        <InputWrapper margin={'0px 5px'}>
            <Input
                name={'firstInput'}
                label={'firstInput'}
                placeholder={'Ingrese un '+this.state.selectedOptionInFirst+'...'}
                value={values.firstInput}
                error={errors.firstInput}
                onChange={({ target: value }) => setFieldValue('firstInput', value.value)}
                margin="normal"
                weight="700"
            />
            {errors.firstInput && <ErrorLabel>{errors.firstInput}</ErrorLabel>}
        </InputWrapper> 
        <Row>
        <RadioBtnContainer>
            <LabelChecked checked={this.state.selectedOptionInSecond === 'alias'}>
                <CheckBox
                    type="radio"
                    name="input2"
                    value="alias"
                    checked={this.state.selectedOptionInSecond === 'alias'}
                    onChange={this.handleOptionChangeSecond}
                    className="form-check-input2"
                />
                Alias
            </LabelChecked>
            <LabelChecked checked={this.state.selectedOptionInSecond === 'cvu'}>
                <CheckBox
                    type="radio"
                    name="input2"
                    value="cvu"
                    checked={this.state.selectedOptionInSecond === 'cvu'}
                    onChange={this.handleOptionChangeSecond}
                    className="form-check-input2"
                />
                CVU
            </LabelChecked>
            <LabelChecked checked={this.state.selectedOptionInSecond === 'cbu'}>
                <CheckBox
                    type="radio"
                    name="input2"
                    value="cbu"
                    checked={this.state.selectedOptionInSecond === 'cbu'}
                    onChange={this.handleOptionChangeSecond}
                    className="form-check-input2"
                />
                CBU
            </LabelChecked>
          </RadioBtnContainer>
        </Row>
        <Row>
            <InputWrapper width={38} margin={'0px 5px'}>
                <Input
                    name={'secondInput'}
                    label={'secondInput'}
                    placeholder={'Ingrese un '+this.state.selectedOptionInSecond+'...'}
                    value={values.secondInput}
                    error={errors.secondInput}
                    onChange={({ target: value }) => setFieldValue('secondInput', value.value)}
                    margin="normal"
                    weight="700"
                />
                {errors.secondInput && <ErrorLabel>{errors.secondInput}</ErrorLabel>}
            </InputWrapper> 
        </Row>
        <Row> <InputLabel>{'Cuit'}</InputLabel> </Row>
        <Row>
            <InputWrapper width={38} margin={'0px 5px'}>
                <Input
                    name={'cuit'}
                    label={'cuit'}
                    placeholder={'Ingrese un cuit...'}
                    type="number"
                    value={values.cuit}
                    error={errors.cuit}
                    onChange={({ target: value }) => setFieldValue('cuit', value.value)}
                    margin="normal"
                    weight="700"
                />
                {errors.cuit && <ErrorLabel>{errors.cuit}</ErrorLabel>}
            </InputWrapper> 
        </Row>
        <Row> <InputLabel>{'Categoría'}</InputLabel> </Row>
        <SelectWrapper>
            <Select
                name={'tipoDestinatario'}
                value={values.tipoDestinatario}
                isSearchable={false}
                onChange={option => { setFieldValue({ ...values, tipoDestinatario: option })  }}
                error={errors.tipoDestinatario}
                options={this.getTypeOptions()}
                placeholder="Seleccione un tipo..."
                isDisabled={false}
                style={{fontWeight: '700'}}
            />
            {errors.tipoDestinatario && <ErrorLabel>{errors.tipoDestinatario}</ErrorLabel>}
            
        </SelectWrapper>
        <Row> <InputLabel>{'Referencia'}</InputLabel> </Row>
        <Row>
            <InputWrapper width={38} margin={'0px 5px'}>
                <Input
                    name={'referencia'}
                    label={'referencia'}
                    placeholder={'Ingrese una referencia...'}
                    value={values.referencia}
                    error={errors.referencia}
                    onChange={({ target: value }) => setFieldValue('referencia', value.value)}
                    margin="normal"
                    weight="700"
                />
                {errors.referencia && <ErrorLabel>{errors.referencia}</ErrorLabel>}
            </InputWrapper> 
        </Row>
        <Row> <InputLabel>{'Email'}</InputLabel> </Row>
        <Row>
            <InputWrapper width={38} margin={'0px 5px'}>
                <Input
                    name={'email'}
                    label={'email'}
                    placeholder={'Ingrese un email...'}
                    value={values.email}
                    error={errors.email}
                    onChange={({ target: value }) => setFieldValue('email', value.value)}
                    margin="normal"
                    weight="700"
                />
                {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
            </InputWrapper> 
        </Row>
      <ButtonWrapper>
        <StyledButton type={'submit'}>
          {this.props.activities.isSubmitting ? <CircularProgress size={18} /> : 'Crear'}
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )

  handleSubmit = async data => {
    await this.props.submitActivity(data)
    if (!this.props.activities.error) this.props.onClose(true)
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({ activities }) => ({
  activities
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitActivity
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDestinatario)
