import React from 'react'
import { Container,AvatarContainer, ActivityContainer, ImageWrapper, Image, Name,StyledLabel,EditIconStyled } from './styled'
import classNames from 'classnames'
import { Avatar } from '@material-ui/core'
import EditIcon from 'components/EditIcon'

const isSelected = (activity, selected) => selected && activity.id === selected.id

const ActivitiesList = ({ activities, onActivityClick, selected,onEdit }) => (
  <Container className={classNames('portal-hide-scrollbars')}>
    {activities.map((activity, i) => (
      <ActivityContainer
        key={i}
        onClick={() => onActivityClick(activity)}
        selected={isSelected(activity, selected)}
      >
        {activity.avatar_url ? (
          <ImageWrapper>
            <Image src={activity.avatar_url} />
          </ImageWrapper>
        ) : (
          <AvatarContainer>
            <Avatar
              style={{
                width: '35px',
                height: '35px',
                padding: '10px',
                background: '#1873f3'
              }}
            >
              {activity.description.charAt(0).toUpperCase()}
            </Avatar>
          </AvatarContainer>
        )}
        <Name selected={isSelected(activity, selected)}>{activity.description}</Name>
        <EditIcon
          width="15px"
          onClick={()=>onEdit(activity)}
          style={{flexGrow: "1", margin: "2%",minWidth: "25px"}}
          translucent="0.3"
        />
      </ActivityContainer>
    ))}
  </Container>
)

export default ActivitiesList
