import styled from 'styled-components'
import {Grid} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import CurrencyInput from 'react-currency-input';
import Select from 'react-select'

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin: ${({ theme }) => theme.spacing.unit };
`
export const FormContainer = styled.form`
    display:flex;
    flex-direction: column;
    width: 100%;
    margin: ${({ theme }) => theme.spacing.unit };
    transition: all 0.2s ease;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
`

export const TitleContainer = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.colors.black };
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    margin-top: 1%;
    display: flex;   
    margin-bottom: 2%;
`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.dark };
    background-color: ${({ theme }) => theme.colors.white };

    ${TitleContainer}:hover & {
        color: ${({theme})=> theme.colors.blueRibbon};
        transition:0.3s ease;  
        cursor: pointer;
    }
`


export const gridItemForm = styled(Grid)`
    flex-direction: column;
    display: flex;
    padding-left: ${({ theme }) => theme.spacing.unit3 };
    padding-right: ${({ theme }) => theme.spacing.unit3 };
    min-height: 500px;
`

export const LabelContainer = styled.label`
    width: 80%;
    color: ${({ theme }) => theme.colors.warmGrey };
    font-size: 20px;
    margin-top: 1%;
`
export const SelectWrapper = styled.div`
    width: 100%;
    margin-top: 1%;
`
export const RadioBtnContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
    margin-bottom: ${({ theme }) => theme.spacing.unit2 };
    display:flex;
    flex-direction: row;
    justify-content: flex-start;  
`

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 17px;
    width: 40%;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 5% 0px 0% 25%;
`


export const Row = styled.div`
    margin:1% 0px 1% 0px;
;
`

export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const LabelChecked = styled.label`
    font-size: 15px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
`

export const InputStyled = styled(CurrencyInput)`
    width: 100%;
    height: 30px;
    margin-top: 5px;
    margin-bottom: ${({ theme }) => theme.spacing.unit };
    font-weight: ${({ onChangeEvent }) => onChangeEvent ? 600 : 500 };
    font-size: 20px;
    padding: 10px;
    color: black;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-color:  ${({ theme }) => theme.colors.ghost };
`

export const SelectStyled = styled(Select)`
    font-weight: 600;
    font-size: 20px;
    
`