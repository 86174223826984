import { createSelector } from 'reselect'
import {getFormattedDate} from "utils/dateParser"

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapTransferPending = createSelector(  
    TransferRequest => TransferRequest,
    TransferRequest => 
    TransferRequest ? ( TransferRequest.map(transfer => ({
    datePay: getFormattedDate(transfer.pay_date),
    alias: transfer.alias,
    category: transfer.category,
    subcategory: transfer.subcategory,
    destination: transfer.destination_account_client,
    dateRequest: getFormattedDate(transfer.creation_date),
    origin: transfer.source_account_client,
    amount: transfer.amount,
    tranfer_request_id: transfer.tranfer_request_id,
    status: transfer.state_code
  })) ) : (TransferRequest)
)
export default mapTransferPending
