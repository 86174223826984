import * as actionTypes from './actionTypes'
import { postNewProprietaryAPI, getPadronAPI, postImportMasiveProprietariesAPI,editPadronAPI } from 'apiConstants'
import getApi from 'shared/api'

export const fetchPadronSuccess = padron => {
  return {
    type: actionTypes.FETCH_PADRON_SUCCESS,
    padron: padron
  }
}

export const fetchPadronFail = error => {
  return {
    type: actionTypes.FETCH_PADRON_FAIL,
    error: error
  }
}

export const fetchPadronStart = () => {
  return {
    type: actionTypes.FETCH_PADRON_START
  }
}

export const createProprietarySuccess = (id, newProprietaryData) => {
  return {
    type: actionTypes.CREATE_PROPRIETARY_SUCCESS,
    newPadronId: id,
    newProprietaryData: newProprietaryData
  }
}

export const createProprietaryFail = error => {
  return {
    type: actionTypes.CREATE_PROPRIETARY_FAIL,
    error: error
  }
}

export const createProprietaryStart = () => {
  return {
    type: actionTypes.CREATE_PROPRIETARY_START
  }
}

export const newProprietary = (newProprietary, guid) => {
  return async dispatch => {
    const api = await getApi(guid, true)
    dispatch(createProprietaryStart())
    return api
      .post(postNewProprietaryAPI, newProprietary)
      .then(() => {
        dispatch(createProprietarySuccess())
      })
      .catch(err => {
        dispatch(createProprietaryFail(err))
      })
  }
}

export const importProprietarySuccess = (id, importProprietaryData) => {
  return {
    type: actionTypes.IMPORT_PROPRIETARY_SUCCESS,
    newPadronId: id,
    importProprietaryData: importProprietaryData
  }
}

export const importProprietaryFail = error => {
  return {
    type: actionTypes.IMPORT_PROPRIETARY_FAIL,
    error: error
  }
}

export const importProprietaryStart = () => {
  return {
    type: actionTypes.IMPORT_PROPRIETARY_START
  }
}

export const importProprietary = (importProprietary, guid) => {
  return async dispatch => {
    const api = await getApi(guid, true)
    dispatch(importProprietaryStart())
    return api
      .post(postImportMasiveProprietariesAPI, importProprietary)
      .then((res) => {
        dispatch(importProprietarySuccess())
      })
      .catch(err => {
        dispatch(importProprietaryFail(err))
      })
  }
}


export const fetchPadron = textoABuscar => {
  return async (dispatch, getState) => {
    dispatch(fetchPadronStart())
    const {
      padron,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid,true)

    const request= {
      searchValue: textoABuscar ? textoABuscar : "",
      pageSize: null,
      pageNumber: null
    }

    return api
    .post(getPadronAPI,request)
    .then(response => {
      dispatch( fetchPadronSuccess({
        values: response.data
      }))
    })
    .catch(err => {
      dispatch(fetchPadronFail(err))
    })
  }
}


  //------------------Request Edit Person -----------------------------------
  export const fetchEditPersonSuccess = editPersonResponse => {
    return {
      type: actionTypes.FETCH_EDIT_PERSON_SUCCESS,
      editPersonResponse: editPersonResponse
    }
  }
  
  export const fetchEditPersonFail = error => {
    return {
      type: actionTypes.FETCH_EDIT_PERSON_FAIL,
      error: error
    }
  }
  
  export const fetchEditPersonStart = () => {
    return {
      type: actionTypes.FETCH_EDIT_PERSON_START
    }
  }
  
  export const fetchEditPerson = person => {
    return async (dispatch, getState) => {
      dispatch(fetchEditPersonStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid,true)

      return api
      .put(editPadronAPI,person)
      .then(res => {
        dispatch(fetchEditPersonSuccess(res.data))
        dispatch(fetchPadron())
      })
      .catch(err => {
        dispatch(fetchEditPersonFail(err))
      })
    }
  }