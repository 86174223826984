import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 40%;
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;    
  padding: 40px 100px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
  box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-bottom: ${({ error }) => (error ? 0 : 10)}px;
`

export const InputWrapper = styled.div`
    width:  100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  padding-bottom: 10px;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.royalBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`
export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`
export const ResetIcon = styled.img`
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
`

export const FileInputStyled = styled.div`
    width: 80px;
    height: 80px;
    border-color: ${({ theme }) => theme.colors.royalBlue};
    border-style: dotted;
    border-width: 1px;
    border-radius: 10px;
    
`

export const DocumentImg = styled.img`
  width: 100%;
  border-radius: 8px;
  
  
`


export const FileInputWrapper = styled.div`
   width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

`

export const StyledFileInput = styled.input`
  width: 150px;
  height: 150px;
  font-size: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  
`

export const ImageAttachText = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 14px;
  text-decoration: underline;
  margin-left: 20px;
`

export const AttachedContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.transparent};
  
  ${FileInputWrapper} &:hover{
    cursor: pointer;
  }
`

export const CheckedElement = styled.img`
  position: absolute;
  top: -12px;
  right: -12px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`
